import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import notify from "../../../shared/notify";
import Button from "../../UI/Button/Button";
import { useStores } from "../../../stores";
import './style.scss';
import { useNavigate } from "react-router-dom";
import authStore from "../../../stores/authStore";
import moment from "moment";
import { propTypes } from "react-bootstrap/esm/Image";
import axios from 'axios'
import SpokesLoader from "../SpokesLoader";
import crossimg from '../../../assets/images/cancel.png';
import Service from "../../../shared/service/Service";

function VerifyOTP(props) {

    const navigate = useNavigate();
    const { applicantStore, businessDetailsStore, authStore } = useStores();

    const id = sessionStorage.getItem('retailerId');
    const programName = sessionStorage.getItem('programName');
    const loginData = sessionStorage.getItem('loginData');
    const item = JSON.parse(loginData)
    const nextActivityId = sessionStorage.getItem('nextActivityId')

    const [loader, setLoader] = useState(false);
    const [otp, setOtp] = useState('')
    const [countDown, setCountDown] = React.useState(0);
    const [runTimer, setRunTimer] = useState(false);
    const [showResendButton, setShowResendButton] = useState(false)
    const [mobile, setMobile] = useState(props?.mobile)
    const [pan, setPan] = useState(props?.pan)
    const [nextId, setNextId] = useState(false)
    const [showVerifyButton, setShowVerifyButton] = useState(false)

    let timerId;

    // useEffect(() => {
    //     window.history.pushState(null, null, window.location.href); 
    // }, [])

    useEffect(() => {
        if (nextActivityId !== null && nextActivityId !== undefined && nextActivityId !== '-1') {
            setNextId(true)
        } else {
            setNextId(false)
        }
    }, [nextActivityId])

    useEffect(() => { })


    useEffect(() => {
        if (props.show) {
            setRunTimer(true)
        } else {
            setRunTimer(false)
        }

        if (runTimer) {
            setCountDown(60 * 5);
            timerId = setInterval(() => {
                setCountDown((countDown) => countDown - 1);
            }, 1000);
        } else {
            clearInterval(timerId);
        }

        return () => clearInterval(timerId);
    }, [runTimer, props.show]);

    useEffect(() => {
        if (countDown < 0 && runTimer) {
            // console.log("expired");
            setShowResendButton(true)
            setRunTimer(false);
            setCountDown(0);
        }
    }, [countDown, runTimer]);

    const seconds = String(countDown % 60).padStart(2, 0);
    const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);


    const handleResendOtp = async () => {
        setLoader(true)
        setOtp('')
        if (props?.getOtp === 'OKYC') {

            let params = `retailerId=${id}&aadharNumber=${props?.aadharNumber}`

            businessDetailsStore.oKycGetOtp(params, {}, (response) => {
                console.log("okyc get otp response ", response);
                if (response?.data?.exceptionOccured === 'N') {
                    setShowResendButton(false)
                    setRunTimer(true)
                    setLoader(false)
                    notify(response?.data?.result?.result?.message);
                    props.stopLoader()
                } else {
                    // setIsLoading(false)
                    // setDrugBase64('')
                    // setDrugLicenceFileName('')
                    // setOtpModal(false)
                    setLoader(false)
                    notify(response?.data?.exceptionMessage, 'danger')
                    return;
                }
            });

        } else if (props?.getOtp === 'terms') {
            let params = `retailerId=${id}`
            await applicantStore.eSignOTP(params, (response) => {
                console.log(response);
                if (response?.data?.message === "Success") {
                    notify(response?.data?.result?.statusMessage)
                    setShowResendButton(false)
                    setRunTimer(true)
                    setLoader(false)
                    props.stopLoader()
                } else {
                    notify(response?.data?.exceptionMessage, 'danger')
                    setLoader(false)
                    props.stopLoader()
                }
            })
        } else if (props?.getOtp === 'sanction') {
            let params = `retailerId=${id}&validateType=1`
            await applicantStore.eSignOTP(params, (response) => {
                console.log(response);
                if (response.data.message === "Success") {
                    notify(response.data.result.statusMessage)
                    setShowResendButton(false)
                    setRunTimer(true)
                    setLoader(false)
                    props.stopLoader()
                } else {
                    notify(response?.data?.exceptionMessage, 'danger')
                    setLoader(false)
                    props.stopLoader()
                }
            })
        } else {

            let params = `mobileNumber=${props.mobile}&retailerId=${id}`

            await authStore.sendOtp(params, (response) => {
                if (response?.data?.exceptionOccured === "N") {
                    if (response?.data?.result?.status == 'success') {
                        notify(response?.data?.result?.statusMessage, "success");
                        setShowResendButton(false)
                        setRunTimer(true)
                        setLoader(false)
                        props.stopLoader()
                    } else {
                        setLoader(false)
                        notify(response?.data?.result?.statusMessage, "danger");
                    }
                }
                else {
                    setLoader(false)
                    notify(response?.data?.exceptionMessage, 'danger')
                    props.stopLoader()
                }

            })
        }
    }

    const handleVerifyOtp = async (otpInput) => {
        setLoader(true)

        if (otpInput === '') {
            notify('Please enter OTP first', 'danger');
            return;
        }
        if (props?.getOtp === 'OKYC') {
            let params = `OTP=${otpInput}&retailerId=${id}&aadharNumber=${props?.aadharNumber}`;

            businessDetailsStore.oKycVerifyOtp(params, {}, (response) => {
                console.log("okyc Verify otp response ", response);
                if (response?.data?.exceptionOccured === 'N') {
                    if (response?.data?.result?.status == "success") {
                        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/AadhaarVerification`);
                        notify(response?.data?.result?.statusMessage);
                        setLoader(false);
                        props?.hide();
                    }

                } else {
                    if (response?.data?.status == 107) {
                        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/NoOfferFound`);
                        notify(response?.data?.exceptionMessage, "danger");
                        setLoader(false);
                        props?.hide()
                    } else if (response?.data?.status == 500) {
                        notify(response?.data?.exceptionMessage, "danger");
                        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
                        setLoader(false)
                        props?.hide()
                        return;
                    }  else if (response?.data?.status == 120) {
                        notify(response?.data?.exceptionMessage, "danger");
                        setOtp('');
                        setLoader(false)
                        return;
                    } else {
                        notify(response?.data?.exceptionMessage, "danger");
                        setLoader(false);
                        props?.hide()
                        return;
                    }
                    // setIsLoading(false)
                    // props?.hide()

                }
            });
        } else if (props?.getOtp === 'terms') {
            let params = `OTP=${otpInput}&retailerId=${id}&validateType=2`;

            businessDetailsStore.eSignValidateOTP(params, {}, (response) => {
                console.log("eSignValidateOTP response ", response);
                if (response?.data?.result?.status === 'success') {
                    // notify("Licence uploaded successfully")
                    // setIsLoading(false)
                    props?.hide()
                    setLoader(false)
                    props?.navigate();
                } else if (response?.data?.result?.status === 'failed') {
                    props?.hide()
                    setLoader(false)
                    notify(response?.data?.result?.statusMessage, 'danger')
                } else {
                    // setIsLoading(false)
                    // props?.hide()
                    props?.hide()
                    setLoader(false)
                    notify(response?.data?.exceptionMessage, 'danger')

                    return;
                }
            });
        } else if (props?.getOtp === 'udhyam') {
            let params = `requestId=${props.requestId}&retailerId=${id}&OTP=${otpInput}`

            applicantStore.validateUdyamRegCheck(params, (udhyamResponse) => {
                if (udhyamResponse?.data?.result?.status === 'success') {
                    notify(udhyamResponse?.data?.result?.statusMessage)
                    sessionStorage.setItem("nextActivityId", udhyamResponse?.data?.result?.nextActivityId);
                    navigate(`/${process.env.REACT_APP_BASE_FOLDER}/BusinessAddress`);
                    setLoader(false)
                } else {
                    if (udhyamResponse?.data?.status == 108) {
                        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/NoOfferFound`);
                        notify(udhyamResponse?.data?.exceptionMessage, 'danger')
                        setLoader(false)
                        return;
                    } else if (udhyamResponse?.data?.status == 500) {
                        notify(udhyamResponse?.data?.exceptionMessage, "danger");
                        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
                        setLoader(false)
                        return;
                    } else {
                        notify(udhyamResponse?.data?.exceptionMessage, 'danger')
                        setLoader(false)
                        return;
                    }
                }
            })

        } else if (props?.getOtp === 'sanction') {
            let params = `OTP=${otpInput}&retailerId=${id}&validateType=1`;

            businessDetailsStore.eSignValidateOTP(params, {}, (response) => {
                console.log("eSignValidateOTP response ", response);
                if (response?.data?.result?.status === 'success') {
                    // notify("Licence uploaded successfully")
                    // setIsLoading(false)
                    props?.hide()
                    props.stopLoader()
                    setLoader(false)
                    props?.navigate();
                } else if (response?.data?.result?.status === 'failed') {
                    props.stopLoader()
                    setLoader(false)
                    notify(response?.data?.result?.statusMessage, 'danger')
                } else {
                    // setIsLoading(false)
                    // props?.hide()
                    setLoader(false)
                    props.stopLoader()
                    notify(response?.data?.exceptionMessage, 'danger')
                    return;
                }
            });
        } else {
            let payload = {
                "mobileNumber": props.mobile,
                "OTP": otpInput,
                "programName": programName,
                "pan": item.pan
            }
            // let params = `OTP=${otpInput}&mobileNumber=${props.mobile}`

            await applicantStore.validateOtp(payload, (response) => {
            // await applicantStore.validateOtp(params, (response) => {
                console.log("Login Verify otp response ", response);
                if (response?.data?.exceptionOccured === 'N') {
                    if (response?.data?.result?.status == "success") {

                        sessionStorage.removeItem("showMobileField");
                        sessionStorage.setItem('token', response?.data?.result?.token)
                        sessionStorage.setItem("login", true);
                        notify(response?.data?.result?.statusMessage, 'success')
                        // setTimeout(() => {    
                        //     window.location.href = `/${process.env.REACT_APP_BASE_FOLDER}/`;
                        // }, 100);
                        if (nextActivityId == "login") {
                            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/IndividualDetails`);
                            setLoader(false)
                            props?.hide()
                            window.location.reload();
                        }
                        else if (nextActivityId == 'mitc') {
                            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/Esign`);
                            setLoader(false)
                            props?.hide()
                            window.location.reload();
                        }
                        else if (nextActivityId == 'individual_details') {
                            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/IndividualDetails`);
                            setLoader(false)
                            props?.hide()
                            window.location.reload();
                        }
                        else if (nextActivityId == 'terms_and_condition') {
                            setLoader(false)
                            props?.hide()
                            window.location.href = `/${process.env.REACT_APP_BASE_FOLDER}/`;
                        }
                        else if (nextActivityId == 'business_details') {
                            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/BusinessDetails`);
                            setLoader(false)
                            props?.hide()
                            window.location.reload();
                        }
                        else if (nextActivityId == 'limit_details') {
                            getLoanData()
                            // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/PreApprovedLoan`)
                            // window.location.reload();
                        }
                        else if (nextActivityId == 'udyam') {
                            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/UdyamRegistration`);
                            setLoader(false)
                            props?.hide()
                            window.location.reload();
                        }
                        else if (nextActivityId == 'address_list') {
                            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/BusinessAddress`);
                            setLoader(false)
                            props?.hide()
                            window.location.reload();
                        }
                        else if (nextActivityId == 'basic_information_of_business') {
                            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/BasicBusinessInformation`);
                            setLoader(false)
                            props?.hide()
                            window.location.reload();
                        }
                        else if (nextActivityId == 'okyc') {
                            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/OKYC`);
                            setLoader(false)
                            props?.hide()
                            window.location.reload();
                        }
                        else if (nextActivityId == 'aadhaar_details_verification') {
                            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/AadhaarVerification`);
                            setLoader(false)
                            props?.hide()
                            window.location.reload();
                        }
                        else if (nextActivityId == 'basic_information_of_individual') {
                            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/BasicInformation`);
                            setLoader(false)
                            props?.hide()
                            window.location.reload();
                        }
                        else if (nextActivityId == 'pan_upload') {
                            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/PanUpload`);
                            setLoader(false)
                            props?.hide()
                            window.location.reload();
                        }
                        else if (nextActivityId == 'selfie_upload') {
                            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/UploadSelfie`);
                            setLoader(false)
                            props?.hide()
                            window.location.reload();
                        }
                        else if (nextActivityId == 'offer_details') {
                            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/Congratulations`);
                            setLoader(false)
                            props?.hide()
                            window.location.reload();
                        }
                        else if (nextActivityId == 'bank_details') {
                            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ManuallyBankDetails`);
                            setLoader(false)
                            props?.hide()
                            window.location.reload();
                        }
                        else if (nextActivityId == 'enach') {
                            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/enach`);
                            setLoader(false)
                            props?.hide()
                            window.location.reload();
                        }
                        else if (nextActivityId == 'optional_udyam_details') {
                            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/UdhyamRegistrationForm`);
                            setLoader(false)
                            props?.hide()
                            window.location.reload();
                        }
                        else if (nextActivityId == 'congratulations') {
                            handleGetNachStatus()
                            // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ThankYou2`);
                        }
                       

                    }
                    else {
                        notify(response?.data?.result?.statusMessage, 'danger')
                        setLoader(false)
                        props.stopLoader()
                    }
                    // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/PanDetails`)
                } else {
                    if (response?.data?.status == 500) {
                        notify(response?.data?.exceptionMessage, "danger");
                        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
                        setLoader(false)
                        props.stopLoader()
                        return;
                    }else{

                        notify(response?.data?.exceptionMessage, 'danger')
                        setLoader(false)
                        props.stopLoader()
                    }
                }
            })
        }
    }

    const handleGetNachStatus = async () => {
        let params = `retailerId=${id}`
        setLoader(true)
        await applicantStore.getNachMandateStatus(params, (response) => {
            console.log("getNachMandateStatus ", response);
            if (response?.exceptionOccured == "N") {
                console.log("getNachMandateStatus Success ", response)
                if(response?.result?.informationComplete)
                {
                  navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ThankYou`)
                  setLoader(false)
                  props?.hide()
                  window.location.reload();
                }else{
                  navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ThankYou2`);
                  setLoader(false)
                  props?.hide()
                  window.location.reload();
                }
                
            } else {
                if (response?.status == 500) {
                    notify(response?.exceptionMessage, "danger");
                    setLoader(false)
                    props?.hide()
                    navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
                    return;
                }else{

                    notify(response?.exceptionMessage, 'danger')
                    setLoader(false)
                      props?.hide()
                    return;
                }
            }
        })
    }

    const getLoanData = () => {
        let params = `retailerId=${id}`
        setLoader(true)
        applicantStore.checkEligibilityAndGetLimit(params, (response) => {
            if (response?.exceptionOccured == 'N') {
                sessionStorage.setItem(
                    "nextActivityId",
                    response?.result?.nextActivityId
                );
                navigate(`/${process.env.REACT_APP_BASE_FOLDER}/PreApprovedLoan`)
                setLoader(false)
                props?.hide()
                window.location.reload();

                // notify(response?.result?.statusMessage, 'success');
            } else {
                // 
                if(response?.status == 500)
                {
                    navigate(`/${process.env.REACT_APP_BASE_FOLDER}/NoOfferFound`);
                    setLoader(false)
                    props?.hide()
                    notify(response?.exceptionMessage,'danger');
                    window.location.reload();
                }else if(response?.status == 105)
                {
                    navigate(`/${process.env.REACT_APP_BASE_FOLDER}/NoOfferFound`);
                    setLoader(false)
                    props?.hide()
                    notify(response?.exceptionMessage,'danger');
                    window.location.reload();
                }else if(response?.status == 111)
                {
                    navigate(`/${process.env.REACT_APP_BASE_FOLDER}/NoOfferFound`);
                    setLoader(false)
                    props?.hide()
                    notify(response?.exceptionMessage,'danger');
                    window.location.reload();
                }else{
                    navigate(`/${process.env.REACT_APP_BASE_FOLDER}/NoOfferFound`);
                    setLoader(false)
                    props?.hide()
                    notify(response?.exceptionMessage,'danger');
                    window.location.reload();
                }

            }
        })
    }



    function clickEvent(first, current, last) {
        if (current == undefined) {
            if (first.target.value.length) {
                document.getElementById(last).maxLength = 1;
                document.getElementById(last).focus();
            }
        }
    }

    function clickEvent2(first, current, last) {
        var input = document.getElementById(current);

        input.addEventListener('keydown', function (event) {
            const key = event.key; // const {key} = event; ES6+
            if (key === "Backspace") {
                if (current != undefined) {
                    document.getElementById(current).maxLength = 1;
                    if (current == 'sixth' && first.target.value.length != 0) {
                        document.getElementById(current).focus();
                    } else {
                        document.getElementById(last).focus();
                    }
                }
            }
        });
    }

    const fetchOtpInput = (type) => {
        let ist = document.getElementById('ist').value;
        let sec = document.getElementById('sec').value;
        let third = document.getElementById('third').value;
        let fourth = document.getElementById('fourth').value;
        let fifth = document.getElementById('fifth').value;
        let sixth = document.getElementById('sixth').value;

        let otpInput = ist + sec + third + fourth + fifth + sixth;
        setOtp(otpInput);
        if (otpInput.length === 6) {
            setShowVerifyButton(true)
        }
    }


    const handleVerify = (type) => {
        if (type === 'verify') {
            handleVerifyOtp(otp)
        } else {
            handleResendOtp()
        }
    }
    return (
        <Modal
            {...props}
            centered
        >
            <Modal.Body>
                <div className="VerifyOTP-container">
                    <div className="VerifyOTP-view">

                        <div className="VerifyOTP-modal-header">
                            {/* <p>Verify OTP for {props?.header}</p> */}
                            <p>{props?.header}</p>
                            <div className="crossimg_container">
                                <img
                                    className="crossimg-img"
                                    src={crossimg}
                                    onClick={() => {
                                        setRunTimer(false)
                                        props.hide()
                                    }}
                                />
                            </div>
                        </div>
                        <div className="VerifyOTP-filed">
                            <div className="VerifyOTP-message">
                            {props?.getOtp === 'OKYC' ?
                                <p>Enter The OTP Sent To Your Mobile By UIDAI</p>
                                :
                                <>
                                <p>Enter The OTP Sent To Your Mobile</p>
                                {props?.mobile && <span>XXXXXX{props?.mobile?.slice(6)}</span>}
                                </>
                                }
                            </div>
                            <div className="form-group text-center">
                                <input
                                    type="tel"
                                    className="input-box"
                                    id='ist'
                                    onChange={(e) => e.target.validity.valid && clickEvent(e, undefined, 'sec')}
                                    name="otp_1"
                                    required=""
                                    maxLength="1"
                                />
                                <input
                                    type="tel"
                                    className="input-box"
                                    id="sec"
                                    onChange={(e) => e.target.validity.valid && clickEvent(e, undefined, 'third')}
                                    onKeyDown={(e) => clickEvent2(e, 'sec', 'ist')}
                                    name="otp_2"
                                    required=""
                                    maxLength="1"
                                />

                                <input
                                    type="tel"
                                    className="input-box"
                                    id="third"
                                    onChange={(e) => e.target.validity.valid && clickEvent(e, undefined, 'fourth')}
                                    onKeyDown={(e) => clickEvent2(e, 'third', 'sec')}
                                    name="otp_3"
                                    required=""
                                    maxLength="1"
                                />
                                <input
                                    type="tel"
                                    className="input-box"
                                    id="fourth"
                                    onChange={(e) => e.target.validity.valid && clickEvent(e, undefined, 'fifth')}
                                    onKeyDown={(e) => clickEvent2(e, 'fourth', 'third')}
                                    name="otp_4"
                                    required=""
                                    maxLength="1"
                                />

                                <input
                                    type="tel"
                                    className="input-box"
                                    id="fifth"
                                    onChange={(e) => e.target.validity.valid && clickEvent(e, undefined, 'sixth')}
                                    onKeyDown={(e) => clickEvent2(e, 'fifth', 'fourth')}
                                    name="otp_5"
                                    required=""
                                    maxLength="1"
                                />

                                <input
                                    type="tel"
                                    className="input-box"
                                    id="sixth"
                                    onKeyDown={(e) => clickEvent2(e, 'sixth', 'fifth')}
                                    name="otp_6"
                                    required=""
                                    maxLength="1"
                                    // onBlur={fetchOtpInput}
                                    onChange={fetchOtpInput}
                                />
                            </div>
                            {props?.getOtp === 'OKYC' && otp.length === 6 &&
                                <p className="consent_submit">By Submitting, I confirm to proceed for AADHAAR verification.</p>}
                            <div className="btn-container">
                                {otp.length === 6 &&
                                    <Button
                                        type="button"
                                        buttonStyle="btn--secondary--solid"
                                        buttonSize="btn-medium"
                                        onClick={() => handleVerify("verify")}

                                    >
                                        Verify
                                    </Button>
                                }
                                {showResendButton === true ?
                                    <button
                                        type="button"
                                        className="custom-btn-style"
                                        onClick={() => handleVerify("resend")}
                                    >
                                        Resend OTP
                                    </button>
                                    :
                                    <p className="timer_view">Did Not Received OTP, Retry In : {minutes}:{seconds}</p>
                                }
                            </div>
                        </div>

                    </div>
                    <SpokesLoader
                        show={loader}
                        message={"Loading ..."}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default VerifyOTP;
