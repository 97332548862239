import React, { useEffect, useState, useRef } from "react";
import '../../index.css';
import './style.scss';
import completed from '../../assets/images/completed.svg';
import crossimg from '../../assets/images/cancel.png';
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import { useLocation } from "react-router-dom";
import SpokesLoader from "../../components/Modal/SpokesLoader";


function SignedSuccessfully() {

    const { applicantStore } = useStores();
    const state = useLocation();

    const [loader, setLoader] = useState(false);
    const [nachStatus, setNachStatus] = useState('');
    const [showSuccessText, setShowSuccesssText] = useState(false);
    const [accountType, setAccountType] = useState('')
    const [transactionId, setTransactionId] = useState('')
    const [umrnNumber, setUmrnNumber] = useState('')

    useEffect(() => {
        if (state?.state !== null) {
            setAccountType(state?.state?.accountType);
        }
    }, [])

    const handleGetNachStatus = async () => {

        setLoader(true)

        let id = sessionStorage.getItem('embeddedPartnerId');

        let params = `retailerId=${id}`

        await applicantStore.getNachMandateStatus(params, (response) => {
            console.log("getNachMandateStatus ", response);
            if (response?.message === "Success") {  
                console.log("getNachMandateStatus Success ", response)
                notify(response?.result?.txn_msg)
                setTransactionId(response?.result?.txn_id)
                setUmrnNumber(response?.result?.umrn_no)
                setShowSuccesssText(true)
                setLoader(false)
            } else {
                console.log("getNachMandateStatus else ", response)
                notify(response?.exceptionMessage, 'danger')
                setLoader(false)
                return;
            }
        })
    }

    return (
        <div className="SignedSuccessfully-container">
            <div className='SignedSuccessfully_View'>
                {/* <div className="close-icon-top">
                    <img
                        className="crossimg-img"
                        src={crossimg}
                    />
                </div> */}
                <div className="SignedSuccessfully_body">
                    {showSuccessText === true ?
                        <div className="SignedSuccessfully_body">
                            <img
                                className="completed-img"
                                src={completed}
                            />
                            <h1 className="mb-3">e-NACH registration successful</h1>
                            <p>{`Your Transaction Id: ${transactionId}`}</p>
                            <p className="mb-2">{`Your UMRN No.: ${umrnNumber}`}</p>
                            <p className="mt-3">Your Journey has completed successfully</p>
                        </div>
                        :
                        <div className="SignedSuccessfully_body">
                            <h1>E-NACH registration status</h1>
                            <p className="text-center mb-5">Please complete your E-NACH registration to complete the journey</p>
                            <div className="submit-btn">
                                <button
                                    className="btn-view"
                                    onClick={() => { handleGetNachStatus() }}
                                >
                                    GET STATUS
                                </button>
                            </div>
                        </div>
                    }
                    <SpokesLoader
                        show={loader}
                        message={"Loading ..."}
                    />
                </div>
            </div>
        </div>
    );
}

export default SignedSuccessfully;

