import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar";
import Clip from "../../assets/images/attach_file.png";
import basicinfo from "../../assets/images/basicinfo.svg";
import vrifiedIcon from "../../assets/images/verified_icon.svg";
import crossimg from "../../assets/images/cancel.png";

import "./style.scss";
import Button from "../../components/UI/Button/Button";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../stores";
import convertBase64 from "../../Helper/convertBase64";
import CustomInput from "../../components/UI/Form-input/CustomInput";
import notify from "../../shared/notify";
import SpokesLoader from "../../components/Modal/SpokesLoader";

function PanUpload() {
  const Input1 = useRef();

  const navigate = useNavigate();
  const { applicantStore } = useStores();

  const id = sessionStorage.getItem("embeddedPartnerId");

  const loginData = sessionStorage.getItem("loginData");
  const data = JSON.parse(loginData);

  let [fileSize, setFileSize] = useState(0);

  const [uploadPan, setUploadPan] = useState("");
  const [panbase64, setpanbase64] = useState("");
  const [panName, setpanName] = useState("");
  const [pan, setPan] = useState("");
  const [isVerified, setIsVerified] = useState(true);
  const [isPanUpload, setIsPanUpload] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showPanImage, setShowPanImage] = useState("");

  useEffect(() => {
    // window.history.pushState(null, null, window.location.href);
    // window.onpopstate = function (event) {
    //   navigate(`/${process.env.REACT_APP_BASE_FOLDER}/SessionExpired`)

    // };
  }, [])
  

  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    const filesize = file.size / 1000000;
    const fileName = file.name;
    const base64 = await convertBase64(file);
    return { base64, filesize, fileName };
  };

  const handlePanUpload = async (e) => {
    const result = await handleFileRead(e);
    const data = result.base64;
    const name = result.fileName;
    let currentFileSize = fileSize + result.filesize;
    setFileSize(currentFileSize);
    if (currentFileSize > 4) {
      notify(
        "Please Upload File Up To 4MB of JPEG, JPG, PNG, & Up To 2 MB Of PDF format",
        "danger"
      );
      setFileSize(currentFileSize - result.filesize);
      Input1.current.value = "";
      setpanName("");
      return;
    }
    console.log(data.split(",")[1]);
    setpanbase64(data.split(",")[1]);
    setpanName(name);
    setShowPanImage(data);

    sessionStorage.setItem("panImageBase64", e.base64);
    sessionStorage.setItem("panImageFileName", panName);
  };

  const handleSubmit = () => {
    if (panName == "") {
      notify("Please Upload PAN", "danger");
      return;
    }
    let payload = {
      documentName: panName,
      documentBase64: panbase64,
    };
    setLoader(true);
    let params = `retailerId=${id}`;
    applicantStore.uploadPAN(params, payload, (response) => {
      if (response?.data?.exceptionOccured == "N") {
        setPan(response?.data?.result?.result[0]?.details?.panNo?.value);
        setIsPanUpload(true);
        setLoader(false);
        setIsVerified(true);
      } else {
        if(response?.data?.status == 106)
        {
          navigate(`/${process.env.REACT_APP_BASE_FOLDER}/NoOfferFound`);
          notify(response?.data?.exceptionMessage, "danger");
          setLoader(false);
          setPan(data?.pan);
          setIsPanUpload(true);
          setLoader(false);
          setIsVerified(false);
        }else if (response?.data?.status == 500) {
          notify(response?.data?.exceptionMessage, "danger");
          navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
          setLoader(false);
          setPan(data?.pan);
          setIsPanUpload(true);
          setLoader(false);
          setIsVerified(false);
          return;
         }else{
          // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/NoOfferFound`);
          notify(response?.data?.exceptionMessage, "danger");
          setLoader(false);
          setPan(data?.pan);
          setIsPanUpload(true);
          setLoader(false);
          setIsVerified(false)
        }
        // notify('Please Upload A clear Image of PAN Card.','danger')
      }
    });
  };

  return (
    <>
      <Navbar logout={true} appid={true} />
      <div className="panupload-container">
      <div className="content-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
              <div className="top-header-title">
                  <h2>PAN Card Information</h2>
                  <hr className="heading-divider" />
                </div>
                <div className="top-header-content">
                  <img className="header-img" src={basicinfo} />
                  <p>Please Upload PAN Card </p>
                </div>
                  {!isPanUpload ? (
                    <div className="pan-upload-box">
                          <div className="file file--upload pan-card">
                            <label for="input-file">
                              <h3>PAN Card</h3>
                              {showPanImage == "" ? (
                                <p>+</p>
                              ) : (
                                <img
                                  for="input-file"
                                  className="card-icon-img"
                                  src={showPanImage}
                                  alt="Pan Card image"
                                />
                              )}
                            </label>

                            <input
                              ref={Input1}
                              id="input-file"
                              type="file"
                              value={uploadPan}
                              onChange={(e) => {
                                handlePanUpload(e);
                              }}
                              accept="image/*,.pdf"
                            />
                          </div>
                          <p className="panselected_file_name">{panName}</p>
                      <Button
                        type="button"
                        buttonStyle="btn--secondary--solid"
                        buttonSize="btn-medium"
                        onClick={() => {
                          // setIsPanUpload(true);
                          handleSubmit();
                          // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/UploadSelfie`)
                        }}
                      >
                        Upload
                      </Button>
                    </div>
                  ) : (
                    <div className="mt-4">
                      <img
                        className="card-img"
                        src={showPanImage}
                        alt="Pan Card image"
                      />
                      <div
                        className={
                          isVerified
                            ? "verified_icon_container"
                            : "notverified_icon_container"
                        }
                      >
                        {isVerified ? (
                          <>
                            <img className="verified_icon" src={vrifiedIcon} />
                            <p>{`PAN Card Image Uploaded Successfully`}</p>
                          </>
                        ) : (
                          <>
                            <img className="verified_icon" src={crossimg} />
                            <p>{`Name As Per PAN Card Uploaded Not Matching With Applicant Name`}</p>
                          </>
                        )}
                      </div>
                      <div className="mt-3">
                        <CustomInput
                          type="text"
                          label="PAN"
                          placeholder="Enter Your PAN"
                          name={pan}
                          value={pan}
                          // onBlur={(e) =>
                          //     pan.length > 0 && handlePanOnBlur(e.target.value)
                          // }
                          onChange={(e) => setPan(e.target.value)}
                          disabled={pan != "" ? true : false}
                          // mandatory={true}
                        />
                      </div>
                      <div className="submit_container">
                        <Button
                          type="button"
                          buttonStyle="btn--secondary--solid"
                          buttonSize="btn-medium"
                          onClick={() => {
                            // {isVerified &&
                            // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/UploadSelfie`);
                            // }
                            {
                              isVerified
                                ? navigate(
                                    `/${process.env.REACT_APP_BASE_FOLDER}/UploadSelfie`
                                  )
                                : notify(
                                    "Please upload valid pan document",
                                    "danger"
                                  );
                            }
                          }}
                        >
                          Submit
                        </Button>
                        <p
                          onClick={() => {
                            setIsPanUpload(false);
                            setpanbase64("");
                            // let panName = `${loanRequestId}_FinAgg_PAN_${formattedDate}.${fileType}`
                            setpanName("");
                            setShowPanImage("");
                          }}
                        >
                          Reupload PAN
                        </p>
                      </div>
                    </div>
                  )}
                </div>
            </div>
            <SpokesLoader show={loader} message={"Loading ..."} />
          </div>
        </div>
      </div>
    </>
  );
}

export default PanUpload;
