import React from "react";
import SpokesLoader from "../SpokesLoader";
import Modal from "react-bootstrap/Modal";
import cameraIcon from "../../../assets/images/cameraIcon.png";
import gallery from "../../../assets/images/gallery.png";
import "./style.scss";
import Button from "../../UI/Button/Button";
import Webcam from "react-webcam";
import Close from '../../../assets/images/close.png';

function CameraModal(props) {
  // const webcamRef = React.useRef(null);
  // const capture = React.useCallback(() => {
  //   const imageSrc = webcamRef.current.getScreenshot();
  //     console.log(imageSrc.split(",")[1])
  //     let 
  //   // console.log(webcamRef.current);
  // }, [webcamRef]);

  const videoConstraints = {
    width: 350,
    height: 350,
    facingMode: "user",
  };

  return (
    <>
      <Modal {...props} centered className="camera-container">
        <Modal.Body>
          <div className="modal-close-icon" onClick={props.hide}>
            <img src={Close} className="cancle" />
          </div>
          <div className="camera-body">
            <Webcam
              audio={false}
              height={"100%"}
              ref={props.webcamRef}
              screenshotFormat="image/jpeg"
              width={350}
              videoConstraints={videoConstraints}
            />
            <button className="btn-view" onClick={props.capture}>
              Capture Photo
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CameraModal;
