import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import "./style.scss";
import crossimg from '../../../assets/images/cancel.png';

function AccessModal(props) {
    return (
        <Modal
            {...props}
            centered
            className="AccessModal-container"
        >
            <Modal.Body>

                <div className="content_acces-view">
                    <div className="cross_icon_img">
                        <img
                            className="cross-img"
                            src={crossimg}
                            onClick={props.hide}
                        />
                    </div>
                    <p className="sub-heading">{props.message}</p>
                    <button className="ok-button" onClick={props.hide}>OK</button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default AccessModal;
