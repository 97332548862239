const udyamRegistrationValidate = (text) => {

    let   regex = /^([a-zA-Z]){5}-([a-zA-Z]){2}-([0-9]){2}-([0-9]){7}?$/;
  
    if(regex.test(text)) {
  
         return true;
  
    }
  
    return false;
  }
  
  export default udyamRegistrationValidate;