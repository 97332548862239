import React, { useEffect, useState, useRef } from "react";
import "../../index.css";
import "./style.scss";
import ekycimg from "../../assets/images/aadharkyc.png";
// import ekycimg from '../../assets/images/ekycimg/ekycimg.webp';
import Navbar from "../../components/Navbar";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../components/UI/Form-input/CustomInput";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import VerifyOTP from "../../components/Modal/VerifyOTP";
import SpokesLoader from "../../components/Modal/SpokesLoader";
import Clip from "../../assets/images/attach_file.png";
import Button from "../../components/UI/Button/Button";
import convertBase64 from "../../Helper/convertBase64";
import applicantStore from "../../stores/applicantStore";

function EKYC() {
  const navigate = useNavigate();
  const { businessDetailsStore, applicantStore } = useStores();
  const Input2 = useRef();
  const Input3 = useRef();

  const id = sessionStorage.getItem("embeddedPartnerId");
  let retailerId = sessionStorage.getItem("retailerId");

  let [fileSize, setFileSize] = useState(0);

  const [aadhar, setAadhar] = useState("");
  const [question, setQuestion] = useState("");
  const [AadharFront, setAadharFront] = useState("");
  const [AadharBack, setAadharBack] = useState("");
  const [AadharFrontBase64, setAadharFrontBase64] = useState("");
  const [AadharBackBase64, setAadharBackBase64] = useState("");
  const [aadharNumber, setAadharNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [loader, setLoader] = useState(false);
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const [showOtpfield, setShowOtpfield] = useState(true);
  const [otpModal, setOtpModal] = useState(false);

  let timerId;

  useEffect(() => {
    // window.history.pushState(null, null, window.location.href);
    // window.onpopstate = function (event) {
    //   navigate(`/${process.env.REACT_APP_BASE_FOLDER}/SessionExpired`)

    // };
  }, [])


  useEffect(() => {
    if (runTimer) {
      setRunTimer(true);
    } else {
      setRunTimer(false);
    }

    if (runTimer) {
      setCountDown(60 * 5);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      // console.log("expired");
      setShowResendButton(true);
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  const handleEKyc = () => {
    if (AadharFront === "" || AadharBack === "") {
      notify(
        "Please Upload Image Of Front Side And Back Side Of Your Aadhaar. ",
        "danger"
      );
      return;
    }
    if (aadharNumber === "") {
      notify("Please Enter Your Aadhaar Number", "danger");
      return;
    }
    if (aadharNumber.length < 12) {
      notify("Please Enter a Valid Aadhaar Number", "danger");
      return;
    }

    let params = `retailerId=${retailerId}&aadharNumber=${aadharNumber}`;

    setLoader(true);
    businessDetailsStore.oKycGetOtp(params, {}, (response) => {
      console.log("okyc get otp response ", response);
      if (response?.data?.message === "Success") {
        setLoader(false);
        setRunTimer(true);
        setShowOtpfield(true);
        setShowResendButton(false);
        setOtpModal(true);
        // setOtpModal(true)
        notify(response?.data?.result?.result?.message);
      } else {
        setLoader(false);
        // setOtpModal(false)
        notify(response?.data?.exceptionMessage, "danger");
        return;
      }
    });
  };

  let params = `retailerId=${id}&aadharNumber=${aadhar}`;

  const validateOtp = () => {
    if (otp === "") {
      notify("Please Enter Otp", "danger");
      return;
    }
    setLoader(true);

    let params = `OTP=${otp}&retailerId=${retailerId}&aadharNumber=${aadharNumber}`;
    console.log(params);
    businessDetailsStore.oKycVerifyOtp(params, {}, (response) => {
      console.log("okyc Verify otp response ", response);
      if (response?.data?.exceptionOccured == "N") {
        if (response?.data?.result?.status == "success") {
          notify(response?.data?.result?.statusMessage);
          setLoader(false);
          navigate(`/${process.env.REACT_APP_BASE_FOLDER}/AadhaarVerification`);
        }
      } else {
        if (response?.data?.status == 107) {
          navigate(`/${process.env.REACT_APP_BASE_FOLDER}/NoOfferFound`);
          notify(response?.data?.exceptionMessage, "danger");
          setLoader(false);
        } else if (response?.data?.status == 500) {
          notify(response?.data?.exceptionMessage, "danger");
          navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
          setLoader(false)
          return;
        } else {
          notify(response?.data?.exceptionMessage, "danger");
          setLoader(false);
        }
      }
    });
  };

  const handleUploadAadhar = (payload) => {
    let params = `retailerId=${retailerId}`;
    setLoader(true);
    applicantStore.uploadAadhar(params, payload, (res) => {
      if (res?.data?.exceptionOccured == "N") {
        notify(res?.data?.message);
        setLoader(false);
      } else {
        notify(res?.data?.exceptionMessage, "danger");
        setLoader(false);
      }
    });
  };

  const handleAadharFrontUpload = async (e) => {
    const result = await handleFileRead(e);
    const data = result.base64;
    const name = result.fileName;
    let currentFileSize = fileSize + result.filesize;
    setFileSize(currentFileSize);
    const fileType = result.base64.split(";")[0];
    const type = fileType.split(":")[1];
    if (type === "image/jpeg" || type === "image/png" || type === "image/jpg" || type == "application/pdf") {
      if (currentFileSize > 4) {
        notify(
          "Please Upload File Up To 4MB of JPEG, JPG, PNG, & Up To 2 MB Of PDF format",
          "danger"
        );
        setFileSize(currentFileSize - result.filesize);
        Input2.current.value = "";
        setAadharFront("");
        return;
      }
      setAadharFrontBase64(data.split(",")[1]);
      setAadharFront(name);
      let payload = {
        documentName: name,
        documentBase64: data.split(",")[1],
      };

      handleUploadAadhar(payload);
    } else {
      notify(
        "Please Upload File Up To 4MB of JPEG, JPG, PNG, & Up To 2 MB Of PDF format",
        "danger"
      );
      Input2.current.value = "";
      setAadharFront("");
      return;
    }
  };

  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    const filesize = file.size / 1000000;
    const fileName = file.name;
    const base64 = await convertBase64(file);
    return { base64, filesize, fileName };
  };

  const handleAadharBackUpload = async (e) => {
    const result = await handleFileRead(e);
    const data = result.base64;
    const name = result.fileName;
    let currentFileSize = fileSize + result.filesize;
    setFileSize(currentFileSize);
    const fileType = result.base64.split(";")[0];
    const type = fileType.split(":")[1];
    if (type === "image/jpeg" || type === "image/png" || type === "image/jpg" || type == "application/pdf") {
      if (currentFileSize > 4) {
        notify(
          "Please Upload File Up To 4MB of JPEG, JPG, PNG, & Up To 2 MB Of PDF format",
          "danger"
        );
        setFileSize(currentFileSize - result.filesize);
        Input3.current.value = "";
        setAadharBack("");
        return;
      }

      setAadharBackBase64(data.split(",")[1]);
      setAadharBack(name);
      let payload = {
        documentName: name,
        documentBase64: data.split(",")[1],
      };
      handleUploadAadhar(payload);
    } else {
      notify(
        "Please Upload File Up To 4MB of JPEG, JPG, PNG, & Up To 2 MB Of PDF format",
        "danger"
      );
      Input3.current.value = "";
      setAadharBack("");
      return;
    }
  };

  function clickEvent(first, current, last) {
    if (current == undefined) {
      if (first.target.value.length) {
        document.getElementById(last).maxLength = 1;
        document.getElementById(last).focus();
      }
    }
  }


  function clickEvent2(first, current, last) {
    var input = document.getElementById(current);

    input.addEventListener('keydown', function (event) {
      const key = event.key; // const {key} = event; ES6+
      if (key === "Backspace") {
        if (current != undefined) {
          document.getElementById(current).maxLength = 1;
          if (current == 'sixth' && first.target.value.length != 0) {
            document.getElementById(current).focus();
          } else {
            document.getElementById(last).focus();
          }
        }
      }
    });
  }

  const fetchOtpInput = (type) => {
    let ist = document.getElementById('ist').value;
    let sec = document.getElementById('sec').value;
    let third = document.getElementById('third').value;
    let fourth = document.getElementById('fourth').value;
    let fifth = document.getElementById('fifth').value;
    let sixth = document.getElementById('sixth').value;

    let otpInput = ist + sec + third + fourth + fifth + sixth;
    setOtp(otpInput);
    if (otpInput.length === 6) {
      // setShowVerifyButton(true)
    }
  }

  return (
    <>
      <Navbar logout={true} appid={true} />
      <div className="eKYC-container">
        <div className="content-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="top-header-title">
                  <h2>Aadhaar - OKYC</h2>
                  <hr className="heading-divider" />
                </div>
                <div className="top-header-content">
                  <img className="header-img col-icon" src={ekycimg} />
                  <p>Please Provide Aadhaar Details</p>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="address-form-group form-input">
                      <label>Upload Front Side Of Aadhaar</label>
                      <div className="file file--upload">
                        <label for="input-file-front">
                          {AadharFront == "" ? (
                            <>
                              <div className="left-clip">
                                <img className="Clip-img" src={Clip} /> Select
                                Your File
                              </div>
                              <div className="right-clip"><span>+</span></div>
                            </>
                          ) : (
                            <p className="word-break">{AadharFront}</p>
                          )}
                        </label>

                        <input
                          ref={Input2}
                          id="input-file-front"
                          type="file"
                          // ref={drugInput}
                          onChange={(e) => handleAadharFrontUpload(e)}
                          accept="image/*,.pdf"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="address-form-group form-input">
                      <label>Upload Back Side Of Aadhaar</label>
                      <div className="file file--upload">
                        <label for="input-file-back">
                          {AadharBack == "" ? (
                            <>
                              <div className="left-clip">
                                <img className="Clip-img" src={Clip} /> Select
                                Your File
                              </div>
                              <div className="right-clip"> <span>+</span></div>
                            </>
                          ) : (
                            <p className="word-break">{AadharBack}</p>
                          )}
                        </label>
                        <input
                          ref={Input3}
                          id="input-file-back"
                          type="file"
                          // ref={drugInput}
                          onChange={(e) => handleAadharBackUpload(e)}
                          accept="image/*,.pdf"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <hr className="hr-line" />
                  </div>
                  <div className="col-md-6">
                    <div className="address-form-group">
                      <label>Aadhaar Number</label>
                      <br />
                      <input
                        type="text"
                        name="address"
                        id="address"
                        placeholder="Enter Aadhaar Number"
                        maxLength={12}
                        value={aadharNumber}
                        onChange={(e) => {
                          setAadharNumber(e.target.value);
                        }}
                      />
                    </div>

                  </div>
                  {/* {!showOtpfield && ( */}
                  <div className="col-md-12 aadhaar-otp-btn">
                    <Button
                      type="button"
                      buttonStyle="btn--secondary--solid"
                      buttonSize="btn-medium"
                      onClick={() => {
                        handleEKyc();
                      }}
                    >
                      Send OTP
                    </Button>
                  </div>
                  {/* )} */}

                  {/* {showOtpfield && (
                    <>
                      <div className="col-md-6">
                        <div className="address-form-group">
                          <label>
                            Enter The OTP Sent To Your Mobile By UIDAI
                          </label>
                          <br />
                          <div>
                            <div className="form-group ekyc-otp">
                              <input
                                type="tel"
                                className="input-box"
                                id='ist'
                                onChange={(e) => e.target.validity.valid && clickEvent(e, undefined, 'sec')}
                                name="otp_1"
                                required=""
                                maxLength="1"
                              />
                              <input
                                type="tel"
                                className="input-box"
                                id="sec"
                                onChange={(e) => e.target.validity.valid && clickEvent(e, undefined, 'third')}
                                onKeyDown={(e) => clickEvent2(e, 'sec', 'ist')}
                                name="otp_2"
                                required=""
                                maxLength="1"
                              />

                              <input
                                type="tel"
                                className="input-box"
                                id="third"
                                onChange={(e) => e.target.validity.valid && clickEvent(e, undefined, 'fourth')}
                                onKeyDown={(e) => clickEvent2(e, 'third', 'sec')}
                                name="otp_3"
                                required=""
                                maxLength="1"
                              />
                              <input
                                type="tel"
                                className="input-box"
                                id="fourth"
                                onChange={(e) => e.target.validity.valid && clickEvent(e, undefined, 'fifth')}
                                onKeyDown={(e) => clickEvent2(e, 'fourth', 'third')}
                                name="otp_4"
                                required=""
                                maxLength="1"
                              />

                              <input
                                type="tel"
                                className="input-box"
                                id="fifth"
                                onChange={(e) => e.target.validity.valid && clickEvent(e, undefined, 'sixth')}
                                onKeyDown={(e) => clickEvent2(e, 'fifth', 'fourth')}
                                name="otp_5"
                                required=""
                                maxLength="1"
                              />

                              <input
                                type="tel"
                                className="input-box"
                                id="sixth"
                                onKeyDown={(e) => clickEvent2(e, 'sixth', 'fifth')}
                                name="otp_6"
                                required=""
                                maxLength="1"
                                // onBlur={fetchOtpInput}
                                onChange={fetchOtpInput}
                              />
                            </div>
                          </div>
                          {/* <input
                            type="text"
                            name="address"
                            id="address"
                            placeholder="OTP"
                            maxLength={6}
                            value={otp}
                            onChange={(e) => {
                              setOtp(e.target.value.replace(/\s/g, ''));
                            }}
                          /> */}
                  {/* </div>
                      </div>


                      <p className="confirm_submit">By Submitting, I confirm to proceed for AADHAAR verification.</p>
                      <div className="col-md-12 aadhaar-otp-btn">
                        <Button
                          type="button"
                          buttonStyle="btn--secondary--solid"
                          buttonSize="btn-medium"
                          onClick={() => {
                            // handleVerify("verify")AadhaarVerification
                            validateOtp();
                          }}
                        >
                          Proceed
                        </Button> */}

                  {/* <div className="col-md-12 text-center mt-3">
                          {showResendButton === true ? (
                            <h3
                              className="resent-otp-text"
                              onClick={() => {
                                handleEKyc();
                              }}
                            >
                              Resend OTP
                            </h3>
                          ) : (
                            <p className="timer_view">
                              Did Not Received OTP, Retry In : {minutes}:{seconds}
                            </p>
                          )}
                        </div> */}
                  {/* </div> */}

                  {/* </>
                  )} */}
                </div>
              </div>
              <VerifyOTP
                show={otpModal}
                getOtp={'OKYC'}
                hide={() => setOtpModal(false)}
                isLoggedIn={true}
                aadharNumber={aadharNumber}
                // navigate={() => {
                //   navigate(`/${process.env.REACT_APP_BASE_FOLDER}/UdyamRegistration`)
                // }}
                header={"OKYC"}
                stopLoader={() => setLoader(false)}
              />
              {<SpokesLoader show={loader} message={"Loading ..."} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EKYC;
