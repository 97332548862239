import React, { useState } from "react";
import udhyam from "../../assets/images/udyam-icon.svg";
import "../../index.css";
import "./style.scss";
import date from "../../assets/images/date.png";
import CustomInput from "../../components/UI/Form-input/CustomInput";
import Navbar from "../../components/Navbar";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../stores";
import notify from "../../shared/notify";

function UdhyamRegistrationForm() {
  const navigate = useNavigate();
  const { businessDetailsStore } = useStores();

  let retailerId = sessionStorage.getItem("retailerId");

  const [voteId, setVoteId] = useState("");
  const [state, setState] = useState("");
  const [code, setCode] = useState("");
  const [NumberOfPerson, setNumberOfPerson] = useState("");

  const handlesubmit = (e) => {
    e.preventDefault();
    // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/BasicBusinessInformation`)
    if (voteId === "") {
      notify("Please enter voteid", "danger");
      return;
    }
    if (state === "") {
      notify("Please select  state ", "danger");
      return;
    }
    if (code === "") {
      notify("Please select BSR Code", "danger");
      return;
    }
    if (NumberOfPerson === "") {
      notify("Please enter Number Of Persons Employed", "danger");
      return;
    }

    let params = `retailerId=${retailerId}`;

    let payload = {};

    businessDetailsStore.udyamRegistrationForm(params, payload, (response) => {
      if (response?.data?.exceptionOccured == "N") {
        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ThankYou2`);
        notify(response?.data?.result?.statusMessage, "success");
      } else {
        notify(response?.data?.exceptionMessage);
      }
    });
  };

  const id = sessionStorage.getItem("embeddedPartnerId");
  return (
    <>
      <Navbar logout={true} appid={true} />
      <div className="UdhyamRegistration-container">
        <div className="content-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="top-header-title">
                  <h2>Udyam Registration Form</h2>
                  <hr className="heading-divider" />
                </div>
                <div className="top-header-content">
                  <img className="header-img" src={udhyam} />
                  <p>
                    Please Provide Below Details So That We Can Apply For Udyam
                    On Behalf Of You.
                  </p>
                </div>
                  <div className="row">
                    <div className="col-md-4">
                      <CustomInput
                        type="text"
                        label="Voter ID Number"
                        placeholder="Voter ID Number"
                        value={voteId}
                        onChange={(e) => {
                          setVoteId(e.target.value);
                        }}
                        // isEditable={true}
                        // style={'input-disabled'}
                      />
                    </div>
                    <div className="col-md-4">
                    <div className="form-input">
                      <label>State</label>
                      <select
                        className="form-control"
                        value={state}
                        onChange={(e) => {
                          setState(e.target.value);
                        }}
                      >
                        <option value="">Select State</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajesthan">Rajesthan</option>
                      </select>
                    </div>
                    </div>
                    <div className="col-md-4">
                    <CustomInput
                      type="text"
                      label="BSR Code"
                      placeholder="BSR Code"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
</div>
<div className="col-md-4">
                    <CustomInput
                      type="text"
                      label="Number Of Persons Employed"
                      placeholder="Number Of Persons Employed"
                      value={NumberOfPerson}
                      onChange={(e) => {
                        setNumberOfPerson(e.target.value);
                      }}
                    />
                    </div>
                  </div>
                </div>
                <div className="submit-btn">
                  <button
                    className="btn-view"
                    onClick={(e) => {
                      handlesubmit(e);
                    }}
                  >
                    Proceed
                  </button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UdhyamRegistrationForm;
