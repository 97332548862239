import React, { useEffect, useState, useRef } from "react";
import moment from 'moment'
import '../../index.css';
import './style.scss';
import notify from "../../shared/notify";
import businessentity from '../../assets/images/businessentity.svg';
import CustomInput from "../../components/UI/Form-input/CustomInput";
import VerifyOTP from "../../components/Modal/VerifyOTP";
import ValidateEmail from "../../Helper/emailValidate";
import SpokesLoader from "../../components/Modal/SpokesLoader";
import { useNavigate } from "react-router-dom";
import { useStores } from '../../stores'
import axios from "axios";
import date from '../../assets/images/date.png';

const STATES = [
    { 'value': 'Andaman and Nicobar Islands' },
    { 'value': 'Andhra Pradesh' },
    { 'value': 'Arunachal Pradesh' },
    { 'value': 'Assam' },
    { 'value': 'Bihar' },
    { 'value': 'Chandigarh' },
    { 'value': 'Chhattisgarh' },
    { 'value': 'Dadra & Nagar Haveli and Daman & Diu' },
    { 'value': 'Delhi' },
    { 'value': 'Goa' },
    { 'value': 'Gujarat' },
    { 'value': 'Haryana' },
    { 'value': 'Himachal Pradesh' },
    { 'value': 'Jammu and Kashmir' },
    { 'value': 'Jharkhand' },
    { 'value': 'Karnataka' },
    { 'value': 'Kerala' },
    { 'value': 'Ladakh' },
    { 'value': 'Lakshadweep' },
    { 'value': 'Madhya Pradesh' },
    { 'value': 'Maharashtra' },
    { 'value': 'Manipur' },
    { 'value': 'Meghalaya' },
    { 'value': 'Mizoram' },
    { 'value': 'Nagaland' },
    { 'value': 'Odisha' },
    { 'value': 'Puducherry' },
    { 'value': 'Punjab' },
    { 'value': 'Rajasthan' },
    { 'value': 'Sikkim' },
    { 'value': 'Tamil Nadu' },
    { 'value': 'Telangana' },
    { 'value': 'Tripura' },
    { 'value': 'Uttar Pradesh' },
    { 'value': 'Uttarakhand' },
    { 'value': 'West Bengal' }
]


function VerifiedDetails() {
    let localStorage;
    let localStorageData;

    const dobRef = useRef();
    const navigate = useNavigate();

    const { authStore } = useStores();

    const [otpModal, setOtpModal] = useState(false);
    const [pan, setPan] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [userstate, setUserState] = useState('');
    const [city, setCity] = useState('');
    const [pincode, setPincode] = useState('');
    const [companyName, setCompanyName] = useState('');

    const name = sessionStorage.getItem('companyName')
    const id = sessionStorage.getItem('embeddedPartnerId')

    // useEffect(() => {
    //     sessionStorage.removeItem('redirectPending')
    //     titleCase(name).then((res) => {
    //         setCompanyName(res)
    //     })
    // }, [])

    const titleCase = async (str) => {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // setCompanyName(splitStr.join(' '))
        return splitStr.join(' ');
    }

    const showloader = () => {
        setOtpModal(true);
    }

    // useEffect(() => {
    //     localStorage = sessionStorage.getItem('UserData');
    //     localStorageData = JSON.parse(localStorage);
    //     if (localStorageData[0]?.panNumber !== 'null') {
    //         setPan(localStorageData[0]?.panNumber)
    //     }
    //     if (localStorageData[0]?.mobile !== 'null') {
    //         setMobileNumber(localStorageData[0]?.mobile)
    //     }
    // }, [])

    useEffect(() => {
        if (otpModal) {
            setTimeout(() => {
                setOtpModal(false)
                navigate(`/${process.env.REACT_APP_BASE_FOLDER}/BusinessDetails`);
            }, 3000);
        }
    }, [otpModal])

    const handleSubmit = async () => {
        if (pan === '') {
            notify('Please enter PAN number', 'danger');
            return;
        }
        if (mobileNumber === '') {
            notify('Please enter moblie number', 'danger')
            return;
        }
        if (dateOfBirth === '') {
            notify('Please enter date of birth', 'danger')
            return;
        }
        if (email === '') {
            notify('Please enter email', 'danger')
            return;
        }
        if (address === '') {
            notify('Please enter address', 'danger')
            return;
        }
        if (userstate === '') {
            notify('Please select state', 'danger')
            return;
        }
        if (city === '') {
            notify('Please select city', 'danger')
            return;
        }
        if (pincode === '') {
            notify('Please enter pincode', 'danger')
            return;
        }


        // showloader();
        let payload = {
            "embeddedPartnerId": id,//Retailer ID
            "dob": dateOfBirth,
            "emailId": email,
            "businessAddress": address,//Full Address
            "city": city,
            "pincode": pincode,
            "state": userstate
        }
        await authStore.kycDetails(payload, (response) => {
            if (response?.data?.result?.status === "success") {
                notify(response?.data?.result?.statusMessage)
                navigate(`/${process.env.REACT_APP_BASE_FOLDER}/BusinessDetails`)
            } else {
                if (response?.data?.status == 500) {
                    notify(response?.data?.exceptionMessage, 'danger');
                    navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
                  }else{

                      notify(response?.data?.exceptionMessage, "danger")
                      return;
                  }
            }
        })

    }

    const handleEmailOnBlur = (e) => {
        let validate = ValidateEmail(e)
        if (validate === false) {
            notify('Please enter valid Email Address', 'danger');
            setEmail('')
            return;
        } else {
            setEmail(e)
        }
    }

    return (
        <div className="VerifiedDetails-container">
            <div className='Login_View'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="Login-body">
                                <div className="Login-heading">
                                    <h1>Welcome <span>{companyName},</span></h1>
                                    <div className="businessentity-container">
                                        <img
                                            className="businessentity-img"
                                            src={businessentity}
                                        />
                                        <p>Kindly Provide PAN Details Of Your Business Entity</p>
                                    </div>
                                </div>
                                <div className="Login-form">
                                    <CustomInput
                                        type="text"
                                        label="PAN"
                                        placeholder="Enter Your PAN"
                                        value={pan}
                                        onChange={(e) => { setPan(e.target.value) }}
                                        isEditable={true}
                                        style={'input-disabled'}
                                    />
                                    <CustomInput
                                        type="text"
                                        label="Mobile Number"
                                        placeholder="Enter Mobile"
                                        value={mobileNumber}
                                        onChange={(e) => { setMobileNumber(e.target.value) }}
                                        isEditable={true}
                                        style={'input-disabled'}
                                    />
                                    {/* <CustomInput
                                        type="date"
                                        label="Date of Birth"
                                        placeholder="Enter Your PAN"
                                        value={dateOfBirth}
                                        onChange={(e) => { setDateOfBirth(e.target.value) }}
                                        maxDate={true}
                                    /> */}
                                    <div className="form-input">
                                        <label>Date of Birth</label>
                                        <div className="date-container">
                                            {/* {console.log(new Date().toDateString())} */}
                                            <input
                                                ref={dobRef}
                                                type="date"
                                                value={dateOfBirth}
                                                onBlur={(e) => {
                                                    if (e.target.validity.valid === false) {
                                                        notify('Please Enter a Valid Date', 'danger')
                                                        dobRef.current.value = ""
                                                    }
                                                }}
                                                onChange={(e) => setDateOfBirth(e.target.value)}
                                                max={new Date().toISOString().split("T")[0]}
                                            />

                                            <img
                                                className="date-img"
                                                src={date}
                                            />
                                            <div className="date-image-overlap"></div>
                                        </div>

                                    </div>
                                    <CustomInput
                                        type="text"
                                        label="Email-ID"
                                        placeholder="Enter Your Email-ID"
                                        value={email}
                                        onBlur={(e) => handleEmailOnBlur(e.target.value)}
                                        onChange={(e) => { setEmail(e.target.value) }}
                                    />
                                    <CustomInput
                                        type="text"
                                        label="Business Address"
                                        placeholder="Enter Your Business Address"
                                        value={address}
                                        onChange={(e) => { setAddress(e.target.value) }}
                                        verbiage={'(Please Ensure Your Business Address Is Correct)'}
                                    />
                                    {/* <div className="">
                                        <p>please ensure your business address is correct</p>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-input">
                                                <label>State</label>
                                                <select
                                                    className="form-control"
                                                    value={userstate}
                                                    onChange={(e) => { setUserState(e.target.value) }}
                                                >
                                                    <option value="">Select State</option>
                                                    {STATES.map((state) => {
                                                        return (
                                                            <option value={state.value}>{state.value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <CustomInput
                                                type="text"
                                                label="City"
                                                placeholder="Enter City"
                                                value={city}
                                                onChange={(e) => { setCity(e.target.value) }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-input">
                                        <CustomInput
                                            type="text"
                                            label="Pincode"
                                            placeholder="Enter Pincode"
                                            value={pincode}
                                            onChange={(e) => {
                                                e.target.validity.valid ? setPincode(e.target.value) : notify("Pin Code Can Only Contains Number", "danger")
                                            }}
                                            maxLength={6}
                                            pattern={'[0-9]*'}
                                        />
                                    </div>
                                </div>
                                <div className="submit-btn">
                                    <button className="btn-view" onClick={handleSubmit}>Submit</button>
                                </div>
                                <SpokesLoader
                                    show={otpModal}
                                    message={"Loading ..."}

                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VerifiedDetails;

