import React, { useEffect, useState, useRef } from "react";
import '../../index.css';
import './style.scss';
import Productquality from '../../assets/images/Product-quality.svg';
import credit from '../../assets/images/credit.svg';
import { useNavigate } from "react-router-dom";
import VerifyOTP from "../../components/Modal/VerifyOTP";
import notify from "../../shared/notify";
import { useStores } from "../../stores";
import SpokesLoader from "../../components/Modal/SpokesLoader";

function PrivacyPolicy(props) {
  const navigate = useNavigate();
  const { authStore, applicantStore } = useStores();;
  const id = sessionStorage.getItem('embeddedPartnerId')

  // const [loader, setLoader] = useState(true);
  // const [retailerFound, setRetailerFound] = useState(false);
  // const [found, setFound] = useState(false);

  // useEffect(() => {
  //   sessionStorage.clear()
  //   // sessionStorage.setItem('embeddedPartnerId', 2)
  //   getQueryVariable() 
  //   validateRetailer();
  // }, [])
  // useEffect(() => {

  // }, [])

  // const getQueryVariable = () => {
  //   let currentURL = window.location.href;
  //   let retailerId = currentURL?.split("retailerId=")[1];
  //   sessionStorage.setItem('embeddedPartnerId', retailerId)
  //   // console.log('retailerId ', retailerId);
  // }

  // const validateRetailer = async () => {
  //   let id = sessionStorage.getItem('embeddedPartnerId')
  //   await authStore.validateRetailer(id, (response) => {
  //     // console.log(name)
  //     if (response?.result?.status === "Failed") {
  //       notify(response?.result?.statusMessage, "danger")
  //       setRetailerFound(false)
  //       setLoader(false)
  //       // setFound(false)
  //     } else {
  //       setRetailerFound(true)
  //       sessionStorage.setItem("companyName", response.result.retailerName)
  //       // let name = titleCase(response.result.retailerName)
  //       // setRetailerName(name)
  //       sessionStorage.setItem("nextActivityId", response?.result?.nextActivityId)
  //       setLoader(false)
  //       // setFound(false)
  //       // getQueryVariable()  
  //     }
  //   }).catch((error)=>{
  //     notify(`Cannot read properties of undefined (reading 'retailerName')`,'danger')
  //     setLoader(false)
  //   })
  // }

  // useEffect(() => {
  //   getQuery()    
  // },[found])

  // const getQuery = () => {
  //   setLoader(true)

  // }


    const acceptPolicy = async () => {
      let params = `retailerId=${id}&acceptStatus=${true}`
      await applicantStore.acceptPolicy(params, (response) => {
          if (response?.result?.status === "success") {
            notify(response?.result?.statusMessage)
            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/VerifiedDetails`);
          } else {
              notify(response?.response?.data?.exceptionMessage, 'danger')
              return;
          }
      })
  }


  const handleContinue = async () => {
    // if (sessionStorage.getItem('redirectPending')) {
    //   let nextActivityId = sessionStorage.getItem('redirectPending');

    //   if (nextActivityId !== '-1' && nextActivityId !== undefined && nextActivityId !== null) {
    //     if (nextActivityId === 'kyc_details') {
    //       navigate(`/${process.env.REACT_APP_BASE_FOLDER}/VerifiedDetails`)
    //     } else if (nextActivityId === 'business_details') {
    //       navigate(`/${process.env.REACT_APP_BASE_FOLDER}/BusinessDetails`)
    //     } else if (nextActivityId === 'okyc') {
    //       navigate(`/${process.env.REACT_APP_BASE_FOLDER}/OKYC`)
    //     } else if (nextActivityId === 'udyam_verification') {
    //       navigate(`/${process.env.REACT_APP_BASE_FOLDER}/UdyamRegistration`)
    //     } else if (nextActivityId === 'other_information') {
    //       navigate(`/${process.env.REACT_APP_BASE_FOLDER}/BasicInformation`)
    //     } else if (nextActivityId === 'selfie_upload') {
    //       navigate(`/${process.env.REACT_APP_BASE_FOLDER}/UploadSelfie`)
    //     } else if (nextActivityId === 'loan_sanction') {
    //       navigate(`/${process.env.REACT_APP_BASE_FOLDER}/Congratulations`)
    //     } else if (nextActivityId === 'sanction_letter') {
    //       navigate(`/${process.env.REACT_APP_BASE_FOLDER}/Sanction_LetterESign`)
    //     } else if (nextActivityId === 'download_sanction_letter') {
    //       navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ThankYou`)
    //     } else if (nextActivityId === 'nach_mandate') {
    //       navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ManuallyBankDetails`)
    //     } else if (nextActivityId === 'nach_mandate_created') {
    //       navigate(`/${process.env.REACT_APP_BASE_FOLDER}/SignedSuccessfully`)
    //     }
    //   }
    // }
    // if (retailerFound) {
    navigate(`/${process.env.REACT_APP_BASE_FOLDER}/VerifiedDetails`);
    // }
  }

  return (
    <div className="privacyPolicy-container">
      <div className='privacyPolicy_View'>
        <div className="privacyPolicy_body">
          <div className="contant-view">
            <h3 className="heading_view">T & C and Privacy Policy</h3>
            <p className="content_style">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            <p className="content_style">Why do we use it?</p>
            <p className="content_style">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for ‘lorem ipsum’ will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
            {/* <p className="signing_at">Signing at: 09-08-2022, 04:06 PM</p> */}
          </div>
          <div className="btn-main-container">
            <button className="btn-view" onClick={acceptPolicy}>Accept</button>
          </div>
          {/* <SpokesLoader
            show={loader}
            message={"Loading ..."}
          /> */}
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;

