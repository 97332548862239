import React, { useEffect, useState, useRef } from "react";
import "../../index.css";
import "./style.scss";
import Productquality from "../../assets/images/Product-quality.svg";
import credit from "../../assets/images/credit.svg";
import { useNavigate } from "react-router-dom";
import VerifyOTP from "../../components/Modal/VerifyOTP";
import notify from "../../shared/notify";
import { useStores } from "../../stores";
import SpokesLoader from "../../components/Modal/SpokesLoader";
import Navbar from "../../components/Navbar";

function TermsAndCondition() {
  const navigate = useNavigate();
  const { applicantStore } = useStores();

  const retailerId = sessionStorage.getItem("retailerId");

  const [otpModal, setOtpModal] = useState(false);
  const [apiCount,setApicount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [sanctionTabactive, setSanctionTabactive] = useState(true);
  const [mitcTabactive, setMitcTabactive] = useState(false);
  const [keyFactorTabactive, setKeyFactorTabactive] = useState(false);
  const [mobile, setMobile] = useState("");
  const [pan, setPan] = useState("");

  // useEffect(() => {
  //   // if(apiCount <= 31){
  //     const timer = setTimeout(() => {
  //       // verifyEsignStatus();
  //       // alert(apiCount)
  //     }, 10000)
  //     return () => { clearInterval(timer) }
  //   // }
  // }, [])

  const callback =(response)=> {
    console.log(response,"<===========")
    verifyEsignStatus();
  }
  const sign = (url) => {
    const Leegality = window.Leegality;
    var obj = {
          logoUrl: url,
          callback: callback	
        };
    var leegality = new Leegality(obj);
      leegality.init();
      //You will get a signing url by uploading document from backend.
    var signingUrl = url;
    leegality.esign(signingUrl);
  }


  const handleContinue = async () => {
    let params = `retailerId=${retailerId}`
    setLoader(true)
    await applicantStore.eSignOTP(params, (response) => {
      console.log(response);
      setLoader(false)
      setOtpModal(true)
      if (response?.data?.message === "Success") {
        notify(response?.data?.result?.statusMessage)
      } else {
        setLoader(false)
        setOtpModal(false)
        notify("Something went wrong!", 'danger')
        return;
      }
    })
  }

  const handleIntiateEsign = async () => {
    let params = `retailerId=${retailerId}`
    setLoader(true)
    await applicantStore.initiateEsign(params, (response) => {
      setLoader(false)
      if (response?.data?.message === "Success") {
        let url = response?.data?.result?.signUrl
        // window.open(url, '_self');
        sign(url)

      } else {
        setLoader(false)
        notify("Something went wrong!", 'danger')
        return;
      }
    })
  }

  const verifyEsignStatus = async () => {
    let params = `retailerId=${retailerId}`
    setLoader(true)

    await applicantStore.checkEsignStatus(params, (response) => {
      
      if (response?.data?.exceptionOccured == "N") {
        if (response?.data?.message === "Success") {
          if (response?.data?.result?.status == "success") {
            if (response?.data?.result?.action == "Signed") 
            {
              setLoader(false)
              navigate(`/${process.env.REACT_APP_BASE_FOLDER}/enach`)
            } 
            else{
              setLoader(false)
              notify(response?.data?.result?.statusMessage, 'danger')
              navigate(`/${process.env.REACT_APP_BASE_FOLDER}/LoanRejected`);
              return;
            }
          }
          else {
            setLoader(false)
            notify("Esign check status failed", 'danger')
            return;
          }

        } else {
          setLoader(false)
          notify("Esign check status failed", 'danger')
          return;

        }
      } else {
        if (response?.data?.status == 500) {
          notify(response?.data?.exceptionMessage, "danger");
          navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
          setLoader(false)
          return;
        } else{

          setLoader(false)
          notify(response?.data?.exceptionMessage, 'danger')
          return;
        }
      }
    })
  }

  return (
    <>
      <Navbar appid={true} logout={true} />
      <div className="TermsAndCondition-container">
        <div className="content-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <nav>
                  <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <a
                      class={sanctionTabactive ? "nav-item nav-link active show" : "nav-item nav-link"}
                      id="sanction-letter-tab"
                      data-toggle="tab"
                      href="#sanction-letter"
                      role="tab"
                      aria-controls="sanction-letter"
                      aria-selected="true"
                      onClick={() => {
                        setSanctionTabactive(true);
                        setMitcTabactive(false);
                        setKeyFactorTabactive(false);
                      }}
                    >
                      Sanction Letter
                    </a>
                    <a
                      class={mitcTabactive ? "nav-item nav-link active show" : "nav-item nav-link"}
                      id="MITC-tab"
                      data-toggle="tab"
                      href="#MITC"
                      role="tab"
                      aria-controls="MITC"
                      aria-selected="false"
                      onClick={() => {
                        setSanctionTabactive(false);
                        setMitcTabactive(true);
                        setKeyFactorTabactive(false);
                      }}
                    >
                      Most Important Terms And Condition
                    </a>
                    <a
                      class={keyFactorTabactive ? "nav-item nav-link active show" : "nav-item nav-link "}
                      id="key-facts-statement-tab"
                      data-toggle="tab"
                      href="#key-facts-statement"
                      role="tab"
                      aria-controls="key-facts-statement"
                      aria-selected="false"
                      onClick={() => {
                        setSanctionTabactive(false);
                        setMitcTabactive(false);
                        setKeyFactorTabactive(true);
                      }}
                    >
                      Key Facts Statement
                    </a>
                  </div>
                </nav>
                <div class="tab-content" id="nav-tabContent">
                  <div
                    class={sanctionTabactive ? "tab-pane fade show active" : "tab-pane fade"}
                    id="sanction-letter"
                    role="tabpanel"
                    aria-labelledby="sanction-letter-tab"
                  >
                    <div className="contant-view">
                      {/* <h3 className="heading_view">
                    Most Important Terms And Condition
                  </h3> */}
                      <p className="content_style">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the industry’s
                        standard dummy text ever since the 1500s, when an unknown
                        printer took a galley of type and scrambled it to make a
                        type specimen book. It has survived not only five centuries,
                        but also the leap into electronic typesetting, remaining
                        essentially unchanged. It was popularised in the 1960s with
                        the release of Letraset sheets containing Lorem Ipsum
                        passages, and more recently with desktop publishing software
                        like Aldus PageMaker including versions of Lorem Ipsum.
                      </p>
                      <p className="content_style">Why do we use it?</p>
                      <p className="content_style">
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when looking at
                        its layout. The point of using Lorem Ipsum is that it has a
                        more-or-less normal distribution of letters, as opposed to
                        using ‘Content here, content here’, making it look like
                        readable English. Many desktop publishing packages and web
                        page editors now use Lorem Ipsum as their default model
                        text, and a search for ‘lorem ipsum’ will uncover many web
                        sites still in their infancy. Various versions have evolved
                        over the years, sometimes by accident, sometimes on purpose
                        (injected humour and the like).
                      </p>
                      <p className="signing_at">Signing at: 09-08-2022, 04:06 PM</p>

                      <div className="btn-main-container">
                        <button className="btn-view" onClick={() => { setMitcTabactive(true); setSanctionTabactive(false); setKeyFactorTabactive(false) }}>
                          I have read Sanction Letter
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class={mitcTabactive ? "tab-pane fade show active" : "tab-pane fade"}
                    id="MITC"
                    role="tabpanel"
                    aria-labelledby="MITC-tab"
                  >
                    <div className="contant-view">
                      {/* <h3 className="heading_view">
                    Most Important Terms And Condition
                  </h3> */}
                      <p className="content_style">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the industry’s
                        standard dummy text ever since the 1500s, when an unknown
                        printer took a galley of type and scrambled it to make a
                        type specimen book. It has survived not only five centuries,
                        but also the leap into electronic typesetting, remaining
                        essentially unchanged. It was popularised in the 1960s with
                        the release of Letraset sheets containing Lorem Ipsum
                        passages, and more recently with desktop publishing software
                        like Aldus PageMaker including versions of Lorem Ipsum.
                      </p>
                      <p className="content_style">Why do we use it?</p>
                      <p className="content_style">
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when looking at
                        its layout. The point of using Lorem Ipsum is that it has a
                        more-or-less normal distribution of letters, as opposed to
                        using ‘Content here, content here’, making it look like
                        readable English. Many desktop publishing packages and web
                        page editors now use Lorem Ipsum as their default model
                        text, and a search for ‘lorem ipsum’ will uncover many web
                        sites still in their infancy. Various versions have evolved
                        over the years, sometimes by accident, sometimes on purpose
                        (injected humour and the like).
                      </p>
                      <p className="signing_at">Signing at: 09-08-2022, 04:55 PM</p>

                      <div className="btn-main-container">
                        <button className="btn-view" onClick={() => { setMitcTabactive(false); setSanctionTabactive(false); setKeyFactorTabactive(true) }}>
                          I have read Most Important Terms And Condition
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class={keyFactorTabactive ? "tab-pane fade show active" : "tab-pane fade"}
                    id="key-facts-statement"
                    role="tabpanel"
                    aria-labelledby="key-facts-statement-tab"
                  >
                    <div className="contant-view">
                      {/* <h3 className="heading_view">
                    Most Important Terms And Condition
                  </h3> */}
                      <p className="content_style">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the industry’s
                        standard dummy text ever since the 1500s, when an unknown
                        printer took a galley of type and scrambled it to make a
                        type specimen book. It has survived not only five centuries,
                        but also the leap into electronic typesetting, remaining
                        essentially unchanged. It was popularised in the 1960s with
                        the release of Letraset sheets containing Lorem Ipsum
                        passages, and more recently with desktop publishing software
                        like Aldus PageMaker including versions of Lorem Ipsum.
                      </p>
                      <p className="content_style">Why do we use it?</p>
                      <p className="content_style">
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when looking at
                        its layout. The point of using Lorem Ipsum is that it has a
                        more-or-less normal distribution of letters, as opposed to
                        using ‘Content here, content here’, making it look like
                        readable English. Many desktop publishing packages and web
                        page editors now use Lorem Ipsum as their default model
                        text, and a search for ‘lorem ipsum’ will uncover many web
                        sites still in their infancy. Various versions have evolved
                        over the years, sometimes by accident, sometimes on purpose
                        (injected humour and the like).
                      </p>
                      <p className="signing_at">Signing at: 09-08-2022, 04:06 PM</p>

                      <div className="btn-main-container">
                        <button className="btn-view" onClick={handleIntiateEsign}>
                          Initiate E-sign
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <iframe src="https://sandbox.leegality.com/sign/468f4744-8573-4668-8be8-28774d2003c0" width="540" height="450"></iframe>
                {/* <iframe src="https://www.youtube.com/embed/cWDJoK8zw58" width="540" height="450"></iframe> */}
                <VerifyOTP
                  show={otpModal}
                  hide={() => {
                    setOtpModal(false);
                  }}
                  getOtp={"terms"}
                  isLoggedIn={true}
                  navigate={() => {
                    // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ThankYou`)
                    navigate(`/${process.env.REACT_APP_BASE_FOLDER}/enach`);
                  }}
                  // header={"Terms And Condition"}
                  stopLoader={() => setLoader(false)}
                />
                <SpokesLoader show={loader} message={"Loading ..."} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsAndCondition;
