import React, { useEffect, useState, useRef } from "react";
import "../../index.css";
import "./style.scss";
import bankdetails from "../../assets/images/bankdetails.svg";
import paperclip from "../../assets/images/attach_file.png";
import { useStores } from "../../stores";
import convertBase64 from "../../Helper/convertBase64";
import notify from "../../shared/notify";
import { useNavigate } from "react-router-dom";
import SpokesLoader from "../../components/Modal/SpokesLoader";
import Navbar from "../../components/Navbar";

function ManuallyBankDetails() {
  const navigate = useNavigate();
  const { applicantStore } = useStores();
  const chequeInput = useRef();

  const id = sessionStorage.getItem("embeddedPartnerId");

  const [loader, setLoader] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [accountType, setAccountType] = useState("");
  const [ocrData, setOcrData] = useState([]);
  const [chequeName, setChequeName] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [selectedAccountType, setSelectedAccountType] = useState("");

  useEffect(() => {
    // getBankLists()
    window.history.pushState(null, null, window.location.href);

    window.onpopstate = function (event) {

      navigate(`/${process.env.REACT_APP_BASE_FOLDER}/SessionExpired`)

    };
    sessionStorage.removeItem("chequeName");
    sessionStorage.removeItem("UploadChequeResponse");
    sessionStorage.removeItem("manualBankData");
    // getAccountType();
  }, []);

  // const getBankLists = async () => {
  //     await applicantStore.getBankLists((response) => {
  //         console.log("get Bank ", response);
  //         // if (response?.response?.data.status !== 200) {
  //         //     notify(response?.response?.data?.exceptionMessage, 'danger')
  //         //     return;
  //         // } else
  //         if (response?.message === "success") {
  //             setBankList(response?.result)
  //         } else {
  //             notify(response?.exceptionMessage, 'danger')
  //             return;
  //         }
  //     })
  // }

  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    const filesize = file.size / 1000000;
    const fileName = file.name;
    const base64 = await convertBase64(file);
    return { base64, filesize, fileName };
  };

  const handleChequeUpload = async (e) => {
    // setSelfieName('')

    const result = await handleFileRead(e);
    const data = result.base64.split(",")[1];
    const splitfile = data.split(";")[0];
    const fileType = result.base64.split(";")[0];
    const name = result.fileName;
    const type = fileType.split(":")[1];

    if (type === "image/jpeg" || type === "image/png" || type === "image/jpg" || type == "application/pdf") {
      if (result.filesize > 10) {
        notify("Please Upload File Less Than Or Equal To 10 mb", "danger");
        chequeInput.current.value = "";
        return;
      }
      // let formattedDate = formattedCurrentDate();
      // setSelfieBase64(data)
      setChequeName(name);
      sessionStorage.setItem("ChequeName", name);

      let payloadData = {
        embeddedPartnerId: id,
        chequeName: name,
        chequeBase64: data,
      };
      // setIsLoading(true)
      setLoader(true);
      applicantStore.uploadCheque(
        payloadData,
        (response) => {
          console.log("upload res ", response);
          if (response?.data?.message === "Success") {
            setLoader(false);
            notify(response?.data?.message);
            sessionStorage.setItem(
              "UploadChequeResponse",
              JSON.stringify(response?.data?.result)
            );
            setOcrData(response?.data?.result);
            setAccountNumber(response?.data?.result?.accNo);
            setIfscCode(response?.data?.result?.ifsc);
            setAccountHolderName(response?.data?.result?.name[0]);
            // setSelfieName(response?.data?.result?.imagePath)
            // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/VerifyDetails`)
          } else {
            if (response?.data?.status == 500) {
              notify(response?.data?.exceptionMessage, "danger");
              navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
              setLoader(false)
              return;
            } else {

              console.log("upload cheque error ", response);
              setLoader(false);
              chequeInput.current.value = "";
              setChequeName("");
              notify(response?.data?.exceptionMessage, "danger");
              return;
            }
          }
        },
        (error) => {
          setLoader(false);
          console.log("error", error);
        }
      );
    } else {
      notify("Please Upload jpg, jpeg, png Images", "danger");
      return;
    }
  };

  const getAccountType = async () => {
    await applicantStore.getAccountType((response) => {
      console.log("acc type ", response);
      // if (response?.response?.data.status !== 200) {
      //     notify(response?.response?.data?.exceptionMessage, 'danger')
      //     return;
      // } else
      if (response?.message === "success") {
        setAccountType(response?.result);
      } else {
        notify(response?.response?.data?.exceptionMessage, "danger");
        return;
      }
    });
  };

  const handleSubmitButton = async () => {
 
    if (
      accountNumber == "" ||
      ifscCode == "" ||
      accountHolderName == "" ||
      selectedAccountType == ""
    ) {
      notify(
        "All The Above Fields Are Mandatory, Please Enter Missing Details",
        "danger"
      );
      return;
    }
    // console.log(selectedAccountType);
    let accName;
    if (selectedAccountType === "Savings Account") {
      accName = "Saving";
    } else if (selectedAccountType === "Current Account") {
      accName = "Current";
    }
    // if (ocrData.length === 0) {
      let payloadData = {
        accountNumber: accountNumber,
        ifscCode: ifscCode,
        accountHolderName: accountHolderName,
        selectedAccountType: accName,
      };
      // console.log("Payload ", payloadData);

      sessionStorage.setItem("manualBankData", JSON.stringify(payloadData));
      sessionStorage.setItem("chequeBankData", JSON.stringify(accName));
      navigate(`/${process.env.REACT_APP_BASE_FOLDER}/VerifyDetails`);
    // } else if (ocrData.length !== 0) {
    //   let payloadData = {
    //     selectedAccountType: accName,
    //   };
    //   sessionStorage.setItem("chequeBankData", JSON.stringify(payloadData));

    //   // if (ocrData.length !== 0) {
    //   navigate(`/${process.env.REACT_APP_BASE_FOLDER}/VerifyDetails`);
    // }

    // } else if (ocrData.length !== 0) {

    //     if (accountNumber === '') {
    //         notify("Account Number can not be empty", "danger")
    //         return;
    //     }
    //     if (ifscCode === '') {
    //         notify("Ifsc Code can not be empty", "danger")
    //         return;
    //     }
    //     if (accountHolderName === '') {
    //         notify("Account Holder Name can not be empty", "danger")
    //         return;
    //     }
    //     if (selectedAccountType === '') {
    //         notify("Please select Account Type", "danger")
    //         return;
    //     }

    //     let accName;
    //     if (selectedAccountType === "Savings Account") {
    //         accName = 'Saving';
    //     } else if (selectedAccountType === "Current Account") {
    //         accName = 'Current';
    //     }
    //     let payloadData = {
    //         "selectedAccountType": accName
    //     }
    //     sessionStorage.setItem('chequeBankData', JSON.stringify(payloadData))

    //     // if (ocrData.length !== 0) {
    //         navigate(`/${process.env.REACT_APP_BASE_FOLDER}/VerifyDetails`)
    //     // } else {
    //     //     notify("Please upload cheque Image", "danger")
    //     //     return;
    //     // }
    // }
  };

  return (
    <>
      <Navbar logout={true} appid={true} />
      <div className="ManuallyBankDetails-container">
        <div className="content-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="top-header">
                  <h1>Bank Details</h1>
                  <hr className="heading-divider" />
                </div>
                <div className="icon-heading">
                  <img className="header-icon" src={bankdetails} />
                  <p>Please Provide The Details For Your Bank Account</p>
                </div>
                <div className="form-body">
                  <p className="file-upload-label">
                    Please Upload a Copy Of Cheque For{" "}
                    <span className="required_field">*</span>
                  </p>
                  <input
                    type="file"
                    name="bank-file-upload"
                    id="bank-file-upload"
                    style={{ display: "none" }}
                    ref={chequeInput}
                    accept="image/*,.pdf"
                    onChange={(e) => handleChequeUpload(e)}
                  />
                  <label for="bank-file-upload">
                    <div className="bankfile-upload">
                      <div className="bankfile-upload-left">
                        <img className="paperclip-img" src={paperclip} />
                        <p>
                          {chequeName === "" ? "Select Your File" : chequeName}
                        </p>
                      </div>
                      <span>+</span>
                    </div>
                  </label>
                </div>
                <div className="or-view">
                  <p>——————— OR ———————</p>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <p className="file-upload-label">
                      You Can Enter Bank Details Manually
                    </p>
                  </div>
                  {/* <div className="col-md-3">
                {" "}
                <div className="form-input">
                  <label>You can enter bank details manually</label>
                  <select
                            className="form-control"
                            value={bankName}
                            onChange={(e) => {
                                setBankName(e.target.value);
                            }}
                        >
                            <option value="">—Select bank—</option>
                            {bankList?.map((bank) => {
                                return (
                                    <option value={JSON.stringify(bank)}>{bank?.bankName}</option>
                                )
                            })}
                        </select> */}

                  <div className="col-md-3">
                    <div className="form-input">
                      <input
                        className="input-field"
                        type="text"
                        placeholder="Account Number"
                        value={accountNumber}
                        disabled={chequeName != "" ? true : false}
                        onChange={(e) => {
                          setAccountNumber(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (
                            e.target.value.length >= 10 &&
                            e.target.value.length <= 17
                          ) {
                            setAccountNumber(e.target.value);
                          } else {
                            setAccountNumber("");
                            notify(
                              "Account Number Must Be Between 10 To 17 Digits",
                              "danger"
                            );
                            return;
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-input">
                      <input
                        className="input-field"
                        type="text"
                        placeholder="IFSC Code"
                        value={ifscCode}
                        disabled={chequeName != "" ? true : false}
                        onChange={(e) => {
                          setIfscCode(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (e.target.value.length >= 11) {
                            setIfscCode(e.target.value);
                          } else {
                            setIfscCode("");
                            notify("IFSC Code Must Be Of 11 Digits", "danger");
                            return;
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-input">
                      <input
                        className="input-field"
                        type="text"
                        placeholder="Account Holder Name"
                        value={accountHolderName}
                        disabled={chequeName != "" ? true : false}
                        onChange={(e) => {
                          setAccountHolderName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-input">
                    <p className="file-upload-label"> Primary
                        Account Holder Name For Joint Account

                        <span className="required_field">*</span>
                        </p>
                      {/* <span className="alert-content">
                        <span className="required_field">*</span>Put primary
                        account holder name for Joint account
                      </span> */}
                      <select
                        className="form-control"
                        value={selectedAccountType}
                        onChange={(e) => {
                          setSelectedAccountType(e.target.value);
                        }}
                      >
                        <option value="">—Account Type—</option>
                        <option value="Savings Account">Savings Account</option>
                        <option value="Current Account">Current Account</option>
                        {/* {accountType.length > 0 && accountType?.map((type) => {
                                if (type.accId === '1' || type.accId === '3') {
                                    return (
                                        <option value={type?.accName}>{type?.accName}</option>
                                    )
                                }
                            })} */}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="btn-main-container">
                      <div className="btn-container">
                        <button
                          className="btn-view"
                          onClick={handleSubmitButton}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                  <SpokesLoader show={loader} message={"Loading ..."} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManuallyBankDetails;
