import React, { useEffect } from 'react'

import '../../index.css';
import './style.scss';

import Navbar from '../../components/Navbar'

import rejected from '../../assets/images/reject.webp';
import crossimg from '../../assets/images/cancel.png';
import { useNavigate } from 'react-router-dom';


function LoanRejected() {
  const navigate = useNavigate();


  useEffect(() => {
    window.history.pushState(null, null, window.location.href); 
    window.history.pushState(null, null, window.location.href); 
    window.onpopstate = function (event) {
      navigate(`/${process.env.REACT_APP_BASE_FOLDER}/SessionExpired`)
    };
}, [])


  return (
    <>
      <Navbar />
      <div className="LoanReject-container">

        {/* <div className="close-icon-top">
                    <img
                        className="crossimg-img"
                        src={crossimg}
                        onClick={() => {navigate(-1)}}
                        // onClick={() => {
                        //     // if (back) {
                        //     //     navigate(`/${process.env.REACT_APP_BASE_FOLDER}/OKYC`)
                        //     // } else {
                        //     //     navigate(`/${process.env.REACT_APP_BASE_FOLDER}/${id}`)
                        //     // }
                        // }}
                    />
                </div> */}
        <div className="content-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 text-center">
                <img
                  className="reject-img"
                  src={rejected}
                />
                <h1 className='rj-heading'>Oops, We Are Sorry!</h1>
                <p className='rj-text'>Well, We Couldn’t Find Any Offer For You At This Time</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoanRejected