import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import AccessModal from "../../components/Modal/AccessModal";

function Enach() {
  const navigate = useNavigate();
  const { businessDetailsStore, applicantStore } = useStores();
  const id = sessionStorage.getItem("embeddedPartnerId");

  const [showTitle, setShowTitle] = useState(true);
  const [accesModal, setAccesModal] = useState(false);


  useEffect(() => {
    // window.history.pushState(null, null, window.location.href);
    // window.onpopstate = function (event) {
    //   navigate(`/${process.env.REACT_APP_BASE_FOLDER}/SessionExpired`)

    // }; 
  }, [])


  useEffect(() => {
    let currentURL = window.location.href;
    if (currentURL.indexOf("response=txn_id=") > -1) {
      setShowTitle(false);
      let reponseURL = currentURL?.split("?")[1]?.split("&");
      let transactionId = reponseURL[0]?.split("response=txn_id=")[1];
      let UrmNumber = reponseURL[4]?.split("umrn_no=")[1];
      sessionStorage.setItem("UrmnNumber", UrmNumber);

      let params = `transactionId=${transactionId}`;
      checkTransactionComplete(params);
    } else {
      setAccesModal(true)
      setTimeout(() => {
        getEnachRegistration();
      }, 2000);
    }
  }, []);

  const checkTransactionComplete = (params) => {
    businessDetailsStore.transactionComplete(params, (res) => {
      if (res?.data?.exceptionOccured == "N") {
        if (res?.data?.result?.status == "success") {
          handleGetNachStatus();
        } else {
          notify(res?.data?.exceptionMessage, "danger");
          // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`)
        }
      }
    });
  };

  const handleGetNachStatus = async () => {
    let id = sessionStorage.getItem("embeddedPartnerId");

    let params = `retailerId=${id}`;

    await applicantStore.getNachMandateStatus(params, (response) => {
      console.log("getNachMandateStatus ", response);
      if (response?.exceptionOccured == "N") {
        console.log("getNachMandateStatus Success ", response);
        if (response?.result?.informationComplete) {
          navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ThankYou`);
        } else {
          navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ThankYou2`);
        }
      } else {
        if (response?.data?.status == 500) {
          notify(response?.data?.exceptionMessage, "danger");
          navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
          return;
        }else {

          console.log("getNachMandateStatus else ", response);
          notify(response?.exceptionMessage, "danger");
          return;
        }
      }
    });
  };

  const getEnachRegistration = () => {
    let params = `retailerId=${id}`;
    businessDetailsStore.enachRegistration(params, (res) => {
      if (res?.data?.exceptionOccured == "N") {
        let url = res?.data?.result?.data?.action_url;
        setShowTitle(false);
        window.open(url, "_self");
      } else {
        if (res?.data?.status == 500) {
          notify(res?.data?.exceptionMessage, "danger");
          navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
          return;
        }else{

          notify(res?.data?.exceptionMessage, "danger");
        }
      }
    });
  };

  return (
    <>
      <Navbar />
      <div className="waitingLoader-container">
        <div className="content-container">
          <div className="container-fluid text-center">
            <div className="row">
              <div className="col-md-12">
                <div className="loader-box">
                  <div className="waiting-loader"></div>
                  {showTitle &&
                    <p>You Shall Now Be Redirected For ENach Registration</p>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <AccessModal
          show={accesModal}
          message={"Allow access to Mandate the application"}
          hide={() => {
            setAccesModal(false);
          }}
        />
      </div>
    </>
  );
}

export default Enach;
