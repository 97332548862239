import React, { useEffect, useState } from 'react'
import Button from '../../components/UI/Button/Button';
import ekycimg from '../../assets/images/aadharkyc.png';
// import ekycimg from '../../assets/images/ekycimg/ekycimg.webp';
import Navbar from '../../components/Navbar';
import './style.scss';
import AddressModel from '../../components/Modal/AddressModel';
import { useNavigate } from 'react-router-dom';
import { useStores } from '../../stores';
import notify from '../../shared/notify';
import SpokesLoader from '../../components/Modal/SpokesLoader';

function AadhaarVerification() {
    const navigate = useNavigate();
    const { applicantStore,businessDetailsStore } = useStores();

    const id = sessionStorage.getItem('embeddedPartnerId')
    let retailerId = sessionStorage.getItem('retailerId');

    const [aadharNumber, setAadharNumber] = useState('');
    const [nameOnAadhar, setNameOnAadhar] = useState('');
    const [date, setDate] = useState('');
    const [address, setAddress] = useState('');
    const [checked, setchecked] = useState('');
    const [loader, setLoader] = useState(false);
    const [question, setQuestion] = useState('');

    useEffect(() => {
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function (event) {
            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/SessionExpired`)
        };
        getAadharData();
    }, [])


    const getAadharData = () => {
        
        let params = `retailerId=${retailerId}`
        setLoader(true)
        applicantStore.getAadharVerificationData(params, (response) => {
            let data = response?.result;
            if (response?.exceptionOccured == "N") {
                setAadharNumber(data?.aadhaarNumber)
                setNameOnAadhar(data?.name)
                setDate(data?.dateOfBirth)
                setAddress(data?.address?.combinedAddress)
                setLoader(false);
            } else {
                notify(response?.exceptionMessage, 'danger')
                setLoader(false);
            }
        })
    }


    const handleSubmit = (e) => {
        e.preventDefault();
    
        if (question === "") {
            notify("Please Select Yes Or No Before You Proceed.", "danger");
            return;
        }

        let params = `retailerId=${retailerId}`

        let Actualaddress = address.split(',');
        let pincode = Actualaddress[Actualaddress.length - 1]
        let add = Actualaddress.slice(0,-1).toString();

        let payload = {
            address:add,
            pincode:pincode.trim()
        }
      
        console.log(payload)
        businessDetailsStore.insertVerifiedResidenceAddress(params,payload,(response)=>{
            if(response?.data?.exceptionOccured == 'N'){
                notify(response?.data?.result?.statusMessage,'success');
                navigate(`/${process.env.REACT_APP_BASE_FOLDER}/BasicInformation`)
            }else{
                if (response?.data?.status == 500) {
                    notify(response?.data?.exceptionMessage, "danger");
                    navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
                    return;
                }else{

                    notify(response?.data?.exceptionMessage,'danger');
                }
            }
            console.log(response.data);
        })
    }
    return (
        <>
            <Navbar logout={true} appid={true} />
            <div className="aadharverification-container">
            <div className="content-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
              <div className="top-header-title">
                  <h2>Aadhaar Details Verification</h2>
                  <hr className="heading-divider" />
                </div>
                <div className="top-header-content">
                  <img className="header-img col-icon" src={ekycimg} />
                  <p>Please Provide Aadhaar Details</p>
                </div>
                        <div className="row">
                            <div className='col-md-4'>
                                <div className="address-form-group">
                                    <label>Aadhaar Number</label><br />
                                    <input type='text' name='address' id='address' placeholder='Aadhaar Number' disabled={aadharNumber != '' ? true : false} value={aadharNumber} onChange={(e) => { setAadharNumber(e.target.value) }} />
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className="address-form-group">
                                    <label>Name As Per Aadhaar</label><br />
                                    <input type='text' name='address' id='address' placeholder='Name as per Aadhaar'disabled={nameOnAadhar != '' ? true : false} value={nameOnAadhar} />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className="address-form-group">
                                    <label>Date Of Birth</label><br />
                                    <input type='date' name='date' id='date' placeholder='Date of Birth' disabled={date != '' ? true : false} value={date} />
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className="address-form-group">
                                    <label>Address</label><br />
                                    <p className='text-fatch'>{address}</p>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className="address-form-group">
                                    <label>Is This Your Current Address?</label><br />
                                </div>
                                <div className="radio-container">
                                    <input
                                        type="radio"
                                        id="address"
                                        name="entity"
                                        value={question}
                                        onChange={() => { setQuestion('Yes'); }}
                                    />
                                    <label for="address">YES</label>
                                </div>
                                <div className="radio-container">
                                    <input
                                        type="radio"
                                        id="address"
                                        name="entity"
                                        value={question}
                                        onChange={() => { setQuestion('No'); }}
                                    />
                                    <label for="address">NO</label>
                                </div>

                            </div>
                            <div className='col-md-12 aadhaar-otp-btn'>
                                <Button
                                    type="button"
                                    buttonStyle="btn--secondary--solid"
                                    buttonSize="btn-medium"
                                    onClick={(e) => {
                                        // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/BasicInformation`) 
                                        if (question == 'Yes' || question == '') {
                                            handleSubmit(e)
                                            // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/BasicInformation`) 
                                        } else if (question == 'No') {
                                            setchecked(!checked);
                                        }
                                    }}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                    <AddressModel show={checked} onSubmit={() => { setchecked(!checked) }} title={"Current Residential Address"} />
                    {<SpokesLoader
                        show={loader}
                        message={"Loading ..."}
                    />}
                </div>
            </div>
            </div>
            </div>
           
    </>
  );
}

export default AadhaarVerification;
