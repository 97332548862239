import React, { useEffect, useState, useRef } from "react";
import "../../index.css";
import "./style.scss";
import businessIcon from "../../assets/images/business.webp";
import Navbar from "../../components/Navbar";
import CustomInput from "../../components/UI/Form-input/CustomInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Clip from "../../assets/images/attach_file.png";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import convertBase64 from "../../Helper/convertBase64";
import RejectionMessage from "../RejectionMessage";
import SpokesLoader from "../../components/Modal/SpokesLoader";
import OfferModel from "../../components/Modal/OfferModel";
import NotAOfferModal from "../../components/Modal/NotAOfferModal";

function BusinessDetails() {
    const navigate = useNavigate();
    const drugInput = useRef();
    const { businessDetailsStore, authStore,applicantStore} = useStores();
    // const { authStore, applicantStore } = useStores();

  let retailerId = sessionStorage.getItem("retailerId");
  const nextActivityId = sessionStorage.getItem("nextActivityId");

  const [entityType, setEntityType] = useState("");
  const [firmName, setFarmName] = useState("");
  const [dateOfIncorporation, setDateOfIncorporation] = useState("");
  const [businessPinCode, setBusinessPinCode] = useState("");

  const [loader, setLoader] = useState(false);

  const [showNoOfferModel, setShowNoOfferModel] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);

  useEffect(() => {
      window.history.pushState(null, null, window.location.href); 
      window.onpopstate = function (event) {
        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/SessionExpired`)
      };  
}, [])
  

  // const validateRetailer = async () => {
  //     let id = sessionStorage.getItem('embeddedPartnerId')
  //     await authStore.validateRetailer(retailerId, (response) => {
  //         if (response?.result?.status === "Failed") {
  //             notify(response?.result?.statusMessage, "danger")
  //             setLoader(false)
  //         } else {
  //             setLoader(false);
  //         }
  //     }).catch((error) => {
  //         notify(`Cannot read properties of undefined (reading 'retailerName')`, 'danger')
  //         setLoader(false)
  //     })
  // }

  // const validateRetailer = async () => {
  //     let id = sessionStorage.getItem('embeddedPartnerId')
  //     await authStore.validateRetailer(retailerId, (response) => {
  //         if (response?.result?.status === "Failed") {
  //             notify(response?.result?.statusMessage, "danger")
  //             setLoader(false)
  //         } else {
  //             setLoader(false);
  //         }
  //     }).catch((error) => {
  //         notify(`Cannot read properties of undefined (reading 'retailerName')`, 'danger')
  //         setLoader(false)
  //     })
  // }
  const handleSubmit = (e) => {
    // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/PreApprovedLoan`)
    e.preventDefault();
    if (entityType === "") {
        notify("Please Select Your Business Entity", "danger");
        return;
    }
    if(firmName === "" || dateOfIncorporation === "" || businessPinCode === ""){
        notify('All The Above Fields Are Mandatory, Please Enter Missing Details','danger');
        return
    }
    // if (firmName === "") {
    //     notify("Please enter your firm name ", "danger");
    //     return;
    // }
    // if (dateOfIncorporation === "") {
    //     notify("Please choose date of incorporation", "danger");
    //     return;
    // }
    if (businessPinCode.length < 6) {
        notify("Please Enter a Valid Pincode", "danger");
        return;
    }

    let params = `retailerId=${retailerId}`
    let formattedDate = dateOfIncorporation?.split('-')?.reverse()?.join('-')
    let payload = {
        typeOfBusinessEntity: entityType,
        businessPincode: businessPinCode,
        firmName: firmName,
        dateOfIncorporation: formattedDate,
    }
    setLoader(true);

    businessDetailsStore.retailerBusinessDetails(params, payload, (response) => {

        if (response?.data?.exceptionOccured == 'N') {
                // setLoader(false)
                sessionStorage.setItem('loanRequestId',response?.data?.result?.loanRequestId);
                notify(response?.data?.result?.statusMessage, 'success')
                getLoanData();
                // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/PreApprovedLoan`)

        } else {
          if (response?.data?.status == 500) {
            notify(response?.data?.exceptionMessage, "danger");
            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
            setLoader(false)
            return;
        }else{

          setLoader(false);
          notify(response?.data?.exceptionMessage,'danger');
        }
        }
    })
 }

 
     const getLoanData = () => {
        let params = `retailerId=${retailerId}`
        setLoader(true)
        applicantStore.checkEligibilityAndGetLimit(params, (response) => {
            if (response?.exceptionOccured == 'N') {
                sessionStorage.setItem(
                    "nextActivityId",
                    response?.result?.nextActivityId
                );
                navigate(`/${process.env.REACT_APP_BASE_FOLDER}/PreApprovedLoan`)
                setLoader(false)

                // notify(response?.result?.statusMessage, 'success');
            } else {
                // notify(response?.exceptionMessage,'danger');
                navigate(`/${process.env.REACT_APP_BASE_FOLDER}/NoOfferFound`);
                setLoader(false)

            }
        })
    }



  return (
    <>
      <Navbar firmName={true} />
      <div className="BusinessDetails-container">
        <div className="content-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="top-header-title">
                  <h2>Business Details</h2>
                  <hr className="heading-divider" />
                </div>
                <div className="top-header-content">
                  <img className="header-img" src={businessIcon} />
                  <p>
                  Enter Your Firm Details
                  </p>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="business-entity">
                      <h3>
                        Type Of Business Entity{" "}
                        <span className="required_field">*</span>
                      </h3>
                      <div className="radio-container">
                        <input
                          type="radio"
                          id="typeOfBusinessProp"
                          name="entity"
                          value={entityType}
                          onChange={(e) => {
                            setEntityType("Proprietorship");
                          }}
                        />
                        <label for="typeOfBusinessProp">Proprietorship</label>
                      </div>
                      <div className="radio-container">
                        <input
                          type="radio"
                          id="typeOfBusinessParnter"
                          name="entity"
                          checked={checked1}
                          value={entityType}
                          onChange={(e) => {
                            setEntityType("Partner");
                            setShowNoOfferModel(true);
                            setChecked1(!checked1);
                            // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/CibilFetchReject`, {
                            //     state: {
                            //         message: `We Are Not Able To Serve You At The Moment`
                            //     }
                            //     //         message: `Dear Customer, we appreciate your interest in our product.
                            //     // However, we express our inability to serve you at the moment as your profile does not match our credit parameters
                            //     // `}
                            // })
                          }}
                        />
                        <label for="typeOfBusinessParnter">Partnership</label>
                      </div>
                      <div className="radio-container">
                        <input
                          type="radio"
                          id="typeOfBusinessCompany"
                          name="entity"
                          checked={checked2}
                          value={entityType}
                          onChange={(e) => {
                            setEntityType("Private limited");
                            setShowNoOfferModel(true);
                            setChecked2(!checked2);
                            // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/CibilFetchReject`, {
                            //     state: {
                            //         message: `We Are Not Able To Serve You At The Moment`
                            //     }
                            //     //         message: `Dear Customer, we appreciate your interest in our product.
                            //     // However, we express our inability to serve you at the moment as your profile does not match our credit parameters
                            //     // `}
                            // })
                          }}
                        />
                        <label for="typeOfBusinessCompany">
                          Private limited
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {entityType === "Proprietorship" && (
                  <div className="row">
                    <div className="col-md-4">
                      <CustomInput
                        type="text"
                        label="Please Enter Your Firm Name"
                        placeholder="Firm Name"
                        value={firmName}
                        onChange={(e) => {
                          setFarmName(e.target.value);
                        }}
                        mandatory={true}
                      />
                    </div>
                    <div className="col-md-4">
                      <CustomInput
                        type="date"
                        label="Date Of Incorporation"
                        value={dateOfIncorporation}
                        onChange={(e) => {
                          setDateOfIncorporation(e.target.value);
                        }}
                        maxDate={true}
                        mandatory={true}
                      />
                    </div>
                    <div className="col-md-4">
                      <CustomInput
                        type="text"
                        label="Business Pin Code"
                        placeholder="Business Pin Code"
                        value={businessPinCode}
                        maxLength={6}
                        onChange={(e) => {
                          setBusinessPinCode(e.target.value.replace(/\s/g, ''));
                        }}
                        mandatory={true}
                      />
                    </div>
                  </div>
                )}

                <div className="submit-btn">
                  <button
                    className="btn-view"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    Submit
                  </button>
                </div>

                <SpokesLoader show={loader} message={"Loading ..."} />
              </div>
            </div>
          </div>
        </div>
        <NotAOfferModal
          show={showNoOfferModel}
          onClick={() => {
            setShowNoOfferModel(false);
            setEntityType("");
            setChecked1(false);
            setChecked2(false);
          }}
        />
      </div>
    </>
  );
}

export default BusinessDetails;
