import React, { useEffect, useState } from "react";

import "../../index.css";
import "./style.scss";

import Navbar from "../../components/Navbar";
import SpokesLoader from "../../components/Modal/SpokesLoader";
import CustomInput from "../../components/UI/Form-input/CustomInput";

import basicinfo from "../../assets/images/basicinfo.svg";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../stores";
import notify from "../../shared/notify";

function BasicBusinessInformation() {
  const navigate = useNavigate();
  const { businessDetailsStore } = useStores();
  const [showloader, setShowLoader] = useState(false);

  let retailerId = sessionStorage.getItem("retailerId");
  const [address, setAddress] = useState("");
  const [currentaddress, setCurrentAddress] = useState("");
  const [addressStatus, setAddressStatus] = useState("");
  const [businessNature, setBusinessNature] = useState("");
  const [businessType, setBusinessType] = useState("");

  useEffect(() => {
    // window.history.pushState(null, null, window.location.href);
    // window.onpopstate = function (event) {
    //   navigate(`/${process.env.REACT_APP_BASE_FOLDER}/SessionExpired`)

    // };
    getcurrentAddress();
  }, []);

  const getcurrentAddress = () => {
    let params = `retailerId=${retailerId}`;
    businessDetailsStore.getCurrentBusinessAddress(params, (res) => {
      console.log(res);
      if (res?.exceptionOccured == "N") {
        setAddress(res?.result?.currentAddress);
      } else {
        notify(res?.exceptionMessage, "danger");
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      address === "" ||
      currentaddress === "" ||
      addressStatus === "" ||
      businessNature === "" 
      // ||
      // businessType === ""
    ) {
      notify(
        "All The Above Fields Are Mandatory, Please Enter Missing Details",
        "danger"
      );
      return;
    }

    let params = `retailerId=${retailerId}`;

    let payload = {
      currentBusinessAddress: address,
      // typeOfBusiness: businessType,
      natureOfBusiness: businessNature,
      statusOfCurrentBusinessAddress: addressStatus,
      numberOfMonthsInCurrentBusinessAddress: currentaddress,
    };
    businessDetailsStore.basicDetails(params, payload, (res) => {
      if (res?.data?.exceptionOccured == "N") {
        notify(res?.data?.result?.statusMessage);
        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/OKYC`);
      } else {
        if (res?.data?.status == 500) {
          notify(res?.data?.exceptionMessage, "danger");
          navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
          return;
      }else{
        notify(res?.data?.exceptionMessage, "danger");
      }
      }
    });
  };

  return (
    <>
      <Navbar logout={true} appid={true} />
      <div className="basicBusinessInformation-container">
        <div className="content-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="top-header-title">
                  <h2>Basic Information Of Business</h2>
                  <hr className="heading-divider" />
                </div>
                <div className="top-header-content">
                  <img className="header-img" src={basicinfo} />
                  <p>Please Tell Us More About Your Business</p>
                </div>

                <div className="Login-form">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="business-entity mb-3">
                        <h3>
                          Current Address{" "}
                          <span className="required_field">*</span>
                        </h3>
                        <div className="radio-container">{address}</div>
                      </div>
                      </div>
                      <div className="col-md-4">
                      <CustomInput
                        type="tel"
                        label="No. Of Months In Current Business Address"
                        placeholder="No. Of Months In Current Business Address"
                        style={"label_title"}
                        value={currentaddress}
                        pattern="[0-9]*"
                        maxLength={3}
                        onChange={(e) => {
                          e.target.validity.valid &&
                            setCurrentAddress(e.target.value);
                        }}
                        mandatory={true}
                      />
                    </div>
                    <div className="col-md-4">
                      <div className="form-input">
                        <label>
                          Status Of Current Business Address{" "}
                          <span className="required_field">*</span>
                        </label>
                        <select
                          className="form-control"
                          value={addressStatus}
                          onChange={(e) => {
                            setAddressStatus(e.target.value);
                          }}
                        >
                          <option value="">Select Status</option>
                          <option value="Owned">Owned</option>
                          <option value="Rented">Rented</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-input">
                        <label>
                          Nature Of Business{" "}
                          <span className="required_field">*</span>
                        </label>
                        <select
                          className="form-control"
                          value={businessNature}
                          onChange={(e) => {
                            setBusinessNature(e.target.value);
                          }}
                        >
                          <option value="">Select Nature Of Business</option>
                          {/* <option value="Manufacturing">Manufacturing</option> */}
                          <option value="Trading">Trading</option>
                          <option value="Services">Services</option>
                        </select>
                      </div>
                    </div>
                    {/* <div className="col-md-4">
                      <div className="form-input">
                        <label>
                          Type Of Business{" "}
                          <span className="required_field">*</span>
                        </label>
                        <select
                          className="form-control"
                          value={businessType}
                          onChange={(e) => {
                            setBusinessType(e.target.value);
                          }}
                        >
                          <option value="">Select type of Business</option>
                          <option value="Self-owned">Self-owned</option>
                          <option value="Rented">Rented</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                    </div> */}
                  </div>
                  <div className="submit-btn">
                    <button
                      className="btn-view"
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      Proceed
                    </button>
                  </div>
                  <SpokesLoader show={showloader} message={"Loading ..."} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BasicBusinessInformation;
