import React, { useEffect, useState, useRef } from "react";
import '../../index.css';
import './style.scss';
import ekycimg from '../../assets/images/ekycimg/ekycimg.webp';
import Navbar from "../../components/Navbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { propTypes } from "react-bootstrap/esm/Image";

function RejectionMessage(props) {
    const state = useLocation();
    const [message, setMessage]=useState('')
    
    useEffect(()=>{
        if(state?.state !== null){
            setMessage(state?.state?.message);
        }
    },[])
    const navigate = useNavigate();
    return (
        <div className="rejection-container">
            <div className='eKYC_View'>
                <div className="eKYC_body">
                    <div className="top-header">
                        <div className="top-header-icon">
                            <img
                                className="Business-img"
                                src={ekycimg}
                            />
                        </div>                       
                    </div>
                    <div className="business-entity">
                        <h3>Oops, We are sorry!</h3>
                        <p>{message}</p>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default RejectionMessage;

