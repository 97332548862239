import React from 'react'
import authStore from "./authStore";
import businessDetailsStore from './businessDetailsStore';
import applicantStore from './applicantStore'


class RootStore {
  constructor() {
    this.authStore = new authStore(this);
    this.applicantStore = new applicantStore(this);
    this.businessDetailsStore = new businessDetailsStore(this);
  }
}


const StoresContext = React.createContext(new RootStore());

export const useStores = () => React.useContext(StoresContext);