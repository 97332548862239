import React, { useEffect, useState, useRef } from "react";
import '../../index.css';
import './style.scss';
import rejected from '../../assets/images/reject1.png';
import Navbar from "../../components/Navbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { propTypes } from "react-bootstrap/esm/Image";
import crossimg from '../../assets/images/cancel.png';

function TryAgain() {

    const state = useLocation();
    const navigate = useNavigate();

    const [message, setMessage] = useState('')
    const [id, setid] = useState('')
    const [back, setBack] = useState('')

    useEffect(() => {
        if (state?.state !== null) {
            setMessage(state?.state?.message);
            setid(state?.state?.retailerId)
            setBack(state?.state?.back)
        }
    }, [])

    return (
        <>
            <Navbar />
            <div className="CibilFetchReject-container">
                <div className="content-container">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <div className="close-icon">
                                    {/* <img
                                        className="crossimg-img"
                                        src={crossimg}
                                        onClick={() => { navigate(-1) }}
                                    // onClick={() => {
                                    //     // if (back) {
                                    //     //     navigate(`/${process.env.REACT_APP_BASE_FOLDER}/OKYC`)
                                    //     // } else {
                                    //     //     navigate(`/${process.env.REACT_APP_BASE_FOLDER}/${id}`)
                                    //     // }
                                    // }}
                                    /> */}
                                </div>
                                <img
                                    className="reject-img"
                                    src={rejected}
                                />
                                <h1 className="cfr-heading">Your E-sign Is Not Verified, Kindly Try Again!</h1>
                                <button className="re_initiate_btn" onClick={()=>{navigate(-1) }}>Re-Initiate</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TryAgain