import React, { useEffect, useState, useRef } from "react";
import '../../index.css';
import './style.scss';
import completed from '../../assets/images/completed.svg';
import { useNavigate } from "react-router-dom";

function ProcessCompleted() {

    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/UdyamRegistration`);
        }, 2000);
    }, [])
    return (
        <div className="ProcessCompleted-container">
            <div className='ProcessCompleted_View'>
                <div className="ProcessCompleted_body">
                    <img
                        className="reject-img"
                        src={completed}
                    />
                    <h1>KYC Process Completed</h1>
                    <p>Do not close the window. It will close automatically</p>
                </div>
            </div>
        </div>
    );
}

export default ProcessCompleted;

