import React, { useEffect, useState, useRef } from "react";
import "../../index.css";
import "./style.scss";
import Productquality from "../../assets/images/Product-quality.svg";
import credit from "../../assets/images/credit.svg";
import tenureImg from "../../assets/images/tenure.svg";
import entitykyc from "../../assets/images/entitykyc.svg";
import interest from "../../assets/images/interest.svg";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import SpokesLoader from "../../components/Modal/SpokesLoader";
import Navbar from "../../components/Navbar";

function Congratulations() {
  const navigate = useNavigate();
  const { applicantStore, businessDetailsStore } = useStores();

  const id = sessionStorage.getItem("embeddedPartnerId");

  const [showloader, setShowLoader] = useState(true);
  const [amount, setAmount] = useState("");
  const [tenure, setTenure] = useState("");
  const [transTenure, setTranseTenure] = useState("");
  const [roi, setRoi] = useState("");

  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function (event) {
      navigate(`/${process.env.REACT_APP_BASE_FOLDER}/SessionExpired`)
    };
  }, [])


  useEffect(() => {
    let params = `retailerId=${id}`;
    applicantStore.getLimitData(params, (response) => {
      console.log(response);
      if (response?.data?.message === "Success") {
        // notify(response?.data?.result?.statusMessage)
        let loanAmount = Number(response?.data?.result?.limit).toFixed(2);
        let amt = Number(loanAmount)
        formatNumber(amt);
        setTenure(response?.data?.result?.tenure);
        setTranseTenure(response?.data?.result?.transTenure);
        setRoi(response?.data?.result?.roi);
        setShowLoader(false);
      } else {
        // console.log(response.response.data)
        notify(response?.data?.exceptionMessage, "danger");
        setShowLoader(false);
        return;
      }
    });
  }, []);

  const handleContinue = () => {
    let params = `retailerId=${id}`;

    businessDetailsStore.acceptOffer(params, (res) => {
      if (res?.exceptionOccured == "N") {
        notify(res?.result?.statusMessage, "success");
        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ManuallyBankDetails`)
      } else {
        if (res?.status == 500) {
          notify(res?.exceptionMessage, "danger");
          navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
          setShowLoader(false)
          return;
      }else{

        notify(res?.exceptionMessage, "danger");
      }
      }
    })
    // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ManuallyBankDetails`)
  }

  function formatNumber(num) {
    let input = num;
    var n1, n2;
    num = num + "" || "";
    // works for integer and floating as well
    n1 = num.split(".");
    n2 = n1[1] || null;
    n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
    num = n2 ? n1 + "." + n2 : n1;
    console.log("Input:", input);
    console.log("Output:", num);
    setAmount(num);
    return num;
  }

  function formatNumber(num) {
    let input = num;
    var n1, n2;
    num = num + "" || "";
    // works for integer and floating as well
    n1 = num.split(".");
    n2 = n1[1] || null;
    n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
    num = n2 ? n1 + "." + n2 : n1;
    console.log("Input:", input);
    console.log("Output:", num);
    setAmount(num);
    return num;
  }

  return (
    <>
      <Navbar logout={true} appid={true} />
      <div className="Congratulations-container">
        <div className="content-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5">
                <div className="Productquality-continer">
                  <img className="Productquality-img" src={Productquality} />
                </div>
              </div>
              <div className="col-md-7">
                <div className="contant-view">
                  <h3>Congratulations!</h3>
                  <p>
                    You are eligible for
                    <b> INR {amount} </b>
                    {/* <b>INR 5,000,00</b> */}
                    sanction limit. To avail please accept the terms and conditions
                  </p>

                  <div className="contain-fluid info_container">
                    <div className="row">
                      <div className="col-md-3 col-sm-3 col-6">
                        <img className="info-img" src={credit} />
                        <h4>{amount}</h4>
                        {/* <h4>5,000,00</h4> */}
                        <p className="counter-text">Allocated Credit</p>
                      </div>
                      <div className="col-md-3 col-sm-3 col-6">
                        <img className="info-img" src={tenureImg} />
                        <h4>{tenure} Year </h4>
                        {/* <h4>7 years</h4> */}
                        <p className="counter-text">Loan Tenure</p>
                      </div>
                      <div className="col-md-3 col-sm-3 col-6">
                        <img className="info-img" src={interest} />
                        <h4>{roi}%</h4>
                        {/* <h4>9.0%</h4> */}
                        <p className="counter-text">Rate of Interest</p>
                      </div>
                      <div className="col-md-3 col-sm-3 col-6">
                        <img className="info-img" src={entitykyc} />
                        <h4>{transTenure} Days</h4>
                        {/* <h4>9.0%</h4> */}
                        <p className="counter-text">Tranche Tenure</p>
                      </div>
                    </div>
                  </div>
                  <div className="btn-main-container">
                    <button className="btn-view" onClick={handleContinue}>
                      I Accept
                    </button>
                  </div>
                  <SpokesLoader show={showloader} message={"Please Wait ..."} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Congratulations;
