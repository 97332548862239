import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from "../../UI/Button/Button";
import './style.scss';
import ReactLoading from 'react-loading';

function SpokesLoader(props) {
    return (
        <Modal
            {...props}
            centered
        >
            <Modal.Body>
                <div className="SpokesLoader-container">
                    <ReactLoading type={'spokes'} color={'#FFFF'} height={90} width={80} />
                    <p>{props?.message}</p>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default SpokesLoader;
