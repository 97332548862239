import React, { useEffect, useState, useRef } from "react";
import '../../index.css';
import './style.scss';
import rejected from '../../assets/images/reject1.png';
import Navbar from "../../components/Navbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { propTypes } from "react-bootstrap/esm/Image";
import crossimg from '../../assets/images/cancel.png';

function CibilFetchReject() {

    const state = useLocation();
    const navigate = useNavigate();

    const [message, setMessage] = useState('')
    const [id, setid] = useState('')
    const [back, setBack] = useState('')

    useEffect(() => {
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function (event) {
            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/SessionExpired`)

        };
        if (state?.state !== null) {
            setMessage(state?.state?.message);
            setid(state?.state?.retailerId)
            setBack(state?.state?.back)
        }
    }, [])

    const removeSeesionStorage = () =>{
        sessionStorage.removeItem("login");
        sessionStorage.removeItem("loginData");
        sessionStorage.removeItem("retailerName");
        sessionStorage.removeItem("nextActivityId");
        sessionStorage.removeItem("embeddedPartnerId");
        sessionStorage.removeItem("retailerId");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("loanRequestId");
        sessionStorage.removeItem("UrmnNumber");
      }

    return (
        <>
            <Navbar />
            <div className="CibilFetchReject-container">
                <div className="content-container">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <div className="close-icon">
                                    <img
                                        className="crossimg-img"
                                        src={crossimg}
                                        onClick={() => {  let programId = sessionStorage.getItem("programName");
                                        removeSeesionStorage();
                                        localStorage.clear();
                                        window.location.href = `/`; }}
                                    // onClick={() => {
                                    //     // if (back) {
                                    //     //     navigate(`/${process.env.REACT_APP_BASE_FOLDER}/OKYC`)
                                    //     // } else {
                                    //     //     navigate(`/${process.env.REACT_APP_BASE_FOLDER}/${id}`)
                                    //     // }
                                    // }}
                                    />
                                </div>
                                <img
                                    className="reject-img"
                                    src={rejected}
                                />
                                <h1 className="cfr-heading">Oops, We Are Sorry!</h1>
                                <p className="cfr-text">We Are Not Able To Serve You At The Moment</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CibilFetchReject;

