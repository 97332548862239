import React, { useEffect, useState } from "react";

import '../../index.css';
import './style.scss';
import Navbar from '../../components/Navbar';

import Loan from '../../assets/images/PreApprovedLoan.png'
import { useNavigate } from 'react-router-dom';
import { useStores } from '../../stores';
import notify from '../../shared/notify';
import SpokesLoader from '../../components/Modal/SpokesLoader';
import numberWithCommas from "../../Helper/numberWithComma";

function PreApprovedLoan() {
  const navigate = useNavigate();

  const { applicantStore } = useStores();

  let retailerId = sessionStorage.getItem("retailerId");
  const nextActivityIdName = sessionStorage.getItem("nextActivityId");

  const [loanamount, setLoanAmount] = useState("");
  const [isEligible, setIsEligible] = useState(false);
  const [nextActivityId, setNextActivityId] = useState("");
  const [rejectedModal, setRejectedModal] = useState("");
  const [loader, setLoader] = useState(false)

  useEffect(() => {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function (event) {
        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/SessionExpired`)

      };
    getLoanData()
  }, []);

  const getLoanData = () => {
    let params = `retailerId=${retailerId}`
    setLoader(true)
    applicantStore.checkEligibilityAndGetLimit(params, (response) => {
      if (response?.exceptionOccured == 'N') {
        sessionStorage.setItem("nextActivityId", response?.result?.nextActivityId);
        setNextActivityId(response?.result?.nextActivityId);
        if (response?.result?.isEligible) {
          setIsEligible(true);
        } else {
          setIsEligible(false);
        }
        setLoanAmount( response?.result?.limit )
        notify(response?.result?.statusMessage, 'success');
        setLoader(false);
      } else {
        if (response?.status == 500) {
          notify(response?.exceptionMessage, 'danger');
          setLoader(false);
          navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
        } else if (response?.status == 105) {
          notify(response?.exceptionMessage, 'danger');
          setLoader(false);
          navigate(`/${process.env.REACT_APP_BASE_FOLDER}/NoOfferFound`);
        } else if (response?.status == 111) {
          notify(response?.exceptionMessage, 'danger');
          setLoader(false);
          navigate(`/${process.env.REACT_APP_BASE_FOLDER}/NoOfferFound`);
        } else {
          notify(response?.exceptionMessage, 'danger');
          setLoader(false);
          navigate(`/${process.env.REACT_APP_BASE_FOLDER}/NoOfferFound`);
        }
      }
    })
  }
  return (
    <>
      <Navbar logout={false} appid={true} />
      <div className="PreApprovedLoan-container">
        <div className="content-container">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <img src={Loan} className="loan_img" />
              </div>
              <div className="col-md-7">
                <div className="pal-box">
                  <h1>You Are Eligible For Pre Approved Loan</h1>
                  <h2>
                    Eligible Loan Amount:{" "}
                    <span className="span_color">{`INR ${numberWithCommas(loanamount)}`}</span>
                  </h2>
                  <div className="submit-btn">
                    <button
                      className="btn-view"
                      onClick={(e) => {
                        if (isEligible) {
                          navigate(
                            `/${process.env.REACT_APP_BASE_FOLDER}/UdyamRegistration`
                          );
                        } else {
                          notify("Not eligible", "danger");
                        }
                        // handleSubmit(e);
                        // nextActivityId != null
                        //   ? handleSubmit()
                        //   : notify("Retailer exists but Limit Expired", "danger");
                      }}
                    // disabled={showSubmit}
                    >
                      I Accept
                    </button>
                    <button
                      className="btn-interested"
                      onClick={(e) => {
                        navigate(
                          `/${process.env.REACT_APP_BASE_FOLDER}/NoOfferFound`
                        );
                        // handleSubmit(e);
                        // nextActivityId != null
                        //   ? handleSubmit()
                        //   : notify("Retailer exists but Limit Expired", "danger");
                      }}
                    // disabled={showSubmit}
                    >
                      Not Interested
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SpokesLoader
            show={loader}
            message={"Loading ..."}
          />
        </div>
      </div>
    </>
  );
}

export default PreApprovedLoan;
