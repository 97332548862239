import React, { useState } from 'react'
import './style.scss';
import Navbar from '../../components/Navbar'
import ekycimg from '../../assets/images/aadharkyc.png';
import Button from '../../components/UI/Button/Button';
import { useNavigate } from 'react-router-dom';

function GST() {
    const navigate = useNavigate();

    const [gstList,setGstList] = useState([]);
    const [selectedGst,setSelectedGst] = useState('');
    const [firmName,setFarmName] = useState('');
    const [address,setAddress] = useState('');

    return (
        <>
            <Navbar />
            <div className="gst-container">
                    <div className="content-container">
                        <div className="top-header">
                            <div className="top-header-icon">
                                <img
                                    className="icon_img"
                                    src={ekycimg}
                                />
                            </div>
                            <div className="top-header-content">
                                <h2>GST</h2>
                                <p>Select your GST. Let us understand your loan eligibility better.</p>
                            </div>

                        </div>
                        <div className="row">
                            <div className='col-md-12'>
                                <div className="address-form-group">
                                    <label>GST</label><br />
                                    <select onChange={(e)=>{setSelectedGst(e.target.value)}}>
                                        <option>19AAACC2069E1ZW</option>
                                        <option>Delhi</option>
                                        <option>Punjab</option>
                                    </select>
                                </div>
                            </div>

                            <div className='col-md-12'>
                                <div className="address-form-group">
                                    <label>Firm Name</label><br />
                                    <input type='text' name='text' id='text' placeholder='A2Z Enterprises' onChange={(e)=>{setFarmName(e.target.value)}} />
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className="address-form-group">
                                    <label>Address</label><br />
                                    {/* <p className='text-fatch'>{address}</p> */}
                                    <p className='text-fatch'>#604, Sector 3, Panchukula, Haryana- 140603</p>
                                </div>
                            </div>


                            <div className='col-md-12 aadhaar-otp-btn'>
                                <Button
                                    type="button"
                                    buttonStyle="btn--secondary--solid"
                                    buttonSize="btn-medium"
                                    onClick={()=>{navigate(`/${process.env.REACT_APP_BASE_FOLDER}/UdyamRegistration`)}}
                                    >
                                    Proceed
                                </Button>
                            </div>
                        </div>
                    </div>
            </div>
        </>
    )
}

export default GST