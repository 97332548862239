import React, { useEffect, useState, useRef } from "react";
import "../../index.css";
import "./style.scss";
import greencheck from "../../assets/images/greencheck.svg";
import download from "../../assets/images/download.svg";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import SpokesLoader from "../../components/Modal/SpokesLoader";
import Navbar from "../../components/Navbar";
import checkdawonload from "../../assets/images/download_img.svg";
import dawonload from "../../assets/images/dawonload-btn.svg";
import playstore from "../../assets/images/playstore.svg";
import vrifiedIcon from "../../assets/images/verified_icon.svg";

function ThankYou() {
  const navigate = useNavigate();
  const { applicantStore,businessDetailsStore } = useStores();
  const id = sessionStorage.getItem("embeddedPartnerId");
  const [applicationNumber, setApplicationNumber] = useState("");
  const [loader, setLoader] = useState(false);
  const [loanAccountNumber, setLoanAccountNumber] = useState("");
  const [umrnNumber, setUmrnNumber] = useState("");
  const [document, setDocument] = useState("");

  useEffect(() => {
    // window.history.pushState(null, null, window.location.href);
    // window.onpopstate = function (event) {
    //   navigate(`/${process.env.REACT_APP_BASE_FOLDER}/SessionExpired`)

    // };
    handleGetNachStatus();
    getMitcDocument();
  }, []);

  const getMitcDocument = () =>{
    let id = sessionStorage.getItem("embeddedPartnerId");

    let params = `retailerId=${id}`;
    businessDetailsStore.getMitcDocument(params,(res)=>{
      console.log(res)
      if(res?.exceptionOccured == "N")
      {
        let url = res?.result?.documentUrl;
        setDocument(url);
      }else{
        notify(res?.exceptionMessage,"danger")
      }
    })
  }

  const handleGetNachStatus = async () => {
    let id = sessionStorage.getItem("embeddedPartnerId");

    let params = `retailerId=${id}`;
    setLoader(true);
    await applicantStore.getNachMandateStatus(params, (response) => {
      console.log("getNachMandateStatus ", response);
      if (response?.exceptionOccured == "N") {
        console.log("getNachMandateStatus Success ", response);
        if (response?.result?.informationComplete) {
          setLoanAccountNumber(response?.result?.loanAccountNumber);
          setUmrnNumber(response?.result?.nachRegistrationNumber);
          setLoader(false);
        } else {
          // setLoanAccountNumber(response?.result?.loanAccountNumber)
          // setUmrnNumber(response?.result?.nachRegistrationNumber)
          setLoader(false);
        }
      } else {
        console.log("getNachMandateStatus else ", response);
        notify(response?.exceptionMessage, "danger");
        setLoader(false);
        return;
      }
    });
  };
  return (
    <>
      <Navbar logout={true} appid={true} />
      <div className="thanku_container">
        <div className="content-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5">
                <div className="thanku-img">
                  <img className="thanku_img" src={checkdawonload} />
                  <p className="thanku_text">
                  Congratulations{" "}
                  </p>
                  <div className="thanku_application_box">
                    <img className="tick_icon" src={vrifiedIcon} />
                    <h4 className="thanku_subtext">
                      Your Journey is Completed Successfully
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="th-content">
                  <div className="nach-number">
                  <div className="loan_account">
                    <p className="loan_text">Loan Account Number:</p>
                    <p className="loan_number">{loanAccountNumber}</p>
                  </div>
                  <div className="loan_account">
                  <p className="loan_text">NACH Registration Number :</p>
                    <p className="loan_number">{umrnNumber}</p>
                  </div>
                  </div>

                  <div className="line_box">
                    <hr />
                  </div>

                  <p className="email-text">
                    An Email with Most Important Terms and Conditions has been
                    sent to your Registered Email Id.
                  </p>
                  <div className="Terms_btn_container">
                    <button className="Terms_btn">
                      <a href={document} download={true} target="_blank" >
                      <img className="dawonload_icon" src={dawonload} />
                      Most Important Terms and Conditions
                      </a>
                    </button>
                    <button className="GroChain_btn" onClick={()=>{window.open("https://play.google.com/store/apps/details?id=com.ugrocapital.scfapp", "_blank");}}>
                      <img className="dawonload_icon" src={playstore} />{" "}
                      Download GroChain
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="submit-btn">
                      <button className="btn-view" >Proceed</button>
                  </div> */}
          </div>
        </div>
        <SpokesLoader show={loader} message={"Loading ..."} />
      </div>
    </>
  );
}

export default ThankYou;
