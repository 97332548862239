import React, { useEffect, useState, useRef } from "react";
import "../../index.css";
import "./style.scss";
import entitykyc from "../../assets/images/entitykyc.svg";
import Navbar from "../../components/Navbar";
import CustomInput from "../../components/UI/Form-input/CustomInput";
import { useNavigate } from "react-router-dom";
import udyamRegistrationValidate from "../../Helper/udyamRegistrationValidate";
import notify from "../../shared/notify";
import { useStores } from "../../stores";
import SpokesLoader from "../../components/Modal/SpokesLoader";
import UdhyamVerify from "../../components/UdhyamVerify";
import nofound from "../../assets/images/notfound.svg";
import VerifyOTP from "../../components/Modal/VerifyOTP";

function UdyamRegistration() {
    const navigate = useNavigate();
    const { applicantStore } = useStores();

    const id = sessionStorage.getItem("retailerId");
    const nextActivityId = sessionStorage.getItem("nextActivityId");

    const [udyamNumber, setUdyamNumber] = useState("");
    const [udyamApply, setUdyamApply] = useState(false);
    const [mobileNumber, setMobileNumber] = useState("");
    const [loader, setLoader] = useState(false);
    const [question, setQuestion] = useState("");
    const [requestId, setRequestId] = useState("");
    const [otpModal, setOtpModal] = useState(false);

    useEffect(() => {
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function (event) {
          navigate(`/${process.env.REACT_APP_BASE_FOLDER}/SessionExpired`)
  
        };
    }, [])
    

    const getUdyamNumber = () => {
        let ist1 = document.getElementById("ist1").value;
        let sec1 = document.getElementById("sec1").value;
        let third1 = document.getElementById("third1").value;
        let fourth1 = document.getElementById("fourth1").value;
        let fifth1 = document.getElementById("fifth1").value;
        let sixth1 = document.getElementById("sixth1").value;
        let seventh1 = document.getElementById("seventh1").value;
        let eight1 = document.getElementById("eight1").value;
        let nine1 = document.getElementById("nine1").value;
        let ten1 = document.getElementById("ten1").value;
        let eleven1 = document.getElementById("eleven1").value;

        if (
            ist1 === "" ||
            sec1 === "" ||
            third1 === "" ||
            fourth1 === "" ||
            fifth1 === "" ||
            sixth1 === "" ||
            seventh1 === "" ||
            eight1 === "" ||
            nine1 === "" ||
            ten1 === "" ||
            eleven1 === ""
        ) {
            notify(
                "All The Above Fields Are Mandatory, Please Enter Missing Details",
                "danger"
            );
            return;
        } else {
            let udyam = `UDYAM-${ist1 + sec1}-${third1 + fourth1}-${fifth1 + sixth1 + seventh1 + eight1 + nine1 + ten1 + eleven1
                }`;
            handleUdyamRegistration(udyam);
        }
    };
    const sendUdhyamOTP = (udhyam) => {
       
      setLoader(true);
      let payload = {
    "udyamNumber": udhyam,
    "retailerId": id
  }
  applicantStore.udyamRegCheckWithoutOTP(payload, (response) => {
    if (response?.data?.exceptionOccured == "N") {
      notify(response?.data?.result?.statusMessage)
      sessionStorage.setItem("nextActivityId", response?.data?.result?.nextActivityId);
      navigate(`/${process.env.REACT_APP_BASE_FOLDER}/BusinessAddress`);
      setLoader(false);
    } else {
      if (response?.data?.status == 500) {
        notify(response?.data?.exceptionMessage, 'danger');
        setLoader(false);
        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
      }else if (response?.data?.status == 110) {
        notify(response?.data?.exceptionMessage, "danger");
        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/LoanRejected`);
        setLoader(false);
        return;
    } else {

        notify(response?.data?.exceptionMessage, 'danger')
        setLoader(false);
      }
    }
  });
};

    // const sendUdhyamOTP = (udhyam) => {
       
    //     setLoader(true);
    //     let params = `udyamNumber=${udhyam}&retailerId=${id}&mobileNumber=${mobileNumber}`;
    //     applicantStore.udhyamSendOtp(params, (response) => {
    //         if (response?.data?.exceptionOccured == "N") {
    //             if (response?.data?.result?.status == "success") {
    //                 let requestId = response?.data?.result?.udyamOTPResponse?.requestId;
    //                 setRequestId(requestId);
    //                 notify(response?.data?.result?.udyamOTPResponse?.result?.message);
    //                 setLoader(false);
    //                 setOtpModal(true);
    //             } else {
    //                 setLoader(false);
    //                 setOtpModal(false);
    //             }
    //         } else {
    //           if (response?.data?.status == 500) {
    //             notify(response?.data?.exceptionMessage, 'danger');
    //             setLoader(false);
    //             navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
    //           }else{

    //             notify(response?.data?.exceptionMessage,'danger')
    //             setLoader(false);
    //           }
    //         }
    //     });
    // };

    const fetchUdyamDetails = (e) => {
        setLoader(true);
        let params = `udyamNumber=${e}&retailerId=${id}`;
        applicantStore.validateUdyamRegCheck(params, (response) => {
            console.log(response);
            if (response?.data?.result?.status === "success") {
                notify(response?.data?.result?.statusMessage);
                navigate(`/${process.env.REACT_APP_BASE_FOLDER}/UploadSelfie`);
                setLoader(false);
            } else {
                notify(response?.data?.exceptionMessage, "danger");
                setLoader(false);
                return;
            }
        });
    };

    const handleUdyamRegistration = (e) => {
        if (e.length == 19) {
            let validate = udyamRegistrationValidate(e);
            if (!validate) {
                notify("Please Enter A Valid Udyam Number", "danger");
                setUdyamNumber("");
                return;
            } 
            // else {
            //     if (mobileNumber == "") {
            //         notify(
            //             "The Above Fields Are Mandatory, Please Enter Missing Details",
            //             "danger"
            //         );
            //         return;
            //     }
            //     const isValidNumber = /^[6789]\d{9}$/.test(mobileNumber);
            //     if (!isValidNumber) {
            //         notify("Please Enter A valid Mobile Number", "danger");
            //         setMobileNumber("");
            //         return;
            //     }
            //     if (mobileNumber.length < 10) {
            //         notify("Please Enter A valid Mobile Number", "danger");
            //         return;
            //     }
                setUdyamNumber(e);
                // fetchUdyamDetails(e)
                sendUdhyamOTP(e);
            // }
        }
    };

    function clickEvent(first, current, last) {
        if (current == undefined) {
            if (first.target.value.length) {
                document.getElementById(last).maxLength = 1;
                document.getElementById(last).focus();
            }
        }
    }

    function clickEvent2(first, current, last) {
        var input = document.getElementById(current);

        input.addEventListener("keydown", function (event) {
            const key = event.key; // const {key} = event; ES6+
            if (key === "Backspace") {
                if (current != undefined) {
                    document.getElementById(current).maxLength = 1;
                    if (current == "eleven1" && first.target.value.length != 0) {
                        document.getElementById(current).focus();
                    } else {
                        document.getElementById(last).focus();
                    }
                }
            }
        });
    }

    const handleSkipUdhyam = () => {
        let params = `retailerId=${id}`;
        applicantStore.skipUdyam(params, (response) => {
            if (response?.data?.exceptionOccured == "N") {
                sessionStorage.setItem(
                    "nextActivityId",
                    response?.data?.result?.nextActivityId
                );
                if (response?.data?.result?.nextActivityId == "address_list") {
                    navigate(`/${process.env.REACT_APP_BASE_FOLDER}/BusinessAddress`);
                }
                notify(response?.data?.result?.statusMessage, "success");
            } else {
                notify(response?.data?.exceptionMessage, "danger");
            }
        });
    };

    const applyUdhyam = () => {
        setLoader(true);
        setTimeout(() => {
            setLoader(false);
            UdhyamCheckShow();
        }, 2000);
    };

    const UdhyamCheckShow = () => {
        setUdyamApply(true);
        setTimeout(() => {
            setUdyamApply(false);
            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/Congratulations`);
        }, 2000);
    };

    return (
      <>
      <Navbar logout={true} appid={true} />
      <div className="udyamregistration-container">
        <div className="content-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="top-header-title">
                  <h2>Udyam Aadhar</h2>
                  <hr className="heading-divider" />
                </div>
                <div className="top-header-content">
                  <img className="header-img" src={entitykyc} />
                  <p>Kindly Provide Udyam Aadhar Number</p>
                </div>

                {/* <select
                                className="form-control"
                                value={question}
                                onChange={(e) => {
                                    setQuestion(e.target.value);
                                    if (e.target.value == 'No') {
                                        notify('Ugro Capital shall apply for Udyam on Your Behalf !', 'success')
                                    }
                                }}
                            >
                                <option value="">Select option</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select> */}

                <div className="form-input">
                  <div className="row">
                    <div className="col-md-6">
                      <label className="udyam-title">
                        Please Enter Your Udyam Number
                      </label>
                      <div className="bank-mandate-input">
                        <p className="form-label">UDYAM - </p>

                        <div className="form-group">
                      <input
                        type="text"
                        className="form-control inputheight"
                        id="ist1"
                        onChange={(e) =>
                          e.target.validity.valid &&
                          clickEvent(e, undefined, "sec1")
                        }
                        name="otp_11"
                        required=""
                        maxLength="1"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control inputheight"
                        id="sec1"
                        onChange={(e) =>
                          e.target.validity.valid &&
                          clickEvent(e, undefined, "third1")
                        }
                        onKeyDown={(e) => clickEvent2(e, "sec1", "ist1")}
                        name="otp_12"
                        required=""
                        maxLength="1"
                      />
                    </div>
                    <p className="form-label-hyphen"> - </p>
                    <div className="form-group">
                      <input
                        type="tel"
                        className="form-control inputheight"
                        id="third1"
                        onChange={(e) =>
                          e.target.validity.valid &&
                          clickEvent(e, undefined, "fourth1")
                        }
                        onKeyDown={(e) => clickEvent2(e, "third1", "sec1")}
                        name="otp_13"
                        required=""
                        maxLength="1"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="tel"
                        className="form-control inputheight"
                        id="fourth1"
                        onChange={(e) =>
                          e.target.validity.valid &&
                          clickEvent(e, undefined, "fifth1")
                        }
                        onKeyDown={(e) => clickEvent2(e, "fourth1", "third1")}
                        name="otp_14"
                        required=""
                        maxLength="1"
                      />
                    </div>
                    <p className="form-label-hyphen"> - </p>
                    <div className="form-group">
                      <input
                        type="tel"
                        className="form-control inputheight"
                        id="fifth1"
                        onChange={(e) =>
                          e.target.validity.valid &&
                          clickEvent(e, undefined, "sixth1")
                        }
                        onKeyDown={(e) => clickEvent2(e, "fifth1", "fourth1")}
                        name="otp_15"
                        required=""
                        maxLength="1"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="tel"
                        className="form-control inputheight"
                        id="sixth1"
                        onChange={(e) =>
                          e.target.validity.valid &&
                          clickEvent(e, undefined, "seventh1")
                        }
                        onKeyDown={(e) => clickEvent2(e, "sixth1", "fifth1")}
                        name="otp_16"
                        required=""
                        maxLength="1"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="tel"
                        className="form-control inputheight"
                        id="seventh1"
                        onChange={(e) =>
                          e.target.validity.valid &&
                          clickEvent(e, undefined, "eight1")
                        }
                        onKeyDown={(e) => clickEvent2(e, "seventh1", "sixth1")}
                        name="otp_17"
                        required=""
                        maxLength="1"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="tel"
                        className="form-control inputheight"
                        id="eight1"
                        onChange={(e) =>
                          e.target.validity.valid &&
                          clickEvent(e, undefined, "nine1")
                        }
                        onKeyDown={(e) => clickEvent2(e, "eight1", "seventh1")}
                        name="otp_18"
                        required=""
                        maxLength="1"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="tel"
                        className="form-control inputheight"
                        id="nine1"
                        onChange={(e) =>
                          e.target.validity.valid &&
                          clickEvent(e, undefined, "ten1")
                        }
                        onKeyDown={(e) => clickEvent2(e, "nine1", "eight1")}
                        name="otp_19"
                        required=""
                        maxLength="1"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="tel"
                        className="form-control inputheight"
                        id="ten1"
                        onChange={(e) =>
                          e.target.validity.valid &&
                          clickEvent(e, undefined, "eleven1")
                        }
                        onKeyDown={(e) => clickEvent2(e, "ten1", "nine1")}
                        name="otp_110"
                        required=""
                        maxLength="1"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="tel"
                        className="form-control inputheight"
                        id="eleven1"
                        onKeyDown={(e) => clickEvent2(e, "eleven1", "ten1")}
                        name="otp_111"
                        required=""
                        maxLength="1"
                      />
                    </div>
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <CustomInput
                        type="text"
                        style="mobileNumber-input"
                        label="Udyam Registered Mobile Number"
                        placeholder="Enter Mobile"
                        name={mobileNumber}
                        value={mobileNumber}
                        maxLength={10}
                        pattern="[0-9]*"
                        onChange={(e) => {
                          e.target.validity.valid
                            ? setMobileNumber(e.target.value)
                            : notify(
                                "Mobile Number Can Only Contain Numbers",
                                "danger"
                              );
                        }}
                        // mandatory={true}
                      />
                    </div> */}
                  </div>

                  <div className="btn-main-container">
                    <div className="btn-container">
                      <button
                        className="btn-view"
                        onClick={() => {
                          getUdyamNumber();
                          // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/BusinessAddress`);
                        }}
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                </div>

                <VerifyOTP
                  show={otpModal}
                  hide={() => {
                    setOtpModal(false);
                  }}
                  getOtp={"udhyam"}
                  isLoggedIn={true}
                  mobile={mobileNumber}
                  requestId={requestId}
                  navigate={() => {
                    // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ThankYou`)
                  }}
                  header={"Verify OTP To Accept Udhyam Aadhar"}
                  stopLoader={() => setLoader(false)}
                />
                <div className="udaym-number-apply">
                  <p>
                    Don’t Have Udyam Number?{" "}
                    <a href="https://udyamregistration.gov.in/Government-India/Ministry-MSME-registration.htm">
                      Apply Now
                    </a>
                  </p>
                  {/* <p className="cursor">Don’t have Udyam Number? <a onClick={() => { applyUdhyam() }}>Apply Now</a></p> */}
                </div>
                <SpokesLoader show={loader} message={"Loading ..."} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    );
}

export default UdyamRegistration;
