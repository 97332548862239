// import notify from "../components/notify";
import { makeAutoObservable } from "mobx";
import Service from "../shared/service/Service";


class applicantStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }



    getToken() {
        return sessionStorage.getItem("token");
    }

    setToken(token) {
        sessionStorage.setItem(token);
    }



    // async validateOtp(params, callback) {
    //     await Service.post(`login/validateOTP?${params}`, "", async (response) => {
    //         if (response !== null && response !== undefined) {
    //             await sessionStorage.setItem('token', response?.data?.result?.token)
    //             Service.refreshHeader(() => {
    //                 return callback(response);
    //             })
    //         }

    //     }).catch((error) => {
    //         return callback(error);
    //     });

    // }
    async validateOtp(payload, callback) {
        await Service.post(`login/validateOTP`, payload, async (response) => {
            if (response !== null && response !== undefined) {
                await sessionStorage.setItem('token', response?.data?.result?.token)
                Service.refreshHeader(() => {
                    return callback(response);
                })
            }

        }).catch((error) => {
            return callback(error);
        });

    }


    async sendOtp(params, callback) {
        await Service.post(`sendOTP?${params}`, "", async (response) => {
            return callback(response);
        }).catch((error) => {
            return callback(error)
        });

    }

    async eSignOTP(params, callback) {
        await Service.post(`terms-and-condition/eSignOTP?${params}`, "", async (response) => {
            return callback(response);
        }).catch((error) => {
            return callback(error)
        });

    }

    async initiateEsign(params, callback) {
        await Service.post(`terms-and-condition/initiateESign?${params}`, "", async (response) => {
            return callback(response);
        }).catch((error) => {
            return callback(error)
        });

    }

    async checkEsignStatus(params, callback) {
        await Service.post(`terms-and-condition/checkESignStatus?${params}`, "", async (response) => {
            return callback(response);
        }).catch((error) => {
            return callback(error)
        });

    }

    async acceptEsign(params, callback) {
        await Service.post(`acceptEsign?${params}`, "", async (response) => {
            return callback(response);
        }).catch((error) => {
            return callback(error)
        });
    }

    async getBankLists(callback) {
        await Service.getV2(`finAggPortalAPIService/api/v1/report/bankList`, (status, response) => {
            return callback(response);
        }).catch((error) => {
            return callback(error)
        });
    }

    async getAccountType(callback) {
        await Service.getV2(`finAggPortalAPIService/api/v1/report/accountTypeList`, (status, response) => {
            return callback(response);
        }).catch((error) => {
            return callback(error)
        });
    }

    async getLoanAccountNumber(params, callback) {
        await Service.get(`getLoanAccountNumber?${params}`, async (status, response) => {
            return callback(response);
        }).catch((error) => {
            return callback(error)
        });

    }

    async processCheque(params,payload, callback) {
        await Service.post(`bank-details/processCheque?${params}`, payload, async (response) => {
            return callback(response);
        }).catch((error) => {
            return callback(error)
        });
    }

    async getNachMandateStatus(params, callback) {
        await Service.get(`embedded-onboard/getNachMandateStatus?${params}`, async (status,response) => {
            return callback(response);
        }).catch((error) => {
            return callback(error)
        });
    }

    // async validateUdyamRegCheck(params, callback) {
    //     await Service.post(`udyamRegCheck?${params}`, "", async (response) => {
    //         return callback(response)
    //     }).catch((error) => {
    //         return callback(error)
    //     });
    // }

    async uploadFile(payload, callback) {
        await Service.post(`embedded-onboard/uploadSelfie`, payload, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }

    async uploadCheque(payload, callback) {
        await Service.post(`bank-details/uploadCheque`, payload, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }

    async submitBasicDetails(params,payload, callback) {
        await Service.post(`individual-details/basicDetails?${params}`, payload, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }

    async getLimitData(params, callback) {
        await Service.post(`embedded-onboard/getLimitData?${params}`, {}, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }


    async getAadhaarAddress(params, callback) {
        await Service.get(`getAadhaarAddress?${params}`,(status,response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }

    async acceptPolicy(params, callback) {
        await Service.get(`acceptPolicy?${params}`,(status,response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }

    async getAadharVerificationData(params, callback) {
        await Service.get(`kyc-details/getAadhaarVerificationData?${params}`,(status,response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }

    
    async udhyamSendOtp(params, callback) {
        await Service.post(`udyam-details/sendUdyamOTP?${params}`, "", async (response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }

    async udyamRegCheckWithoutOTP(data, callback) {
        await Service.post(`udyam-details/udyamRegCheckWithoutOTP`,data, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }

    async validateUdyamRegCheck(params, callback) {
        await Service.post(`udyam-details/udyamRegCheck?${params}`, "", async (response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }

    async uploadPAN(params,payload, callback) {
        await Service.post(`kyc-details/uploadPan?${params}`,payload, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }

    async individualDetails(params,payload, callback) {
        await Service.post(`individual-details/initialDetailsForCustomerStaging?${params}`,payload, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }
    
    async getPanProfile(params, callback) {
        await Service.get(`kyc-details/getPanProfile?${params}`,(status,response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }
    
    async uploadAadhar(params,payload, callback) {
        await Service.post(`kyc-details/uploadAadhaar?${params}`,payload, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }
    
    async checkEligibilityAndGetLimit(params, callback) {
        await Service.get(`embedded-onboard/checkEligibilityAndGetLimit?${params}`,(status,response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }
    
    async getAadhaarVerificationData(params, callback) {
        await Service.get(`kyc-details/getAadhaarVerificationData?${params}`,(status,response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }
    async skipUdyam(params, callback) {
        await Service.post(`udyam-details/skipUdyam?${params}`,'', async (response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }

    async getCurrentResidenceAddress(params,callback){
        await Service.get(`individual-details/getCurrentResidenceAddress?${params}`,(status,response) =>{
                return callback(response);
        }).catch((error)=>{
            return callback(error);
        })
    }


    /// getAuth() {
    //     let auth = sessionStorage.getItem("isAuthenticated");
    //     if (auth != null) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }
}


export default applicantStore;
