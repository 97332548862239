import React, { useEffect, useState, useRef } from "react";
import "../../index.css";
import "./style.scss";
import logo from "../../assets/images/logo.png";

function Navbar(props) {
  let token = sessionStorage.getItem("token");
  let loanRequestId = sessionStorage.getItem("loanRequestId");
  let retailerName = sessionStorage.getItem("retailerName");

  const logout = () => {
    let programId = sessionStorage.getItem("programName");
    removeSeesionStorage();
    localStorage.clear();
    window.location.href = `/`;
  };

  const removeSeesionStorage = () =>{
    sessionStorage.removeItem("login");
    sessionStorage.removeItem("loginData");
    sessionStorage.removeItem("retailerName");
    sessionStorage.removeItem("nextActivityId");
    sessionStorage.removeItem("embeddedPartnerId");
    sessionStorage.removeItem("retailerId");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("loanRequestId");
    sessionStorage.removeItem("UrmnNumber");
  }

  return (
    <div className="Navbar-container">
      <div className="navbar">
        <img src={logo} className="ugro-logo" />
        <div className="d-flex">
        {props.firmName &&
                <div className="login-heading">
                  <p >Welcome <span>{retailerName}</span></p>
                </div>
              }
          {props.appid && (
            <div className="appid">
              App ID: <span className="App_id">{loanRequestId}</span>
            </div>
          )}
          {props.logout && (
            <button
              className="btn-view"
              onClick={() => {
                logout();
              }}
            >
              Logout
            </button>
          )}
        </div>
      </div>
      <div className="sub-header"></div>
      {/* <div className="submit-btn">
  
      </div> */}
    </div>
  );
}

export default Navbar;
