import { makeAutoObservable } from "mobx";
import Service from "../shared/service/Service";


class businessDetailsStore {
    constructor(rootStore) {
        this.rootStore= rootStore;
        makeAutoObservable(this);
    }

    // async retailerBusinessDetails(payload,callback){
    //     await Service.post(`retailerBusinessDetails`,payload,(response) =>{
    //             return callback(response);
    //     }).catch((error)=>{
    //         return callback(error);
    //     })
    // }

    async uploadFile(payload,callback){
        await Service.post(`business-details/retailerBusinessDetails`,payload,(response) =>{
                return callback(response);
        }).catch((error)=>{
            return callback(error);
        })
    }

    async oKycGetOtp(params,payload,callback){
        await Service.post(`kyc-details/okyc/getOTP?${params}`,payload,(response) =>{
                return callback(response);
        }).catch((error)=>{
            return callback(error);
        })
    }

    async onboardRunAML(params,callback){
        await Service.post(`runAml?${params}`,{},(response) =>{
                return callback(response);
        }).catch((error)=>{
            return callback(error);
        })
    }

    async oKycVerifyOtp(params,payload,callback){
        await Service.post(`kyc-details/okyc/aadhar?${params}`,payload,(response) =>{
                return callback(response);
        }).catch((error)=>{
            return callback(error);
        })
    }

    async eSignValidateOTP(params,payload,callback){
        await Service.post(`terms-and-condition/eSignValidateOTP?${params}`,payload,(response) =>{
                return callback(response);
        }).catch((error)=>{
            return callback(error);
        })
    }

    async businessList(callback){
        await Service.get(`business-details/getBusinessList`,(status,response) =>{
                return callback(response);
        }).catch((error)=>{
            return callback(error);
        })
    }
    
    async processAPIService(params, callback) {
        await Service.post(`processAPIService?${params}`,{}, (response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }
    async retailerBusinessDetails(params,payload, callback) {
        await Service.post(`business-details/retailerBusinessDetails?${params}`,payload, (response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }
    async currentBusinessAddress(params,payload, callback) {
        await Service.post(`business-details/currentBusinessAddress?${params}`,payload, (response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }

    async currentResidenceAddress(params,payload, callback) {
        await Service.post(`individual-details/currentResidenceAddress?${params}`,payload, (response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }
    
    async fetchBusinessAddress(params,callback){
        await Service.get(`business-details/fetchBusinessAddress?${params}`,(status,response) =>{
                return callback(response);
        }).catch((error)=>{
            return callback(error);
        })
    }
  
    async getCurrentBusinessAddress(params,callback){
        await Service.get(`business-details/getCurrentBusinessAddress?${params}`,(status,response) =>{
                return callback(response);
        }).catch((error)=>{
            return callback(error);
        })
    }

    async basicDetails(params,payload, callback) {
        await Service.post(`business-details/basicDetails?${params}`,payload, (response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }

    async insertVerifiedBusinessAddress(params,payload, callback) {
        await Service.post(`business-details/insertVerifiedBusinessAddress?${params}`,payload, (response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }

    async insertVerifiedResidenceAddress(params,payload, callback) {
        await Service.post(`individual-details/insertVerifiedResidenceAddress?${params}`,payload, (response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }
    async udyamRegistrationForm(params,payload, callback) {
        await Service.post(`udyam-details/udyamRegistrationForm?${params}`,payload, (response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }
    async enachRegistration(params, callback) {
       
        await Service.post(`bank-details/enachRegistration?${params}`,'', (response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }
    async transactionComplete(params, callback) {
       
        await Service.post(`bank-details/transactionComplete?${params}`,'', (response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }
    
    
    async fetchPincodeData(params, callback) {
        await Service.get(`embedded-onboard/fetchPincodeData?${params}`,(status,response) => {
            return callback(response)
        }).catch((error) => {
            return callback(error)
        });
    }

    async acceptOffer(params,callback){
        await Service.get(`terms-and-condition/acceptOffer?${params}`,(status,response) =>{
                return callback(response);
        }).catch((error)=>{
            return callback(error);
        })
    }
    async getMitcDocument(params,callback){
        await Service.get(`terms-and-condition/getMitcDocument?${params}`,(status,response) =>{
                return callback(response);
        }).catch((error)=>{
            return callback(error);
        })
    }
}

export default businessDetailsStore;