import React, { useEffect, useState, useRef } from "react";
import "../../index.css";
import "./style.scss";
import UploadSelfieimg from "../../assets/images/ekycimg/ekycimg.webp";
import picimg from "../../assets/images/pic.svg";
import camera from "../../assets/images/camera.png";
import Navbar from "../../components/Navbar";
import { useNavigate } from "react-router-dom";
import convertBase64 from "../../Helper/convertBase64";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import SpokesLoader from "../../components/Modal/SpokesLoader";
import Webcam from "react-webcam";
import UploadSelfiModal from "../../components/Modal/UploadSelfiModal";
import CameraModal from "../../components/Modal/CameraModal";
import { faL } from "@fortawesome/free-solid-svg-icons";
import AccessModal from "../../components/Modal/AccessModal";

function UploadSelfie() {
    const selfieInput = useRef();
    const navigate = useNavigate();
    const { applicantStore } = useStores();

    const id = sessionStorage.getItem("embeddedPartnerId");

    const [selfiedBase64, setSelfieBase64] = useState("");
    const [selfiedName, setSelfieName] = useState("");
    const [loader, setLoader] = useState(false);
    const [camFound2, setCamFound2] = useState(false);
    const [openSlefieModal, setOpenSlefieModal] = useState(false);
    const [openCameraModal, setOpenCameraModal] = useState(false);
    const [camBase64, setCamBase64] = useState("");
    const [accesModal, setAccesModal] = useState(false);

    let isMobile = window.innerWidth <= 600;


    const webcamRef = React.useRef(null);
    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        //   console.log(imageSrc,"<=========")
        const data = imageSrc.split(",")[1];
        setCamBase64(data);
        // console.log(webcamRef.current);
    }, [webcamRef]);

    useEffect(() => {
        if (camBase64 != "") {
            let payloadData = {
                embeddedPartnerId: id,
                imageName: `camImage_${id}.jpg`,
                imageSelfieDataBase64: camBase64,
            };
            // setIsLoading(true)
            applicantStore.uploadFile(payloadData, (response) => {
                console.log(response);
                if (response?.data?.exceptionOccured == "N") {

                    if (response?.data?.result?.status === "success") {
                        notify(response?.data?.result?.statusMessage);
                        setOpenCameraModal(false)
                        setSelfieName(response?.data?.result?.imagePath);
                        sessionStorage.setItem("nextActivityId",response?.data?.result?.nextActivityId);
                        sessionStorage.setItem("nextActivityId","offer_details");
                        setLoader(false);
                        // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/Congratulations`)
                    } else {
                        notify(response?.data?.exceptionMessage, "danger");
                        setLoader(false);
                        return;
                    }
                } else {
                    if (response?.data?.status == 500) {
                        notify(response?.data?.exceptionMessage, "danger");
                        setCamBase64("")
                        setOpenCameraModal(false)
                        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
                        setLoader(false);
                        return;
                    } else if (response?.data?.status == 103) {
                        notify(response?.data?.exceptionMessage, "danger");
                        setOpenCameraModal(false)
                        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/LoanRejected`);
                        setLoader(false);
                        return;
                    } else if (response?.data?.status == 104) {
                        notify(response?.data?.exceptionMessage, "danger");
                        setOpenCameraModal(false)
                        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/LoanRejected`);
                        setLoader(false);
                        return;
                    }
                    else if (response?.data?.status == 102) {
                        notify(response?.data?.exceptionMessage, "danger");
                        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/LoanRejected`);
                        setLoader(false);
                        return;
                    } else if (response?.data?.status == 110) {
                        notify(response?.data?.exceptionMessage, "danger");
                        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/LoanRejected`);
                        setLoader(false);
                        return;
                    }
                }
            });
        }
    }, [camBase64])

    useEffect(() => {
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function (event) {
            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/SessionExpired`)

        };
    }, [])
    const videoConstraints = {
        width: 1280,
        height: 720,
        facingMode: "user",
    };

    const handleFileRead = async (event) => {
        const file = event.target.files[0];
        const filesize = file.size / 1000000;
        const fileName = file.name;
        const base64 = await convertBase64(file);
        return { base64, filesize, fileName };
    };


    const handleSelfieUpload = async (e) => {
        
        const result = await handleFileRead(e);
        const data = result.base64.split(",")[1];
        const splitfile = data.split(";")[0];
        const fileType = result.base64.split(";")[0];
        const name = result.fileName;
        const type = fileType.split(":")[1];

        if (type === "image/jpeg" || type === "image/png" || type === "image/jpg") {
            if (result.filesize > 10) {
                notify("Please Capture File Less Than Or Equal To 10 mb", "danger");
                selfieInput.current.value = "";
                return;
            }
            // let formattedDate = formattedCurrentDate();
            // setSelfieBase64(data)
            // setSelfieName(name)
          
            let payloadData = {
                embeddedPartnerId: id,
                imageName: name,
                imageSelfieDataBase64: data,
            };
            setLoader(true);           
            applicantStore.uploadFile(payloadData, (response) => {
                console.log(response);
                if (response?.data?.exceptionOccured == "N") {

                    if (response?.data?.result?.status === "success") {
                        notify(response?.data?.result?.statusMessage);
                        setOpenSlefieModal(false)
                        setSelfieName(response?.data?.result?.imagePath);
                        setLoader(false);
                        // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/Congratulations`)
                    } else {
                        notify(response?.data?.exceptionMessage, "danger");
                        selfieInput.current.value = "";
                        setLoader(false);
                        return;
                    }
                } else {
                    if (response?.data?.status == 500) {
                        notify(response?.data?.exceptionMessage, "danger");
                        selfieInput.current.value = "";
                        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
                        setOpenSlefieModal(false)
                        setLoader(false);
                        return;
                    } else if (response?.data?.status == 103) {
                        notify(response?.data?.exceptionMessage, "danger");
                        selfieInput.current.value = "";
                        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/LoanRejected`);
                        setLoader(false);
                        return;
                    } else if (response?.data?.status == 104) {
                        notify(response?.data?.exceptionMessage, "danger");
                        selfieInput.current.value = "";
                        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/LoanRejected`);
                        setLoader(false);
                        return;
                    }
                    else if (response?.data?.status == 102) {
                        notify(response?.data?.exceptionMessage, "danger");
                        selfieInput.current.value = "";
                        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/LoanRejected`);
                        setLoader(false);
                        return;
                    } else if (response?.data?.status == 110) {
                        notify(response?.data?.exceptionMessage, "danger");
                        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/LoanRejected`);
                        selfieInput.current.value = "";
                        setLoader(false);
                        return;
                    }
                }
            });
        } else {
            notify("Please Capture jpg, jpeg, png Images", "danger");
            return;
        }
    };

    const handleNext = () => {
        if (selfiedName !== "") {
            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/Congratulations`);
        } else {
            notify("Please upload your selfie first", "danger");
        }
    };

    useEffect(() => {
        // getMedia()
        let camAllow = sessionStorage.getItem('camAllow');
        if (!isMobile) {
            console.log(isMobile)
            if (camAllow == null) {
                setAccesModal(true);
            }
            navigator.mediaDevices
                .getUserMedia({ video: true, audio: false })
                .then(function (stream) {
                    setCamFound2(true);
                    sessionStorage.setItem('camAllow', false)
                    // setAccesModal(false);
                })
                .catch(function (error) {
                    console.error("Error accessing webcam:", error);
                    notify('Camera Not Found', 'danger')
                    setCamFound2(false);
                    // setAccesModal(true)
                });
        }
    }, []);



    const WebcamCapture = () => (
        <>
            <Webcam
                audio={false}
                height={600}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={600}
                videoConstraints={videoConstraints}
            />
            <button onClick={capture}>Capture photo</button>
        </>
    );

    return (
        <>
            <Navbar logout={true} appid={true} />
            <div className="UploadSelfie-container">
                <div className="content-container">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="top-header-title">
                                    <h2>Upload A Selfie</h2>
                                    <hr className="heading-divider" />
                                </div>
                                <div className="top-header-content">
                                    <img className="header-img" src={UploadSelfieimg} />
                                    <p>Please Click Your Selfie And Upload </p>
                                </div>
                                <div className="UploadSelfie-view">
                                    {selfiedName === "" ? (
                                        <img className="UploadSelfie-img" src={picimg} />
                                    ) : (
                                        <img className="UploadSelfie-img" src={selfiedName} />
                                    )}
                                </div>

                                {selfiedName === "" ? (
                                    <div className="btn-main-container">
                                        <div className="mobile-none">
                                            {camFound2 && (
                                                <div
                                                    className="btn-view"
                                                    onClick={() => {
                                                        setOpenCameraModal(true);
                                                    }}
                                                >
                                                    <img className="camera-img" src={camera} />
                                                    Capture Selfie
                                                </div>
                                            )}
                                        </div>
                                        {!camFound2 && (
                                            <>
                                                <label for="input-file" className="upload_Lable">
                                                    <div className="btn-view">
                                                        <img className="camera-img" src={camera} />
                                                        Capture Selfie
                                                    </div>
                                                </label>
                                                <input
                                                    id="input-file"
                                                    type="file"
                                                    style={{ display: "none" }}
                                                    ref={selfieInput}
                                                    disabled={!isMobile ? true : false}
                                                    accept="image/*"
                                                    onChange={(e) => {
                                                        handleSelfieUpload(e);
                                                    }}
                                                    capture="environment"
                                                />
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    camFound2 ?
                                    <div className="btn-main-container">
                                    <div
                                      className="btn-view "
                                      onClick={() => {
                                        setOpenCameraModal(true);
                                      }}
                                    >
                                      <img className="camera-img" src={camera} />
                                      Re-Capture Selfie
                                    </div>
                                  </div>
                                        :
                                        <div className="btn-main-container">
                                            <label for="input-file" className="upload_Lable">
                                                <div className="btn-view">
                                                    <img className="camera-img" src={camera} />
                                                    Re-Capture Selfie
                                                </div>
                                            </label>
                                            <input
                                                id="input-file"
                                                type="file"
                                                style={{ display: "none" }}
                                                ref={selfieInput}
                                                disabled={!isMobile ? true : false}
                                                accept="image/*"
                                                onChange={(e) => {
                                                    handleSelfieUpload(e);
                                                }}
                                            />
                                        </div>
                                   )}
                                <div className="d-flex align-items-center justify-content-center mt-4">
                                    <div className="submit-btn">
                                        <button
                                            className="btn-view submit_btn"
                                            onClick={() => {
                                                // handleNext() unComment when binding
                                                if (selfiedName != "") {
                                                    navigate(
                                                        `/${process.env.REACT_APP_BASE_FOLDER}/Congratulations`
                                                    );
                                                } else {
                                                    notify("Please Capture a Selfie", "danger");
                                                }
                                            }}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                                <SpokesLoader show={loader} message={"Loading ..."} />
                            </div>
                        </div>
                    </div>
                    {/* <UploadSelfiModal
                        show={openSlefieModal}
                        uploadFromCamera={() => {
                            setOpenCameraModal(true);
                            setOpenSlefieModal(false);
                        }}
                        selfieInput={selfieInput}
                        onChange={(e) => {
                            handleSelfieUpload(e);
                        }}
                    /> */}
                    <CameraModal
                        webcamRef={webcamRef}
                        show={openCameraModal}
                        hide={() => {
                            setOpenCameraModal(false);
                        }}
                        capture={() => { capture(); }}
                    />

                    <AccessModal
                        show={accesModal}
                        message={"Allow camera access to capture selfie"}
                        hide={() => {
                            setAccesModal(false);
                        }}
                    />
                </div>
            </div>
        </>
    );
}

export default UploadSelfie;