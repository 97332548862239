import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import clock from "../../assets/images/clock.svg";
import dawonload from "../../assets/images/dawonload-btn.svg";
import playstore from "../../assets/images/playstore.svg";
import vrifiedIcon from "../../assets/images/verified_icon.svg";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import notify from "../../shared/notify";
import { useStores } from "../../stores";

function PandingThankYou() {

  const navigate = useNavigate();
  const { businessDetailsStore } = useStores();

  const [document, setDocument] = useState("");


  useEffect(() => {
    // window.history.pushState(null, null, window.location.href);
    // window.onpopstate = function (event) {
    //   navigate(`/${process.env.REACT_APP_BASE_FOLDER}/SessionExpired`)

    // };
    getMitcDocument();
  }, [])

  const getMitcDocument = () => {
    let id = sessionStorage.getItem("embeddedPartnerId");

    let params = `retailerId=${id}`;
    businessDetailsStore.getMitcDocument(params, (res) => {
      console.log(res)
      if (res?.exceptionOccured == "N") {
        let url = res?.result?.documentUrl;
        setDocument(url);
      } else {
        notify(res?.exceptionMessage, "danger")
      }
    })
  }


  return (
    <>
      <Navbar logout={true} appid={true} />
      <div className="panding_thanku_container">
        <div className="content-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4 d-border">
                <div className="panding_thanku_body">
                  <div className="thanku-img">
                    <img className="panding_thanku_img" src={clock} />
                  </div>
                  <div className="thanku_application_box">
                    <p className="thanku_text">
                      Thank You For Your Application!
                    </p>

                    <div className="pt-icon-list">
                      <img className="tick_icon" src={vrifiedIcon} />
                      <h4 className="thanku_subtext">
                        Your Journey is about to complete
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className=" pt-left">
                  <ul>
                    <li>
                      You need to complete Video KYC (if applicable) You will
                      receive a SMS  with link for this process.
                    </li>
                    <li>
                      You can start utilizing your limit once the above step is completed.
                    </li>
                  </ul>

                  {/* <h4 className="thanku_subtext">
                    You can start utilizing your limit once the above 2 steps
                    are complete.
                  </h4> */}
                  <hr className="pt-devider" />

                  <p className="email-text">
                    SMS with Most Important Terms and Conditions has been
                    sent to your Registered Mobile No.
                  </p>
                  <div className="Terms_btn_container">
                    <button className="Terms_btn">
                      <a href={document} download={true} target="_blank">
                        <img className="dawonload_icon" src={dawonload} /> Most
                        Important Terms and Conditions
                      </a>
                    </button>
                    {/* <button className="GroChain_btn"><img className="dawonload_icon" src={playstore} /> Download GroChain</button> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="submit-btn">
                                <button className="btn-view" >Proceed</button>
                            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default PandingThankYou;
