// import notify from "../components/notify";
import { makeAutoObservable } from "mobx";
import Service from "../shared/service/Service";


class authStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }



    getToken() {
        return sessionStorage.getItem("token");
    }

    setToken(token) {
        sessionStorage.setItem(token);
    }

    isLoggedIn() {
        const token = sessionStorage.getItem("token");
        if (!token || token === "" || token === null) {
            return false;
        }
        return true;
    }

    async validateRetailer(params, callback) {
        // sessionStorage.removeItem("loginData");
        // sessionStorage.removeItem("token");
        await Service.get(`login/validateRetailerId?${params}`, async (status, response) => {
            return callback(response);
        }).catch((error) => {
            return callback(error)
        });
        // await Service.get(`login/validateRetailerId?retailerId=${params}`, async (status, response) => {
        //     return callback(response);
        // }).catch((error) => {
        //     return callback(error)
        // });

    }


    // async login(formData,callback) {                 
    //     // sessionStorage.removeItem("loginData");
    //     // sessionStorage.removeItem("token");

    //         await Service.post(`login`,formData, async (status, response) => {                
    //             // sessionStorage.removeItem("isAuthorized");
    //             if (response.message === "Success") {
    //             //     sessionStorage.setItem("loginData",JSON.stringify(response.result))
    //             //     sessionStorage.setItem("lastActivityTime",response.result.lastActivityTime);
    //             //     sessionStorage.setItem("token",response.result.token);
    //                 return callback(response);
    //             } else if (response.message === "Fail") {
    //                 return callback(response);
    //             }

    //         }).catch((error) => {
    //             // this.loginMod.loading = false;
    //     });        
    // }

    async login(payload, callback) {
        await Service.post(`login`, payload, async (response) => {
            return callback(response);
        }).catch((error) => {
            return callback(error)
        });
    }

    async sendOtp(params, callback) {
        await Service.post(`login/sendOTP?${params}`, "", async (response) => {
            return callback(response);
        }).catch((error) => {
            return callback(error)
        });

    }



    async kycDetails(payload, callback) {
        await Service.post(`kycDetails`, payload, (response) => {
            return callback(response);
        }).catch((error) => {
            return callback(error);
        })
    }










    // getAuth() {
    //     let auth = sessionStorage.getItem("isAuthenticated");
    //     if (auth != null) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }
}


export default authStore;
