import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import "./styles.scss";

function NotAOfferModal(props) {
    return (
        <Modal
            {...props}
            centered
            className="NotAOfferModal-container"
        >
            <Modal.Body>
                <div className="content-view">
                    <p>We Currently Do Not Have Offers For Partnership And Private Limited Entities</p>
                    <button className="ok-btn" onClick={props.onClick}>OK</button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default NotAOfferModal;
