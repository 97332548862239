import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import AccessModal from "../../components/Modal/AccessModal";

function Esign() {
    const navigate = useNavigate();
    const { businessDetailsStore, applicantStore } = useStores();
    const retailerId = sessionStorage.getItem("retailerId");

    const [showTitle, setShowTitle] = useState(true);
    const [loader, setLoader] = useState(false);
    const [accesModal, setAccesModal] = useState(false);





    useEffect(() => {
        // window.history.pushState(null, null, window.location.href);
        // window.onpopstate = function (event) {
        //     navigate(`/${process.env.REACT_APP_BASE_FOLDER}/SessionExpired`)

        // };
        setAccesModal(true);
        setTimeout(() => {

            handleIntiateEsign()
        }, 2000);
    }, []);

    const callback = (response) => {
        console.log(response, "<===========")
        verifyEsignStatus();
    }

    const sign = (url) => {
        const Leegality = window.Leegality;
        var obj = {
            logoUrl: url,
            callback: callback
        };
        var leegality = new Leegality(obj);
        leegality.init();
        //You will get a signing url by uploading document from backend.
        var signingUrl = url;
        leegality.esign(signingUrl);
        setTimeout(() => {

            setShowTitle(false);
        }, 3000);
    }

    const handleIntiateEsign = async () => {
        let params = `retailerId=${retailerId}`
        setLoader(true)

        await applicantStore.initiateEsign(params, (response) => {
            setLoader(false)
            if (response?.data?.exceptionOccured == "N") {
                if (response?.data?.message === "Success") {
                    let url = response?.data?.result?.signUrl
                    // window.open(url, '_self');
                    sign(url)

                }

            } else {
                setLoader(false)
                navigate(`/${process.env.REACT_APP_BASE_FOLDER}/TryAgain`);
                notify(response?.data?.exceptionMessage, 'danger')
                return;
            }
        })
    }

    const verifyEsignStatus = async () => {
        let params = `retailerId=${retailerId}`
        setLoader(true)

        await applicantStore.checkEsignStatus(params, (response) => {
            if (response?.data?.exceptionOccured == "N") {
                if (response?.data?.message === "Success") {

                    if (response?.data?.result?.status == "success") {
                        if (response?.data?.result?.action == "Signed") {
                            setLoader(false)
                            sessionStorage.setItem("nextActivityId", "enach");

                            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/enach`)
                        }
                        else if (response?.data?.result?.action == "failed") {
                            setLoader(false)
                            notify(response?.data?.result?.statusMessage, 'danger')
                            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/LoanRejected`);
                            return;
                        } else {
                            setLoader(false)
                            notify(response?.data?.result?.statusMessage, 'danger')
                            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/TryAgain`);
                            return;

                        }
                    }
                    else {
                        if (response?.data?.result?.action == "failed") {
                            setLoader(false)
                            notify(response?.data?.result?.statusMessage, 'danger')
                            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/LoanRejected`);
                            return;
                        } else {
                            setLoader(false)
                            notify(response?.data?.result?.statusMessage, 'danger')
                            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/TryAgain`);
                            return;
                        }
                    }

                } else {
                    setLoader(false)
                    notify(response?.data?.exceptionMessage, 'danger')
                    navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
                    return;

                }
            } else {
                if (response?.data?.status == 500) {
                    notify(response?.data?.exceptionMessage, "danger");
                    navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
                    setLoader(false)
                    return;
                } else {

                    setLoader(false)
                    notify(response?.data?.exceptionMessage, 'danger')
                    navigate(`/${process.env.REACT_APP_BASE_FOLDER}/TryAgain`);
                    return;
                }
            }
        })
    }



    return (
        <>
            <Navbar />
            <div className="Esign-container">
                <div className="content-container">
                    <div className="container-fluid text-center">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="loader-box">
                                    <div className="waiting-loader"></div>
                                    {showTitle &&
                                        <p>You Shall Be Now Redirected To Esign Terms and Conditions. Please Unblock The Pop-up In Browser If Blocked.</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AccessModal
                    show={accesModal}
                    message={"Allow access to E-Sign  the document "}
                    hide={() => {
                        setAccesModal(false);
                    }}
                />
            </div>
        </>
    );
}

export default Esign;