import React, { useEffect, useState, useRef } from "react";
import "../../index.css";
import "./style.scss";
import logo from "../../assets/images/logo.png";
import CustomInput from "../../components/UI/Form-input/CustomInput";
import VerifyOTP from "../../components/Modal/VerifyOTP";
import SpokesLoader from "../../components/Modal/SpokesLoader";
import basicinfo from "../../assets/images/basicinfo.svg";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import Navbar from "../../components/Navbar";

function BasicInformation() {
  const navigate = useNavigate();
  const { applicantStore } = useStores();

  const id = sessionStorage.getItem("embeddedPartnerId");

  const [showloader, setShowLoader] = useState(false);

  const [name, setName] = useState('');
  const [currentaddress, setCurrentAddress] = useState('');
  const [addressStatus, setAddressStatus] = useState('');
  const [motherName, setMotherName] = useState('');
  const [maritalStatus, setMaritalStatus] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [qualification, setQualification] = useState('');
  const [businessNature, setBusinessNature] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [monthsInBusiness, setMonthsInBusiness] = useState('');
  const [otherAddress, setOtherAddress] = useState(false)
  const [aadhaarAddress, setAadhaarAddress] = useState('')
  const [otherCurrentAddress, setOtherCurrentAddress] = useState('')
  const [address, setAddress] = useState('');
  const [individualAddress, setindividualAddress] = useState('');


  // const showloader = () => {
  //     setOtpModal(true);
  // }

  // useEffect(() => {
  //     if (otpModal) {
  //         setTimeout(() => {
  //             setOtpModal(false)
  //             navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ProcessCompleted`);
  //         }, 3000);
  //     }
  // }, [otpModal])




  useEffect(() => {
    // window.history.pushState(null, null, window.location.href);
    // window.onpopstate = function (event) {
    //   navigate(`/${process.env.REACT_APP_BASE_FOLDER}/SessionExpired`)

    // };
    getAadhaarAddress()
  }, [])

  const handleContinue = async (e) => {
    e.preventDefault();
    // basicDetails
    // if (name === '') {
    //     notify('Please enter your name', 'danger');
    //     return;
    // }


    if (currentaddress === '' || addressStatus === '' || motherName === '' || maritalStatus === '' || phoneNumber === '' || qualification === '') {
      notify("All The Above Fields Are Mandatory, Please Enter Missing Details", 'danger')
      return
    }
    const isValidNumber = /^[6789]\d{9}$/.test(phoneNumber);
    if (!isValidNumber) {
      notify('Please Enter A valid Mobile Number', 'danger')

      return
    }

    if (phoneNumber.length < 10) {
      notify('Please Enter A valid Mobile Number', 'danger')
      return
    }



    setShowLoader(true)
    let params = `retailerId=${id}`
    let payload = {
      "currentResidentialAddress": individualAddress,
      "numberOfMonthsInCurrentResidentialAddress": currentaddress,
      "statusOfCurrentResidentialAddress": addressStatus,
      "motherName": motherName,
      "maritalStatus": maritalStatus,
      "alternatePhone": phoneNumber,
      "educationalQualification": qualification,
    }

    await applicantStore.submitBasicDetails(params, payload, (response) => {
      if (response?.data?.result?.status === "success") {
        setShowLoader(false)
        notify(response?.data?.result?.statusMessage)
        // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ProcessCompleted`)
        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/PanUpload`)
        // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/UploadSelfie`)// user navigate to selfie upload according to new flow
      } else {
        if (response?.data?.status == 500) {
          notify(response?.data?.exceptionMessage, "danger");
          navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
          setShowLoader(false)
          return;
      }else{
        setShowLoader(false)
        notify(response?.data?.exceptionMessage, "danger")
        return;
      }
      }
    })
  }

  const getAadhaarAddress = async () => {
    let params = `retailerId=${id}`

    await applicantStore.getCurrentResidenceAddress(params, (res) => {
      // console.log(response);
      if (res?.exceptionOccured == "N") {
        setindividualAddress(res?.result?.currentAddress)
      } else {
        notify(res?.exceptionMessage, 'danger')
      }
    })
  }



  return (
    <>
      <Navbar logout={true} appid={true} />
      <div className="basicInformation-container">
        <div className='content-container'>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                {/* <div className="body-container">
                                    <div className="top-header">
                                        <div className="top-header-icon">
                                            <img
                                                className="Business-img"
                                                src={basicinfo}
                                            />
                                        </div>
                                        <div className="top-header-content">
                                            <h2>Basic Information of Individual</h2>
                                            <p>Please tell us more about yourself</p>
                                        </div>
                                    </div>
                                    <div className="Login-form">
                                        <div className="business-entity mb-0">
                                            <h3>Current Residential Address<span className="required_field">*</span></h3>
                                            <div className="radio-container ">
                                                {individualAddress}
                                            </div>
                                        </div>
                                        {address === 'Other' &&
                                            <CustomInput
                                                type="text"
                                                label="Enter your current address"
                                                placeholder="Please enter current address"
                                                value={otherCurrentAddress}
                                                onChange={(e) => { setOtherCurrentAddress(e.target.value) }}
                                            // mandatory={true}
                                            />
                                        }
                                        <CustomInput
                                            type="tel"
                                            label="No. Of Months In Current Residential Address"
                                            placeholder="No. Of Months In Current Residential Address"
                                            value={currentaddress}
                                            pattern="[0-9]*"
                                            maxLength={3}
                                            onChange={(e) => { e.target.validity.valid && setCurrentAddress(e.target.value) }}
                                            mandatory={true}
                                        />
                                        <div className="form-input">
                                            <label>Status Of Current Residential Address <span className="required_field">*</span></label>
                                            <select
                                                className="form-control"
                                                value={addressStatus}
                                                onChange={(e) => { setAddressStatus(e.target.value) }}
                                            >
                                                <option value="">Select status</option>
                                                <option value="Owned">Owned</option>
                                                <option value="Rented" >Rented</option>
                                            </select>
                                        </div>
                                        <CustomInput
                                            type="text"
                                            label="Mother’s Name"
                                            placeholder="Enter Mother name"
                                            value={motherName}
                                            onChange={(e) => { setMotherName(e.target.value) }}
                                            mandatory={true}
                                        />
                                        <div className="form-input">
                                            <label>Marital Status<span className="required_field">*</span></label>
                                            <select
                                                className="form-control"
                                                value={maritalStatus}
                                                onChange={(e) => { setMaritalStatus(e.target.value) }}
                                            >
                                                <option value="">Select marital status</option>
                                                <option value="Married">Married</option>
                                                <option value="Un-Married" >Un-Married</option>
                                            </select>
                                        </div>
                                        <CustomInput
                                            type="tel"
                                            label="Alternate Phone Number"
                                            placeholder="Enter Alternate phone number"
                                            value={phoneNumber}
                                            pattern="[0-9]*"
                                            onChange={(e) => { e.target.validity.valid && 
                                                setPhoneNumber(e.target.value)
                                             }}
                                            maxLength={10}
                                            mandatory={true}
                                        />
                                        <div className="form-input">
                                            <label>Educational Qualification<span className="required_field">*</span></label>
                                            <select
                                                className="form-control"
                                                value={qualification}
                                                onChange={(e) => { setQualification(e.target.value) }}
                                            >
                                                <option value="">Select educational qualification</option>
                                                <option value="Post- Graduate">Post- Graduate</option>
                                                <option value="Graduate">Graduate</option>
                                                <option value="Under-Graduate">Under-Graduate</option>
                                                <option value="Doctorate" >Doctorate</option>
                                                <option value="M Arch/B Arch/MBBS/BDS/Allopathic">M Arch/B Arch/MBBS/BDS/Allopathic</option>
                                                <option value="MD/ MS/MDS">MD/ MS/MDS</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="submit-btn">
                                        <button className="btn-view" onClick={(e)=>{
                                            // handleContinue(); uncomment when start binding
                                            handleContinue(e)
                                           
                                        }}
                                        >Proceed</button>
                                    </div>
                                    <SpokesLoader
                                        show={showloader}
                                    />
                                </div> */}
                <div className="top-header-title">
                  <h2>Basic Information Of Individual</h2>
                  <hr className="heading-divider" />
                </div>
                <div className="top-header-content">
                  <img className="header-img" src={basicinfo} />
                  <p>Please Tell Us More About Yourself</p>
                </div>

              </div>

                <div className="col-md-4">
                  {" "}
                  <div className="business-entity mt-0 mb-0">
                    <h3>
                      Current Residential Address
                      <span className="required_field">*</span>
                    </h3>
                    <div className="radio-container ">
                      {individualAddress}
                    </div>
                  </div>
                </div>
                {address === "Other" && (
                  <div className="col-md-4">
                    <CustomInput
                      type="text"
                      label="Enter Your Current Address"
                      placeholder="Please Enter Your Current Address"
                      value={otherCurrentAddress}
                      onChange={(e) => {
                        setOtherCurrentAddress(e.target.value);
                      }}
                    // mandatory={true}
                    />
                  </div>
                )}
                <div className="col-md-4">
                  {" "}
                  <CustomInput
                    type="tel"
                    label="No. Of Months In Current Residential Address"
                    placeholder="No. Of Months In Current Residential Address"
                    value={currentaddress}
                    maxLength={3}
                    pattern="[0-9]*"
                    onChange={(e) => {
                      e.target.validity.valid &&
                        setCurrentAddress(e.target.value);
                    }}
                    mandatory={true}
                  />
                </div>
                <div className="col-md-4">
                  <div className="form-input">
                    <label>
                      Status Of Current Residential Address{" "}
                      <span className="required_field">*</span>
                    </label>
                    <select
                      className="form-control"
                      value={addressStatus}
                      onChange={(e) => {
                        setAddressStatus(e.target.value);
                      }}
                    >
                      <option value="">Select Status</option>
                      <option value="Owned">Owned</option>
                      <option value="Rented">Rented</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <CustomInput
                    type="text"
                    label="Mother’s Name"
                    placeholder="Enter Mother Name"
                    value={motherName}
                    onChange={(e) => {
                      setMotherName(e.target.value);
                    }}
                    mandatory={true}
                  />
                </div>
                <div className="col-md-4">
                  <div className="form-input">
                    <label>
                      Marital Status
                      <span className="required_field">*</span>
                    </label>
                    <select
                      className="form-control"
                      value={maritalStatus}
                      onChange={(e) => {
                        setMaritalStatus(e.target.value);
                      }}
                    >
                      <option value="">Select Marital Status</option>
                      <option value="Married">Married</option>
                      <option value="Un-Married">Un-Married</option>
                      <option value="Divorced">Divorced</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <CustomInput
                    type="tel"
                    label="Alternate Phone Number"
                    placeholder="Enter Alternate Phone Number"
                    value={phoneNumber}
                    pattern="[0-9]*"
                    onChange={(e) => {
                      e.target.validity.valid &&
                        setPhoneNumber(e.target.value);
                    }}
                    maxLength={10}
                    mandatory={true}
                  />
                </div>
                <div className="col-md-4">
                  <div className="form-input">
                    <label>
                      Educational Qualification
                      <span className="required_field">*</span>
                    </label>
                    <select
                      className="form-control"
                      value={qualification}
                      onChange={(e) => {
                        setQualification(e.target.value);
                      }}
                    >
                      <option value="">
                        Select Educational Qualification
                      </option>
                      <option value="Post- Graduate">Post- Graduate</option>
                      <option value="Graduate">Graduate</option>
                      <option value="Under-Graduate">Under-Graduate</option>
                      <option value="Doctorate">Doctorate</option>
                      <option value="M Arch/B Arch/MBBS/BDS/Allopathic">
                        M Arch/B Arch/MBBS/BDS/Allopathic
                      </option>
                      <option value="MD/ MS/MDS">MD/ MS/MDS</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="submit-btn">
                <button
                  className="btn-view"
                  onClick={(e) => {
                    handleContinue(e);
                  
                  }}
                >
                  Proceed
                </button>
              </div>
              <SpokesLoader show={showloader} message={"Loading ..."}  />
            </div>
          </div>
        </div>

    </>
  );
}

export default BasicInformation;
