import React, { useEffect, useRef, useState } from "react";
// import axios from 'axios'
import "../../index.css";
import "./style.scss";
import businessentity from "../../assets/images/businessentity.svg";
import CustomInput from "../../components/UI/Form-input/CustomInput";
import VerifyOTP from "../../components/Modal/VerifyOTP";
import { useNavigate } from "react-router-dom";
import notify from "../../shared/notify";
import pancardValidation from "../../Helper/panCardValidate";
import { useStores } from "../../stores";
import date from "../../assets/images/date.png";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SpokesLoader from "../../components/Modal/SpokesLoader";
import Info from "../../assets/images/info.png";
import logo from "../../assets/images/logo.png";
import loginbanner from "../../assets/images/login.png";
import { useSearchParams } from "react-router-dom";

// import moment from "moment";

function Login() {
  const navigate = useNavigate();
  const { businessDetailsStore } = useStores();
  const doiRef = useRef();
  const { authStore, applicantStore } = useStores();
  let nextActivityId = sessionStorage.getItem("nextActivityId");
  const retailerId = sessionStorage.getItem('retailerId')
  let token = sessionStorage.getItem("token");

  const [retailerName, setRetailerName] = useState("");

  let [searchParams, setSearchParams] = useSearchParams();

  const [mobile, setMobile] = useState("");
  const [pan, setPan] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [panValid, setPanValid] = useState(false);
  const [loader, setLoader] = useState(false);
  const [retailerFound, setRetailerFound] = useState("");
  const [showSubmit, setShowsubmit] = useState(false);
  const [key, setKey] = useState('');
  const [otpModal, setOtpModal] = useState('');
  // const [found, setFound] = useState(false);

  useEffect(() => {
    // window.history.pushState(null, '', window.location.href);
    // window.onpopstate = function (event) {
    //   window.history.pushState(null, '', window.location.href);
    // };
  }, [])
  useEffect(() => {
    sessionStorage.removeItem("login");
    sessionStorage.removeItem("loginData");
    sessionStorage.removeItem("retailerName");
    sessionStorage.removeItem("nextActivityId");
    sessionStorage.removeItem("embeddedPartnerId");
    sessionStorage.removeItem("retailerId");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("loanRequestId");
    localStorage.clear();
    getQueryVariable();
  }, []);

  useEffect(() => {
    if (panValid) {
      validateRetailer();
    }
  }, [panValid]);

  const getQueryVariable = () => {
    let currentURL = window.location.href;
    let programId = currentURL?.split("programName=")[1];
    let programNameGet = sessionStorage.getItem('programName');
    if(programNameGet == null || programNameGet == "undefined")
    {
      sessionStorage.setItem("programName", decodeURI(programId));
    }else{
      setSearchParams(`?programName=${programNameGet}`);
    }
  };

  const validateRetailer = async () => {
    let programName = sessionStorage.getItem("programName");
    let params = `retailerId=${pan.toUpperCase()}&programName=${programName}`
    setLoader(true)
    await authStore.validateRetailer(params, (response) => {

      if (response?.exceptionOccured === "N") {
        if (response?.result?.status == "failed") {
          setShowsubmit(true);
          setRetailerFound(response?.result?.status);
          sessionStorage.setItem(
            "nextActivityId",
            response?.result?.nextActivityId
          );
          setKey(Math.random());
          setLoader(false)
          // notify(response?.result?.statusMessage, "danger")
        } else {
          sessionStorage.setItem(
            "nextActivityId",
            response?.result?.nextActivityId
          );
          sessionStorage.setItem('retailerId', response?.result?.embeddedPartnerId);
          sessionStorage.setItem('loanRequestId', response?.result?.loanRequestId);
          sessionStorage.setItem('retailerName', response?.result?.retailerName);
          sessionStorage.setItem('embeddedPartnerId', response?.result?.embeddedPartnerId)
          setKey(Math.random());
          setRetailerFound(response?.result?.status);
          setShowsubmit(false);
          setLoader(false);
          // notify(response?.result?.statusMessage, "success")
        }
      } else {
        if (response?.data?.status == 500) {
          notify(response?.data?.exceptionMessage, "danger");
          navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
          setLoader(false);
          return;
        } else {
          setLoader(false);
          sessionStorage.setItem("nextActivityId", "login");
        }
        // notify(response?.exceptionMessage, "danger");
      }
    });
  };

  const handleRetailerSubmit = async (e) => {
    e.preventDefault();
    
      if (nextActivityId == 'login') {
        if (pan == "" || mobile == "" || pinCode == "") {
          notify('All The Above Fields Are Mandatory, Please Enter Missing Details', 'danger')
          return
        }
        const isValidNumber = /^[6789]\d{9}$/.test(mobile);
        if (!isValidNumber || mobile.length < 10) {
          notify('Please Enter A valid Mobile Number', 'danger')
          setMobile('')
          return
        }
        if (pinCode.length < 6) {
          notify('Please Enter a Valid Pincode', 'danger');
          return
        }
      } else {
        if (pan == "" || mobile == "") {
          notify('All The Above Fields Are Mandatory, Please Enter Missing Details', 'danger')
          return
        }
        const isValidNumber = /^[6789]\d{9}$/.test(mobile);
        if (!isValidNumber) {
          notify('Please Enter A valid Mobile Number', 'danger')
          setMobile('')
          return
        }
      
    }
    let programName = sessionStorage.getItem("programName");
    let params = `retailerId=${pan.toUpperCase()}&programName=${programName}`
    setLoader(true)
    await authStore.validateRetailer(params, (response) => {

      if (response?.exceptionOccured === "N") {
        if (response?.result?.status == "failed") {
          setShowsubmit(true);
          setRetailerFound(response?.result?.status);
          sessionStorage.setItem(
            "nextActivityId",
            response?.result?.nextActivityId
          );
          navigate(`/${process.env.REACT_APP_BASE_FOLDER}/NoOfferFound`);
          setKey(Math.random());
          setLoader(false)
          // notify(response?.result?.statusMessage, "danger")
        } else {
          sessionStorage.setItem(
            "nextActivityId",
            response?.result?.nextActivityId
          );
          handleSubmit();
          setLoader(false);
        }
      } else {
        if (response?.data?.status == 500) {
          notify(response?.data?.exceptionMessage, "danger");
          navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
          setLoader(false);
          return;
        } else {
          setLoader(false);
          sessionStorage.setItem("nextActivityId", "login");
        }
        // notify(response?.exceptionMessage, "danger");
      }
    });
  };

  useEffect(() => {
    sessionStorage.removeItem("UserData");
  }, []);



  const handlePanOnBlur = (e) => {
    let validate = pancardValidation(e);
    if (validate === false) {
      setPanValid(false);
      setPan('');
      notify(" Please Enter A Valid PAN", "danger");
      return;
    } else {
      setPanValid(true);
    }
  };

  const handleSubmit = async () => {
    // e.preventDefault();

   
    setLoader(true);
    let loginData = {
      pan: pan.toUpperCase(),
      mobile: mobile,
      pincode: pinCode
    }
    sessionStorage.setItem('loginData', JSON.stringify(loginData));
    if (nextActivityId != '')
      if (nextActivityId == 'login') {
        setLoader(false);
        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/TermsAndCondition`);
      } else {
        handleContinue();
      }
  };

  const handleContinue = async () => {
    // sessionStorage.setItem("login", true);
    // window.location.href = `/${process.env.REACT_APP_BASE_FOLDER}/`;
    // Otp model open on this function
    let params = `mobileNumber=${mobile}&retailerId=${retailerId}`
    setLoader(true)
    await authStore.sendOtp(params, (response) => {
      if (response?.data?.exceptionOccured === "N") {
        if (response?.data?.result?.status == 'success') {
          setLoader(false)
          setOtpModal(true)
          notify(response?.data?.result?.statusMessage, "success");
        }
        else {
          setLoader(false)
          notify(response?.data?.result?.statusMessage, "danger");
        }
      } else {
        setLoader(false)
        // notify("Something went wrong!", 'danger')
        notify(response?.data?.exceptionMessage, 'danger')
      }
    })
  }


  const handleNavigation = () => {
    navigate(`/${process.env.REACT_APP_BASE_FOLDER}/PrivacyPolicy`);
  };

  return (
    <div className="Login_container">
      <div className="Login_View">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7 lr-container">
              <img src={loginbanner} className="login-banner" alt="Login Banner" />
            </div>
            <div className="col-md-5 lb-container">
              <div className="login-box">
                <div className="login-form">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <img src={logo} className="ugro-logo-img" />
                    </div>
                    <div className="col-md-12 text-center">
                      <h3 className="login-heading">
                        Please Login With Your Details
                      </h3>
                    </div>
                    <form className="input-form" key={key}>
                      <div className="row">
                        <div className="col-md-12">
                          <CustomInput
                            key={key}
                            type="text"
                            label="PAN"
                            placeholder="Enter Your PAN"
                            name={pan}
                            value={pan.toUpperCase()}
                            onBlur={(e) => {
                              pan.length > 0 && handlePanOnBlur(e.target.value);
                            }}
                            onChange={(e) => {
                              setPan(e.target.value);
                              setPanValid(false);
                            }}
                          // mandatory={true}
                          />
                        </div>
                      
                          <div className="col-md-12">
                            <CustomInput
                              type="text"
                              label="Mobile Number"
                              placeholder="Enter Mobile Number"
                              name={mobile}
                              value={mobile}
                              maxLength={10}
                              pattern="[0-9]*"
                              onChange={(e) => {
                                e.target.validity.valid
                                  ? setMobile(e.target.value)
                                  : notify(
                                    "Please Enter A valid Mobile Number",
                                    "danger"
                                  );
                              }}
                            // mandatory={true}
                            />
                          </div>
       
                        {nextActivityId == "login" && (
                          <div className="col-md-12">
                            <CustomInput
                              type="text"
                              label="Residence Pincode"
                              placeholder="Enter Your Residence Pincode"
                              name={pinCode}
                              value={pinCode}
                              maxLength={6}
                              onBlur={(e) => {
                                pinCode.length > 6 && notify('Please Enter a Valid Pincode')
                              }}
                              onChange={(e) => setPinCode(e.target.value.replace(/\s/g, ''))}
                            // mandatory={true}
                            />
                          </div>
                        )}
                        {/* {nextActivityId == null ?
                          <div className="col-md-12 text-center">
                            <div className="submit-btn">
                              <button
                                className="btn-view"
                                onClick={(e) => {
                                  validateRetailer(e);
                                }}
                              // disabled={showSubmit}
                              >
                                Validate
                              </button>
                            </div>
                          </div>
                          : */}
                          <div className="col-md-12 text-center">
                            <div className="submit-btn">
                              <button
                                className="btn-view"
                                onClick={(e) => {
                                  handleRetailerSubmit(e);
                                }}
                              // disabled={showSubmit}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        {/* } */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <VerifyOTP
            show={otpModal}
            hide={() => { setOtpModal(false) }}
            // getOtp={'terms'}
            isLoggedIn={true}
            mobile={mobile}
            navigate={() => {
              // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ThankYou`)
            }}
            header={"Verify OTP "}
            stopLoader={() => setLoader(false)}
          />

        </div>
        <SpokesLoader show={loader} message={"Loading ..."} />
      </div>
    </div>
  );
}

export default Login;
