import React, { useEffect, useState } from "react";

import "../../index.css";
import "./style.scss";

import VerifyOTP from "../../components/Modal/VerifyOTP";
import SpokesLoader from "../../components/Modal/SpokesLoader";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../stores";
import Navbar from "../../components/Navbar";
import notify from "../../shared/notify";

function LoginTermAndCondition() {
  const navigate = useNavigate();
  const { authStore } = useStores();

  const id = sessionStorage.getItem("retailerId");
  const loginData = sessionStorage.getItem("loginData");
  const data = JSON.parse(loginData);

  const [otpModal, setOtpModal] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      navigate(`/${process.env.REACT_APP_BASE_FOLDER}/SessionExpired`)
    };
  }, [])
  

  const handleContinue = async () => {
    // sessionStorage.setItem("login", true);
    // window.location.href = `/${process.env.REACT_APP_BASE_FOLDER}/`;
    // Otp model open on this function
    setOtpModal(true);
    let params = `mobileNumber=${data.mobile}&retailerId=${id}`;
    setLoader(true);
    await authStore.sendOtp(params, (response) => {
      if (response?.data?.exceptionOccured === "N") {
        if (response?.data?.result?.status == "success") {
          setLoader(false);
          setOtpModal(true);
          notify(response?.data?.result?.statusMessage, "success");
        } else {
          setLoader(false);
          notify(response?.data?.result?.statusMessage, "danger");
        }
      } else {
        setLoader(false);
        // notify("Something went wrong!", 'danger')
        notify(response?.data?.exceptionMessage, "danger");
      }
    });
  };

  return (
    <>
      <Navbar />
      <div className="term-container">
        <div className="content-container">
          <h3 className="term-heading">Terms & Condition</h3>
          <p className="term-text">
            <div>
              <ol type="1">
                <li>Submission or acceptance of loan application to UGRO Capital Ltd does not imply / inferred to automatic
                  approval by the Lender and the Lender will decide the quantum of the loan at its sole and absolute
                  discretion. The Lender in its sole and absolute discretion may either sanction or reject the application for
                  granting the loan.</li>
                <li>No discount / gift or any other commitment is given or offered to
                  us/me in whatsoever manner, either not documented in the loan agreement or committed orally, by
                  UGRO Capital Ltd or any of its authorized representative(s).</li>
                <li>Copies of Documents as per the list have been provided by me / us voluntarily/ willingly for loan
                  evaluation and i/we understand that the same will not be returned in the event of rejection. That I / We
                  shall furnish any additional documents as and when required by UGRO Capital Ltd.</li>
                <li>I/We declare that all the particulars and information and documents provided along with this form
                  are genuine, true, correct, complete and up to date in all respects and that I/we have not
                  withheld/suppressed any information /document whatsoever. I / we also authorize UGRO Capital Ltd. to
                  use the documents, download records from CKYCR using the KYC identifier submitted, video record the
                  KYC documents, personal discussion and any other information provided herewith to extract additional
                  information from various public domains, including but not limited to CIBIL/Bureau Report, Perfios
                  Report etc, or for any other regulatory &amp; compliance related matters, prior to sanction/post sanction.</li>
                <li>Any fault or misrepresentation in the document will be my/ our sole responsibility and UGRO Capital Ltd
                  has the authority to take rightful action against any such fault/ misrepresentation.</li>
                <li>I/ We hereby authorize and give consent to UGRO Capital Ltd to disclose, without notice to me/ us,
                  information furnished by me/ us in the application form(s)/ related documents executed/ to be
                  executed in relation to the facilities to be availed by me/ us from UGRO Capital Ltd, to other branches/
                  subsidiaries/ afﬁliates/ Credit Bureaus/ to the Credit Information Bureau of India (CIBIL) /Rating
                  Agencies/ Service Providers, banks/ ﬁnancial institutions, governmental/ regulatory authorities or third
                  parties who may need, process and publish the information in such manner and through such medium
                  as it may be deemed necessary by the lender / RBI, including publishing the name as part of wilful
                  defaulter&#39;s list from time to time, as also use for KYC information veriﬁcation for information
                  veriﬁcation, credit risk analysis or for any other purposes as the Lender deemed necessary.</li>
                <li>I/We acknowledge and understand that the Application / processing fees collected from me/ us by
                  UGRO Capital Ltd, is for reviewing this loan application as per its own parameters and is not refundable
                  to me/us under any circumstances whatsoever, irrespective of whether UGRO capital Ltd sanctions this
                  loan application of mine or not. No cash has been given by me/us to any person for whatsoever reason
                  related to the loan application.</li>
                <li>I/We hereby represent and warrant that I / we have full and adequate authority/legal competency to
                  submit application and such documents for availing the loan from UGRO Capital Ltd.</li>
                <li>I/we authorize and give consent to UGRO capital Ltd. for communicating with me/us regularly via SMS/
                  E-mail/ calls/ WhatsApp/ other social media applications.</li>
                <li>Mobile number provide for receiving OTP is registered in the name of borrower/ applicant/ co-applicant
                  or its main promoter/ director/ partner and i/we hereby authorize you/subsidiaries/ afﬁliates/Service
                  Provider/Third Party Vendor for sending any promotional/transactional SMS. Further i/we confirm that
                  the provided mobile number/s are not registered with Do Not Disturb (DND) “</li>
                <li>I hereby declare that I have an annual household income above Rs. 3,00,000 wherein household means
                  individual family unit ie. Husband, wife and their unmarried children.</li>
              </ol>

              <h3 className="term-heading">Privacy Policy:</h3>
              <h4 className="term-subheading">Objective:</h4>
              <p>
                UGRO Capital Ltd may collect and store information about you when you visit our site, use our services, or view
                our online advertisements. The information which UGRO Capital Ltd collects and store during normal use of the
                site is used to monitor use of the site and to help further development of our products and services. We use this
                information to improve our Web-based offerings.
              </p>
              <h4 className="term-subheading">Scope:</h4>
              <p>
                This policy applies to information collected by UGRO Capital Ltd through its
                websites.
              </p>
              <p>
                UGRO Capital Ltd also may collect some information automatically, which
                include:
              </p>
              <ul>
                <li>IP address</li>
                <li>Information about your web browser software and version;</li>
                <li>General geographic location indicated by your IP address;</li>
                <li>Website that referred you to us, and similar information.</li>
              </ul>
              <p>
                We also may collect information about your activity on our websites, which
                might include:
              </p>
              <ul>
                <li>frequency of visits to our web site;</li>
                <li>Average length of visits;</li>
                <li>Pages viewed during a visit.</li>
              </ul>
              <p>
                We may use various technologies to collect information about your activities
                on our websites, including:
              </p>
              <ul>
                <li>Cookies (If not selected may affect certain or all functionalities);</li>
                <li>Web Beacons / Web bugs (Measures user experience on our website).</li>

              </ul>
              <p>
                We may combine your information, automatically gathered information and the information we collect through
                cookies/web beacons / web bugs, including information that we have collected about you, or information that
                we have obtained from other sources. We may aggregate any statistics or data that are collected, but we are
                under no obligation to do so.
              </p>

              <h4 className="term-subheading">Use of collected Personal Information</h4>

              <p>
                UGRO Capital Ltd may use the personal information that is collected from you to provide requested products
                and services and for our internal business purposes, including, but not limited to:
              </p>
              <ul>
                <li>Responding to your requests for our unique products and services;</li>
                <li>To process your transactions and to provide you with transaction and post transaction-related services,
                  providing, improving, and marketing our products and services, including site content and performance;</li>
                <li>Evaluating your use of the services;</li>
                <li>Providing advertising for products and services that might interest you;</li>
                <li>Responding to your requests for information;</li>
                <li>Communicating with you by e mail, postal mail or courier, and/or telephone about products or services that
                  may be of interest to you;</li>
                <li>Maintaining and managing your account with us;</li>
                <li>Keeping you updated on the issues that are relevant to your business relationship with us;</li>
                <li>Monitoring your compliance with any terms and conditions associated with our products and services,
                  including to confirm your identity;</li>
                <li>Performing other functions as described to you at the time of collection or pursuant to your consent.</li>
              </ul>

              <p>
                In addition, certain information we collect, such as the IP address of any computer or other device that you use
                to access our website and any other domains that may be operated by UGRO Capital Ltd , will be used to
                monitor and investigate possible violations of and enforce any terms and conditions associated with our
                products and services or other applicable agreements between you and UGRO Capital Ltd . Our use and sharing
                of your personal information in the manner described in this Privacy Policy may involve the transfer of your
                personal information to UGRO Capital Ltd or to third parties, as described in this Privacy Policy, that are in
                countries outside your country of residence, which may not have the same privacy legislation as your country of
                residence.
              </p>
              <h3 className="term-heading">How We Share Personal Information:</h3>
              <h4 className="term-subheading">Service Providers</h4>

              <p>
                We may share personal information with organizations or individuals that
                perform functions on our behalf. For example, service providers may process payments for our products and
                services or may store and process data on our behalf. These service providers are granted access to personal
                information for the purpose of performing their functions for UGRO Capital Ltd .</p>
              <h4 className="term-subheading">Marketing</h4>
              <p>
                UGRO Capital Ltd may share collected personal information with group
                companies whose products and services we believe you may find of interest. If you would prefer that we not share
                your personal information with any third parties for the third parties’ marketing purposes, you can notify us
                of your preference by contacting us as described in the “Contact Us” section.
              </p>
              <h4 className="term-subheading">Legal Matters</h4>
              <p>
                UGRO Capital Ltd may share personal information in response to a court order, a request for cooperation from a
                law enforcement or other government agency, or as otherwise required by law; to persons acting as
                representative capacity on your behalf; to defend against legal claims; to investigate, prevent, or take other
                action regarding illegal activity, suspected fraud, suspected violations of terms and conditions associated with
                any of our products or services, or other wrongdoing; or to protect and defend the rights, property or safety of
                our company, our users, our employees, or others.
              </p>

              <h4 className="term-subheading">Transactions</h4>
              <p>
                UGRO Capital Ltd may share personal information in connection with a substantial corporate transaction, such
                as mergers, acquisition, the sale of our business, a divestiture, consolidation, or asset sale, or in the unlikely
                event of bankruptcy.
              </p>

              <h4 className="term-subheading">Other Purposes</h4>
              <p>
                UGRO Capital Ltd may share personal information as otherwise described to
                you at the point of collection or pursuant to your consent.
              </p>
              <h4 className="term-subheading">Third Parties</h4>
              <p>
                For our website users’ convenience, UGRO Capital Ltd may provide links or otherwise refer you to third parties,
                such as operators of Internet services linked to from our websites, that may collect personal information from
                you. In addition, UGRO Capital Ltd may invite your participation in various promotional initiatives through its
                presence on social networking websites and blogs operated by third parties, such as LinkedIn, Facebook, Twitter,
                Wordpress etc. Personal information collected by these third parties is not covered by this policy.
              </p>
              <p>
                In addition, UGRO Capital Ltd may provide you with links to “share” any UGRO Capital Ltd content with other
                third parties via email or certain social networking websites for your convenience. Data transmitted during such
                linking, including but not limited to your email address or the email address of the party receiving such data,
                shall not be tracked by UGRO Capital Ltd . Use of UGRO Capital Ltd data by any third party shall be at their
                discretion and risk. Personal information collected by these third parties is not covered by this policy, and UGRO
                Capital Ltd shall not be bound in any manner by any claims and/or liabilities arising thereto.
              </p>

              <p>
                If we share information with third parties (other than our service providers) as described above, we cannot
                control the privacy practices of those third parties, and they may not comply with the restrictions that we
                describe here.
              </p>

              <h3 className="term-heading">Personal Information Protection:</h3>

              <p>
                UGRO Capital Ltd use commercially reasonable security measures (including physical, electronic and procedural
                measures) to help safeguard personal information against loss, misuse, damage or modification and
                unauthorized access or disclosure. However, no system for transmitting or storing information electronically can
                be completely secure. Therefore, we cannot guarantee that personal information or other communications will
                always remain secure.
              </p>

              <h3 className="term-heading">Minor's Privacy:</h3>
              <p>
                We do not knowingly collect personal information from minor children. If we learn that we have collected
                personal information from a child who are in the minor category, we will delete that infmation from our
                database.
              </p>
              <h3 className="term-heading">Privacy Policy Changes:</h3>
              <p>
                This policy provides a general statement of the ways in which UGRO Capital Ltd aims to protect your
                information. You may, however, in connection with specific products or services offered by UGRO Capital Ltd ,
                amend statements to this privacy policy or supplement this policy with another.

                We may also occasionally update this privacy policy to reflect changes in our practices concerning the collection
                and use of your information. As a result, we encourage you to review the privacy policy before accessing the
                websites or otherwise furnishing data to UGRO Capital Ltd . In order to assist you in determining whether our
                policy has changed, we will list at the top of the policy the most recent date on which we updated it. If we make
                any material changes to this policy, we will notify you by posting notice of the changes on the websites or by
                other appropriate means.
              </p>

              <h3 className="term-heading">Policy Review:</h3>

              <p>
                We reserve the right to change or update this policy at any time by placing
                a prominent notice on our site. Such changes shall be effective immediately upon posting to this site.
              </p>
            </div>

          </p>
          <button className="btn-view" onClick={handleContinue}>
            Accept With OTP
          </button>
        </div>
        <VerifyOTP
          show={otpModal}
          hide={() => {
            setOtpModal(false);
          }}
          // getOtp={'terms'}
          isLoggedIn={true}
          mobile={data?.mobile}
          navigate={() => {
            // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ThankYou`)
          }}
          header={"Verify OTP To Accept Terms And Condition"}
          stopLoader={() => setLoader(false)}
        />
        <SpokesLoader show={loader} message={"Loading ..."} />
      </div>
    </>
  );
}

export default LoginTermAndCondition;
