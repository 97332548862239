import React, { useEffect } from "react";
import "../../index.css";
import "./style.scss";
import Navbar from "../../components/Navbar";
import rejected from "../../assets/images/warning-sign.png";
import crossimg from "../../assets/images/cancel.png";
import { useNavigate } from "react-router-dom";

function ErrorApi() {
  const navigate = useNavigate();

  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function (event) {
      navigate(`/${process.env.REACT_APP_BASE_FOLDER}/SessionExpired`)
    };
  }, [])
  

  return (
    <>
      <Navbar />
      <div className="Error-container">
        <div className="content-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 text-center">
               
                <img className="reject-img" src={rejected} />
                <h1 className="api-error-heading">Oops, We Are Sorry!</h1>
                <p className="api-error-text">
                  Sorry! We Are Facing Some Technical Issues. Kindly Try After
                  Some Time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ErrorApi;
