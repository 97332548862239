import React, { useState, useEffect } from "react";
// import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { HashRouter,Routes, Route,Navigate} from 'react-router-dom';
import Navbar from "../components/Navbar";
import LoginScreen from "../screens/auth/login";
import VerifiedDetails from "../screens/VerifiedDetails";
import CibilFetchReject from "../screens/CibilFetch–reject";
import BusinessDetails from "../screens/BusinessDetails";
import EKYC from '../screens/EKYC';
import UdyamRegistration from '../screens/UdyamRegistration';
import BasicInformation from '../screens/BasicInformation';
import ProcessCompleted from '../screens/ProcessCompleted';
import UploadSelfie from '../screens/UploadSelfie';
import Congratulations from '../screens/Congratulations';
import RejectionMessage from "../screens/RejectionMessage";
import SanctionLetterESign from "../screens/SanctionLetterESign";
import TermsAndCondition from "../screens/TermsAndCondition";
import ThankYou from "../screens/ThankYou";
import SignedSuccessfully from "../screens/SignedSuccessfully";
import DetailsBankAccount from "../screens/DetailsBankAccount";
import SelectedOtherBank from "../screens/selectedOtherBank";
import ManuallyBankDetails from "../screens/ManuallyBankDetails";
import VerifyDetails from "../screens/VerifyDetails";
import BankMandate from "../screens/BankMandate";
import CreateMandate from "../screens/CreateMandate";
import ScreenNavigate from "../screens/ScreenNavigate";
import PrivacyPolicy from "../screens/PrivacyPolicy/privacyPolicy";
import LoginTermAndCondition from "../screens/LoginTermAndCondition";
import PanDetails from "../screens/PanDetails";
import PreApprovedLoan from "../screens/PreApprovedLoan";
import LoanRejected from "../screens/LoanRejected";
import Address from "../screens/Address";
import BasicBusinessInformation from "../screens/BasicBusinessInformation";
import PanUpload from "../screens/PanUpload";
import AadhaarVerification from "../screens/AadhaarVerification";
import GST from "../screens/GST";
import UdhyamRegistrationForm from "../screens/UdhyamRegistrationForm/index";
import PandingThankYou from "../screens/PandingThankYou";
import ErrorApi from "../screens/ErrorApi";
import Enach from "../screens/Enach";
import Esign from "../screens/Esign";
import TryAgain from "../screens/TryAgain";
import SessionExpired from "../screens/SessionExpired";

function AppRoutes() {
    // let token = sessionStorage.getItem('token') != undefined ? true : false
    const isLoggedIn = sessionStorage.getItem('login') === "true"  ? true : false;
    if (isLoggedIn) {
        return (
            <HashRouter>
                <Routes>
                    {/* <Route path={`${process.env.REACT_APP_BASE_FOLDER}/`} element={<LoginScreen />} /> */}
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/`} element={<Navigate replace to={`/${process.env.REACT_APP_BASE_FOLDER}/IndividualDetails`} />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/IndividualDetails`} element={<PanDetails />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/PrivacyPolicy`} element={<PrivacyPolicy />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/VerifiedDetails`} element={<VerifiedDetails />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/LoanRejected`} element={<CibilFetchReject />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/BusinessDetails`} element={<BusinessDetails />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/OKYC`} element={<EKYC />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/UdyamRegistration`} element={<UdyamRegistration />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/BasicInformation`} element={<BasicInformation />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/ProcessCompleted`} element={<ProcessCompleted />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/UploadSelfie`} element={<UploadSelfie />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/Congratulations`} element={<Congratulations />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/rejection_message`} element={<RejectionMessage />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/Sanction_LetterESign`} element={<SanctionLetterESign />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/Mitc`} element={<TermsAndCondition />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/ThankYou`} element={<ThankYou />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/SignedSuccessfully`} element={<SignedSuccessfully />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/DetailsBankAccount`} element={<DetailsBankAccount />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/SelectedOtherBank`} element={<SelectedOtherBank />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/ManuallyBankDetails`} element={<ManuallyBankDetails />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/VerifyDetails`} element={<VerifyDetails />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/BankMandate`} element={<BankMandate />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/CreateMandate`} element={<CreateMandate />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/ScreenNavigate`} element={<ScreenNavigate />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/PreApprovedLoan`} element={<PreApprovedLoan />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/NoOfferFound`} element={<LoanRejected />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/BusinessAddress`} element={<Address />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/BasicBusinessInformation`} element={<BasicBusinessInformation />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/PanUpload`} element={<PanUpload />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/AadhaarVerification`} element={<AadhaarVerification />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/GST`} element={<GST />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/UdhyamRegistrationForm`} element={<UdhyamRegistrationForm />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/ThankYou2`} element={<PandingThankYou />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/enach`} element={<Enach />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/Esign`} element={<Esign />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/TryAgain`} element={<TryAgain />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/SessionExpired`} element={<SessionExpired />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`} element={<ErrorApi />} />
                    {/* <Route path={`${process.env.REACT_APP_BASE_FOLDER}/*`} element={<Navigate to={`${process.env.REACT_APP_BASE_FOLDER}/`} />} /> */}
                </Routes>
            </HashRouter>

        );
    }
    else {
        return (
            <HashRouter>
                <Routes>
                    <Route path={`${process.env.PUBLIC_URL}/`} element={<Navigate to={`${process.env.REACT_APP_BASE_FOLDER}/`} />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/`} element={<LoginScreen />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/TermsAndCondition`} element={<LoginTermAndCondition />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/LoanRejected`} element={<CibilFetchReject />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/enach`} element={<Enach />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/Esign`} element={<Esign />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/SessionExpired`} element={<SessionExpired />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`} element={<ErrorApi />} />
                    <Route path={`${process.env.REACT_APP_BASE_FOLDER}/NoOfferFound`} element={<LoanRejected />} />
                </Routes>
            </HashRouter>
        );
    }

}

export default AppRoutes;