import React from "react";
import './style.scss';

const CustomInput = (props) => {
  const { label, type = "text", name, value, onChange, placeholder, style, pattern, maxLength, maxDate, isEditable, onBlur, mandatory = false,verbiage,disabled } = props;
  let currDate = new Date().toISOString().split("T")[0];
  return (
    <div className="custominput-container">
      <div className={'form-input '}>
        <label>{label} {mandatory && <span className="required_field">*</span>}</label>
        <label className="verbiage_content">{verbiage}</label>
        <input
          maxLength={maxLength}
          className={style}
          type={type}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          pattern={pattern}
          max={maxDate ? currDate : null}
          readOnly={isEditable === true ? true : false}
          onBlur={onBlur}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

export default CustomInput; 