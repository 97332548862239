import React, { useEffect, useRef, useState } from "react";

import "../../index.css";
import "./style.scss";

import Navbar from "../../components/Navbar";
import entitykyc from "../../assets/images/entitykyc.svg";
import rejected from "../../assets/images/reject.webp";
import AddressModel from "../../components/Modal/AddressModel";
import { useNavigate } from "react-router-dom";
import Cancel from "../../assets/images/cancel.png";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import SpokesLoader from "../../components/Modal/SpokesLoader";

const addList = [
  "F.U.F - 4, Fourth Floor, Kunal Tower, Mall Road, Ludhiana.141001",
  "Office 515, 5th Floor, B Wing, Season Business Center, Opp. KDMC, Shivaji Chowk, Kalyan West, Thane- 421301",
];

function Address() {
  const udyam = useRef();
  const gst = useRef();

  const navigate = useNavigate();

  const { businessDetailsStore } = useStores();

  let retailerId = sessionStorage.getItem("retailerId");

  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
  const [selectedAddressId, setSelectedAddressId] = useState("")
  const [selectedSource, setSelectedSource] = useState("")
  const [udhyamPincode, setUdyamPincode] = useState("");
  const [udyamKey, setUdyamKey] = useState("")

  const [checked, setchecked] = useState("");
  const [question, setQuestion] = useState("");
  const [addressData, setAddressData] = useState([]);
  const [gstAddress, setGstAddress] = useState([]);
  const [udyamAddress, setUdyamAddress] = useState("");
  const [addressNotFound, setAddressNotFound] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    // window.history.pushState(null, null, window.location.href);
    // window.onpopstate = function (event) {
    //   navigate(`/${process.env.REACT_APP_BASE_FOLDER}/SessionExpired`)

    // };
    getBusinessAddress();
  }, []);

  const getBusinessAddress = () => {
    let params = `retailerId=${retailerId}`;
    setLoader(true);
    businessDetailsStore.fetchBusinessAddress(params, (res) => {
      if (res?.exceptionOccured == "N") {
        if (res?.result?.status == "success") {
          let data = [];
          // setAddressData(res?.result?.gstAddresses)
          if (res?.result?.gstAddresses.length > 0) {
            setGstAddress(res?.result?.gstAddresses);
            data.push({ gstAddresses: res?.result?.gstAddresses });
            setAddressNotFound(false);
            setAddressData(data);
            setTimeout(() => {
              setLoader(false);
            }, 200);
          } else if (res?.result?.udyamAddress != "") {
            setUdyamAddress(res?.result?.udyamAddress);
            setUdyamPincode(res?.result?.udyamPincode);
            setUdyamKey(res?.result?.udyamKey);

            data.push({ udhyamAddress: res?.result?.udyamAddress });
            setAddressNotFound(false);
            setAddressData(data);
            setTimeout(() => {
              setLoader(false);
            }, 200);
          } else {
            console.log(data);
            setAddressData(data);
            setAddressNotFound(true);
            notify(res?.result?.statusMessage, "success");
            setTimeout(() => {
              setLoader(false);
            }, 200);
          }
        } else {
          setLoader(false);
          notify(res?.result?.statusMessage, "danger");
        }
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedAddress === "") {
      notify("Please Select Suitable Option And Proceed", "danger");
      return;
    }
    if (question === "") {
      notify("Please Select Suitable Option And Proceed", "danger");
      return;
    }
    if (selectedSource == 'GST') {
      let params = `retailerId=${retailerId}`;

      let Actualaddress = selectedAddress.split(",");
      let pincode = Actualaddress[Actualaddress.length - 1];
      let address = Actualaddress.slice(0, -1).toString();

      let payload = {
        address: address,
        pincode: pincode.trim(),
        sourceCode: 2,
        sourceKey: selectedAddressId
      };

      console.log(payload);
      businessDetailsStore.insertVerifiedBusinessAddress(
        params,
        payload,
        (response) => {
          if (response?.data?.exceptionOccured == "N") {
            notify(response?.data?.result?.statusMessage, "success");
            navigate(
              `/${process.env.REACT_APP_BASE_FOLDER}/BasicBusinessInformation`
            );
          } else {
            if (response?.data?.status == 500) {
              notify(response?.data?.exceptionMessage, "danger");
              navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
              return;
            } else {

              notify(response?.data?.exceptionMessage, "danger");
            }
          }
          console.log(response.data);
        }
      );
    } else {
      let params = `retailerId=${retailerId}`;

      // let Actualaddress = selectedAddress.split(",");
      // let pincode = Actualaddress[Actualaddress.length - 1];
      // let address = Actualaddress.slice(0, -1).toString();

      let payload = {
        address: udyamAddress,
        pincode: udhyamPincode,
        sourceCode: 1,
        sourceKey: udyamKey
      };

      console.log(payload);
      businessDetailsStore.insertVerifiedBusinessAddress(
        params,
        payload,
        (response) => {
          if (response?.data?.exceptionOccured == "N") {
            notify(response?.data?.result?.statusMessage, "success");
            navigate(
              `/${process.env.REACT_APP_BASE_FOLDER}/BasicBusinessInformation`
            );
          } else {
            if (response?.data?.status == 500) {
              notify(response?.data?.exceptionMessage, "danger");
              navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
              return;
            } else {

              notify(response?.data?.exceptionMessage, "danger");
            }
          }
          console.log(response.data);
        }
      );
    }

  };

  return (
    <>
      <Navbar logout={true} appid={true} />
      <div className="Address-container">
        <div className="content-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="top-header-title">
                  <h2>Business Address</h2>
                  <hr className="heading-divider" />
                </div>
                <div className="top-header-content">
                  <img className="header-img" src={entitykyc} />
                  <p>Select One Of The Address As Business Address</p>
                </div>
                <div className="udyam_radio_container">
                  {addressData.length > 0 && (
                    <div>
                      <div>
                        <div className="address_text">
                          <p className="Address_type">Address As Per Udyam</p>
                        </div>
                        {udyamAddress != "" ? (
                          <div className="address-box">
                            <input
                              ref={udyam}
                              type="radio"
                              id="udyamAddress"
                              name="udyamAddress"
                              value={udyamAddress}
                              checked={selectedAddress != udyamAddress ? false : true}
                              onChange={(e) => {
                                setSelectedAddress(udyamAddress);
                                setSelectedSource('Udyam');
                                setSelectedAddressIndex(1)
                              }}
                            />
                            <p for="udyamAddress">{udyamAddress}</p>
                          </div>
                        ) : (
                          <div className="Noaddress_text">
                            <p className="NoAddress_type">No Address Found</p>
                          </div>
                        )}
                      </div>
                      <div>
                        <div className="address_text">
                          <p className="Address_type">Address As Per GSTIN</p>
                        </div>
                        {gstAddress.length > 0 ? (
                          addressData?.map((item) =>
                            item?.gstAddresses?.map((add, index) => {
                              return (
                                add != null && (
                                  <div className="address-box">
                                    <input
                                      // key={index}
                                      ref={gst}
                                      type="radio"
                                      id="gstAddress"
                                      name="gstAddress"
                                      value={selectedAddress}
                                      checked={selectedAddressIndex != index ? false : true}
                                      onChange={(e) => {
                                        setSelectedAddress(add?.address);
                                        setSelectedAddressId(add?.id)
                                        setSelectedSource('GST')
                                        if (question == "No") {
                                          setQuestion('');
                                        }
                                        setSelectedAddressIndex(index)
                                      }}
                                    />
                                    <p for="gstAddress">{add?.address}</p>
                                  </div>
                                )
                              );
                            })
                          )
                        ) : (
                          <div className="Noaddress_text">
                            <p className="NoAddress_type">No Address Found</p>
                          </div>
                        )}
                      </div>

                      {/* <div className="address_text">
                                            <div className="address-box">
                                                <input
                                                    type="radio"
                                                    id="gstAddress"
                                                    name="entity"
                                                    value={udyamAddress}
                                                    onChange={(e) => { setUdyamAddress(e) }}
                                                />
                                                <p for="gstAddress">Office 515, 5th Floor, B Wing, Season Business Center, Opp. KDMC, Shivaji Chowk, Kalyan West, Thane- 421301</p>
                                            </div>
                                            </div> */}
                      <div className=" mt-4 address_text">
                        <p className="Address_type">
                          Is This Your Current Business Address?
                        </p>
                        <br />
                        <div className="radio-container">
                          <input
                            type="radio"
                            id="address"
                            name="entity"
                            value={question}
                            checked={question == "Yes"}
                            onChange={() => {
                              setQuestion("Yes");
                            }}
                          />
                          <label for="address">YES</label>
                        </div>
                        <div className="radio-container">
                          <input
                            type="radio"
                            id="address"
                            name="entity"
                            value={question}
                            checked={question == "No" && true}
                            onChange={() => {
                              setQuestion("No");
                              setSelectedAddress("");
                              setSelectedAddressIndex(null);
                            }}
                          />
                          <label for="address">NO</label>
                        </div>
                      </div>
                    </div>
                  )}
                  {addressNotFound && (
                    <div className="address_found_text">
                      <div className="found_text_box">
                        <img className="found_cancle_img" src={rejected} />
                        <p className="Address_type1">
                          No Address Details Found Basis GSTIN/ Udyam Aadhar.
                          Please Enter Details Manually
                        </p>
                      </div>
                    </div>
                  )}
                  {/* <div className="d-flex mt-4">
                                <input
                                    type="checkbox"
                                    id="agree"
                                    name="agree"
                                    value={checked}
                                    onChange={() => { setchecked(!checked); }}
                                />
                                <p className='none_check' for="udyamAddress">Enter your Business address manually?</p>
                            </div> */}
                </div>

                {addressData.length > 0 && addressData.length > 0 && (
                  <div className="submit-btn">
                    <button
                      className="btn-view"
                      onClick={(e) => {
                        if (question == "Yes" || question == "") {
                          handleSubmit(e);
                        } else if (question == "No") {
                          setchecked(!checked);
                        }
                      }}
                    >
                      Submit
                    </button>
                  </div>
                )}
                {addressNotFound && (
                  <div className="proceed-btn">
                    <button
                      className="proceed-btn-view"
                      onClick={(e) => {
                        setchecked(!checked);
                      }}
                    >
                      Proceed
                    </button>
                  </div>
                )}
              </div>
              <AddressModel
                title={"Current Business Address"}
                show={checked}
                onSubmit={() => {
                  setchecked(!checked);
                }}
              />
            </div>
            <SpokesLoader show={loader} message={"Loading ..."} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Address;
