import React, { useEffect, useState, useRef } from "react";
import "../../index.css";
import "./style.scss";
import bankdetails from "../../assets/images/bankdetails.svg";
import paperclipColor from "../../assets/images/paperclip-solid-orange.png";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import { useNavigate } from "react-router-dom";
import SpokesLoader from "../../components/Modal/SpokesLoader";
import Navbar from "../../components/Navbar";

function VerifyDetails() {
  const { applicantStore } = useStores();
  const navigate = useNavigate();

  const chequeData = sessionStorage.getItem("UploadChequeResponse");
  const chequeName = sessionStorage.getItem("ChequeName");
  const id = sessionStorage.getItem("embeddedPartnerId");
  const manualBankData = sessionStorage.getItem("manualBankData");
  const chequeBankData = sessionStorage.getItem("chequeBankData");
  const [accountType, setAccountType] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [selectedAccountType, setSelectedAccountType] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function (event) {
      navigate(`/${process.env.REACT_APP_BASE_FOLDER}/SessionExpired`)
    };
    // getAccountType()
  }, []);

  useEffect(() => {
    // console.log(manualBankData);
    fetchOcrDetails();
  }, []);

  const fetchOcrDetails = async () => {
    if (chequeData == null) {
      // console.log("manualBankData", JSON.parse(manualBankData));
      let data = JSON.parse(manualBankData);
      setAccountNumber(data?.accountNumber);
      // setBankName(data?.bank)
      setIfscCode(data?.ifscCode);
      setAccountHolderName(data?.accountHolderName);
      setSelectedAccountType(data?.selectedAccountType);
      if (data?.selectedAccountType === "Saving") {
        setSelectedAccountType("Savings Account");
      } else {
        setSelectedAccountType("Current Account");
      }
    } else {
      let data = JSON.parse(chequeData);
      let accName = JSON.parse(chequeBankData);
      setAccountNumber(data?.accNo);
      setBankName(data?.bank);
      setIfscCode(data?.ifsc);
      setAccountHolderName(data?.name[0]);
      setSelectedAccountType(accName?.selectedAccountType);
      if (accName === "Saving") {
        setSelectedAccountType("Savings Account");
      } else {
        setSelectedAccountType("Current Account");
      }
    }
  };

  const handleSubmitButton = async () => {
    
    if (selectedAccountType === "") {
      notify("Please select account type", "danger");
      return;
    }

    let accountType;

    if (selectedAccountType === "Savings Account") {
      accountType = "Saving";
    } else {
      accountType = "Current";
    }
    let payload;
    if (manualBankData !== null) {
      payload = {
        accNo: accountNumber,
        bank: "",
        chequeNo: "",
        ifsc: ifscCode,
        micr: "",
        bankDetails: {},
        name: [accountHolderName],
      };
    } else {
      payload = chequeData;
    }
    setLoader(true);

    // console.log("payload ", payload);
    let params = `retailerId=${id}&accountType=${accountType}`;
    // console.log("params ", params);
    // let response = { "status": 200, "message": "Success", "result": { "success": true, "status_code": "200", "data": { "txn_id": "ccfcf2a78d8c69baeb", "action_url": "https://uat.emandate.in/customers/ccfcf2a78d8c69baeb" }, "message": "registered successfully." }, "exceptionOccured": "N", "exceptionMessage": "NA" }
    await applicantStore.processCheque(params, payload, (response) => {
      console.log("processCheque ", response);
      if (response?.data?.message === "Success") {
        console.log("processCheque Success ", response);
        notify(response?.data?.result?.message);
        let url = response?.data?.result?.data?.action_url;
        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/Esign`);
        setLoader(false);
      } else {
        console.log("processCheque else ", response);
        if(response?.data?.status == 500)
        {
          notify(response?.data?.exceptionMessage, "danger");
          navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
          setLoader(false);
          return;

        }else if(response?.data?.status == 101){
          notify(response?.data?.exceptionMessage, "danger");
          setLoader(false);
          navigate(`/${process.env.REACT_APP_BASE_FOLDER}/LoanRejected`);
          return;
        }else{
          notify(response?.data?.exceptionMessage, "danger");
          setLoader(false);
        }
      }
    });
  };

  return (
    <>
      <Navbar appid={true} logout={true} />
      <div className="VerifyDetails-container">
        <div className="content-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
              <div className="top-header-title">
                  <h2>Verify Bank Details</h2>
                  <hr className="heading-divider" />
                </div>
                <div className="top-header-content">
                  <img className="header-img" src={bankdetails} />
                  <p>Please Verify The Details For Your Bank Account</p>
                </div>
                <div className="form-body">
                  {/* <p className="file-upload-label">Please verify all the bank details</p> */}
                  {/* <input
                            type="file"
                            name="bank-file-upload"
                            id="bank-file-upload"
                            style={{ display: 'none' }}
                        /> */}
                  {chequeData !== null && (
                    <label for="bank-file-upload">
                      <div className="bankfile-upload mb-2">
                        <div className="bankfile-upload-left">
                          <img className="paperclip-img" src={paperclipColor} />
                          <p>{chequeName}</p>
                        </div>
                        <p>+</p>
                      </div>
                    </label>
                  )}
                </div>
                <div
                // className="btn-container"
                >
                  {chequeData !== null && (
                    <div className="reupload_container">
                      <p
                        // className="btn-view"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate(
                            `/${process.env.REACT_APP_BASE_FOLDER}/ManuallyBankDetails`
                          );
                        }}
                      >
                        Re-Upload Cheque
                      </p>
                    </div>
                  )}
                </div>
                <div className="form-input">
                  {/* <select className="form-control" >
                            <option value="">State Bank of India</option>
                        </select> */}
                </div>
                {chequeData !== null && (
                  <div className="form-input">
                    <input
                      className="input-field"
                      type="text"
                      placeholder="Bank Name"
                      disabled={bankName != "" ? true : false}
                      value={bankName}

                      // onChange={()=>{}}
                    />
                  </div>
                )}
                 <div className="row">
              <div className="col-md-3">
                <div className="form-input">
                  <input
                    className={
                      accountNumber !== ""
                        ? "input-field input-disabled"
                        : "input-field"
                    }
                    type="text"
                    placeholder="Account number"
                    value={accountNumber}
                    disabled={accountNumber !== "" ? true : false}
                    onChange={(e) => {
                      setAccountNumber(e.target.value);
                    }}
                  />
                </div>
                </div>
                <div className="col-md-3">
                <div className="form-input">
                  <input
                    className={
                      ifscCode !== ""
                        ? "input-field input-disabled"
                        : "input-field"
                    }
                    type="text"
                    placeholder="IFSC Code"
                    value={ifscCode}
                    disabled={ifscCode !== "" ? true : false}
                    onChange={(e) => {
                      setIfscCode(e.target.value);
                    }}
                  />
                </div>
                </div>
                <div className="col-md-3">
                <div className="form-input">
                  <input
                    className={
                      accountHolderName !== ""
                        ? "input-field input-disabled"
                        : "input-field"
                    }
                    type="text"
                    placeholder="Account Holder's Name"
                    disabled={accountHolderName !== "" ? true : false}
                    value={accountHolderName}
                    onChange={(e) => {
                      setAccountHolderName(e.target.value);
                    }}
                  />
                </div>
                </div>
                {/* <span className="alert-content">*Put primary account holder name for Joint account</span> */}
                <div className="col-md-3">
                <div className="form-input">
                  <select
                    className={
                      selectedAccountType !== ""
                        ? "input-field input-disabled"
                        : "input-field"
                    }
                    value={selectedAccountType}
                    disabled={selectedAccountType !== "" ? true : false}
                    onChange={(e) =>
                      e.target.value !== "" &&
                      setSelectedAccountType(e.target.value)
                    }
                  >
                    <option value="">{selectedAccountType}</option>
                    {/* <option value="Saving">Saving</option>
                            <option value="Current" >Current</option> */}
                    {/* {accountType.length > 0 && accountType?.map((type) => {
                                if (type.accId === '1' || type.accId === '3') {
                                    return (
                                        <option value={type?.accName}>{type?.accName}</option>
                                    )
                                }
                            })} */}
                  </select>
                </div>
                </div>
                <div className="col-md-12">
                <div className="btn-main-container">
                  <div className="btn-container">
                    <button
                      className="btn-view"
                      onClick={() => {
                        handleSubmitButton();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
                <SpokesLoader
                  show={loader}
                  message={"Loading ..."}                />
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default VerifyDetails;
