import React, { useEffect, useRef, useState } from "react";

import "../../index.css";
import "./style.scss";

import CustomInput from "../../components/UI/Form-input/CustomInput";
import notify from "../../shared/notify";

import businessentity from "../../assets/images/businessentity.svg";
import businessIcon from "../../assets/images/business.webp";

import date from "../../assets/images/date.png";
import ValidateEmail from "../../Helper/emailValidate";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import applicantStore from "../../stores/applicantStore";
import { useStores } from "../../stores";
import SpokesLoader from "../../components/Modal/SpokesLoader";

function PanDetails() {
  const navigate = useNavigate();
  const { applicantStore } = useStores();
  const doiRef = useRef();

  let retailerId = sessionStorage.getItem("retailerId");
  let programId = sessionStorage.getItem("programId");
  let loginData = sessionStorage.getItem("loginData");
  let data = JSON.parse(loginData);
  const nextActivityId = sessionStorage.getItem("nextActivityId");

  const [pan, setPan] = useState("");
  const [mobile, setMobile] = useState("");
  const [panName, setPanName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [loader, setLoader] = useState(false);
  const [validatePan, setValidatePan] = useState(false);

  useEffect(() => {
      window.history.pushState(null, null, window.location.href); 
      window.onpopstate = function (event) {
        navigate(`/${process.env.REACT_APP_BASE_FOLDER}/SessionExpired`)
      }; 
}, [])

  useEffect(() => {
    getPanData();
  }, []);

  const getPanData = () => {
    setLoader(true);
    let params = `retailerId=${retailerId}`;
    applicantStore.getPanProfile(params, (response) => {
      if (response?.exceptionOccured == "N") {
        let item = response?.result;
        setPan(item?.pan);
        setMobile(
          item?.mobileNumber == null ? data?.mobile : item?.mobileNumber
        );
        setPanName(item?.nameAsPerPan);
        setDateOfBirth(item?.dateOfBirth);
        // setEmail(item?.emailId);
        // setGender(item?.gender);
        setLoader(false);
      } else {
        if (response?.data?.status == 500) {
          notify(response?.data?.exceptionMessage, "danger");
          navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
          setLoader(false)
          return;
      }else{

        notify(response?.exceptionMessage, "danger");
        setLoader(false);
      }
      }
    });
  };

  const handleEmailOnBlur = (e) => {
    let validate = ValidateEmail(e);
    if (validate === false) {
      notify("Please Enter A Valid Email ID", "danger");
      setEmail("");
      setValidatePan(false);
      return;
    } else {
      setEmail(e);
      setValidatePan(true);
    }
  };

  const handleSubmit = (e) => {
    // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/BusinessDetails`);
    e.preventDefault();
    if (email === "" || gender === "") {
      notify(
        `Email ID and Gender Are Mandatory, Please Enter Missing Details.`,
        "danger"
      );
      return;
    }
    // if (pan === "" || pan == null) {
    //     notify("Please enter PAN number", "danger");
    //     return;
    // }
    // if (mobile === "" || mobile == null) {
    //     notify("Please enter mobile number", "danger");
    //     return;
    // }
    // if (panName === "" || panName == null) {
    //     notify("Please enter name as per PAN ", "danger");
    //     return;
    // }
    // if (dateOfBirth === "" || dateOfBirth == null) {
    //     notify("Please enter date of birth", "danger");
    //     return;
    // }
    // if (email === "" || email == null) {
    //     notify("Please enter email-id ", "danger");
    //     return;
    // }
    // if (gender === "" || gender == null) {
    //     notify("Please enter gender", "danger");
    //     return;
    // }
    let params = `retailerId=${retailerId}`;

    let payload = {
      pan: pan,
      mobileNumber: mobile == null ? data?.mobile : mobile,
      nameAsPerPan: panName,
      dateOfBirth: dateOfBirth,
      emailId: email != null && email,
      gender: gender,
      residencePincode: data?.pincode,
      programId: '', //Number
    };

    setLoader(true);
    applicantStore.individualDetails(params, payload, (response) => {
      if (response?.data?.exceptionOccured == "N") {
        if (response?.data?.result?.status == "success") {
          notify(response?.data?.result?.statusMessage, "success");
          if (response?.data?.result?.nextActivityId == "business_details") {
            sessionStorage.setItem("nextActivityId",response?.data?.result?.nextActivityId);
            navigate(`/${process.env.REACT_APP_BASE_FOLDER}/BusinessDetails`);
            setLoader(false);
          }
        } else {
          notify(response?.data?.result?.statusMessage, "danger");
          setLoader(false);
        }
      } else {
        if (response?.data?.status == 500) {
          notify(response?.data?.exceptionMessage, "danger");
          navigate(`/${process.env.REACT_APP_BASE_FOLDER}/ErrorApi`);
          setLoader(false)
          return;
        } else{
          notify(response?.data?.exceptionMessage, "danger");
          setLoader(false);
        }
      }
    });
    // // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/BusinessDetails`);
  };

  return (
    <>
      <Navbar firmName={true} />
      <div className="PanDetails_container">
        <div className="content-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="top-header-title">
                  <h2>Individual Details</h2>
                  <hr className="heading-divider" />
                </div>
                <div className="top-header-content">
                  <img className="header-img" src={businessIcon} />
                  <p>
                  Enter Your Individual Details.
                  </p>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <CustomInput
                      type="text"
                      label="PAN"
                      placeholder="Enter Your PAN"
                      name={pan}
                      value={pan}
                      // onBlur={(e) =>
                      //     pan.length > 0 && handlePanOnBlur(e.target.value)
                      // }
                      onChange={(e) => setPan(e.target.value)}
                      disabled={pan === "" || pan == null ? false : true}
                      // mandatory={true}
                    />
                  </div>
                  <div className="col-md-4">
                    <CustomInput
                      type="text"
                      label="Name As Per PAN"
                      placeholder="Name As Per PAN"
                      name={panName}
                      value={panName}
                      // onBlur={(e) =>
                      //     pan.length > 0 && handlePanOnBlur(e.target.value)
                      // }
                      onChange={(e) => setPanName(e.target.value)}
                      disabled={
                        panName === "" || panName == null ? false : true
                      }
                      // mandatory={true}
                    />
                  </div>
                  
                  <div className="col-md-4">
                    <div className="form-input">
                      <label>
                        Date Of Birth{" "}
                        {/* <span className="required_field">*</span> */}
                      </label>
                      <div className="date-container">
                        {/* {console.log(new Date().toDateString())} */}
                        <input
                          ref={doiRef}
                          type="date"
                          value={dateOfBirth}
                          onBlur={(e) => {
                            if (e.target.validity.valid === false) {
                              notify("Please Enter a Valid Date", "danger");
                              doiRef.current.value = "";
                            }
                          }}
                          onChange={(e) => {
                            setDateOfBirth(e.target.value);
                          }}
                          max={new Date().toISOString().split("T")[0]}
                          disabled={
                            dateOfBirth === "" || dateOfBirth == null
                              ? false
                              : true
                          }
                        />

                        <img className="date-img" src={date} />
                        <div className="date-image-overlap"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <CustomInput
                      type="text"
                      label="Mobile Number"
                      placeholder="Enter Your Mobile Number"
                      name={mobile}
                      value={mobile == null ? data?.mobile : mobile}
                      maxLength={10}
                      pattern="[0-9]*"
                      onChange={(e) => {
                        e.target.validity.valid
                          ? setMobile(e.target.value)
                          : notify(
                              "Mobile Number Can Only Contain Numbers",
                              "danger"
                            );
                      }}
                      disabled={mobile === "" || mobile == null ? false : true}
                      // mandatory={true}
                    />
                  </div>
                  <div className="col-md-4">
                    <CustomInput
                      type="text"
                      label="Email-ID"
                      placeholder="Enter Your Email-ID"
                      value={email}
                      onBlur={(e) => {
                        e.target.value?.length > 0 &&
                          handleEmailOnBlur(e.target.value);
                      }}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      mandatory={true}
                      // disabled={ !validatePan? false : true}
                    />
                  </div>
                  <div className="col-md-4">
                    <div className="form-input">
                      <label>
                        Gender
                        <span className="required_field">*</span>
                      </label>
                      <select
                        className="form-control"
                        value={gender}
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 text-center">
                <div className="submit-btn">
                  <button
                    className="btn-view"
                    onClick={(e) => {
                      handleSubmit(e);
                      // nextActivityId != null
                      //   ? handleSubmit()
                      //   : notify("Retailer exists but Limit Expired", "danger");
                    }}
                    // disabled={showSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <SpokesLoader show={loader} message={"Loading ..."} />
          </div>
        </div>
      </div>
    </>
  );
}

export default PanDetails;
