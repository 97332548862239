import React, { useEffect, useRef, useState } from 'react';
import SpokesLoader from '../SpokesLoader';
import Modal from 'react-bootstrap/Modal';
import Clip from '../../../assets/images/attach_file.png';
import './style.scss';
import Button from "../../UI/Button/Button";
import { useStores } from '../../../stores';
import { useNavigate } from 'react-router-dom';
import convertBase64 from '../../../Helper/convertBase64';
import notify from '../../../shared/notify';
import crossimg from '../../../assets/images/cancel.png';


function AddressModel(props) {
    const Input1 = useRef();
    const navigate = useNavigate();
    const { businessDetailsStore } = useStores();

    let retailerId = sessionStorage.getItem('retailerId');

    let [fileSize, setFileSize] = useState(0);
    const [address, setAddress] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [pincode, setPincode] = useState('');
    const [addressProof, setAddressProof] = useState('');
    const [document64, setDocument64] = useState('');
    const [documentName, setDocumentName] = useState('');
    const [loader, setLoader] = useState('');
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        if (pincode.length == 6) {
            fetchCityData();
        }

    }, [pincode])



    const fetchCityData = () => {
        let params = `pincode=${pincode}`
        setLoader(true)
        businessDetailsStore.fetchPincodeData(params, (res) => {
            if (res?.exceptionOccured == "N") {
                setState(res?.result?.state);
                setCity(res?.result?.city);
                setDisable(true)
                setLoader(false)
            } else {
                setLoader(false);
                setDisable(false);
            }
            console.log(res?.result?.state, "====", res?.result?.city)

        }, (err) => {


        })
    }

    const SubmitBusinessAddress = (e) => {
        e.preventDefault();
        // navigate(`/${process.env.REACT_APP_BASE_FOLDER}/BasicBusinessInformation`)
        if (address == "" || state == "" || city == "" || pincode === "" || addressProof === "") {
            notify("All The Above Fields Are Mandatory, Please Enter Missing Details", "danger");
            return;
        }
        // if (address === "") {
        //     notify("Please enter address", "danger");
        //     return;
        // }
        // if (state === "") {
        //     notify("Please select  state ", "danger");
        //     return;
        // }
        // if (city === "") {
        //     notify("Please select city", "danger");
        //     return;
        // }
        if (pincode.length < 6) {
            notify("Please Enter a Valid Pincode", "danger");
            return;
        }
        // if (addressProof === "") {
        //     notify("Please select addressProof", "danger");
        //     return;
        // }
        if (document64 === "") {
            notify("Please Upload Image", "danger");
            return;
        }
        let params = `retailerId=${retailerId}`

        setLoader(true)
        if (props.title == 'Current Business Address') {
            let payload = {
                address: address,
                city: city,
                pincode: pincode,
                documentName: documentName,
                state: state,
                addressProofBase64: document64,
                addressProof: addressProof,
                sourceCode:3,
                sourceKey:-1
    
            }

            businessDetailsStore.currentBusinessAddress(params, payload, (response) => {
                if (response?.data?.exceptionOccured == 'N') {
                    props.onSubmit()
                    setLoader(false)
                    navigate(`/${process.env.REACT_APP_BASE_FOLDER}/BasicBusinessInformation`)
                    notify(response?.data?.result?.statusMessage, 'success')
                } else {
                    notify(response?.data?.exceptionMessage, 'danger')
                    props.onSubmit()
                    setLoader(false)
                }

            })
        } else {
            
        let payload = {
            address: address,
            city: city,
            pincode: pincode,
            documentName: documentName,
            state: state,
            addressProofBase64: document64,
            addressProof: addressProof

        }
            businessDetailsStore.currentResidenceAddress(params, payload, (response) => {
                if (response?.data?.exceptionOccured == 'N') {
                    props.onSubmit()
                    navigate(`/${process.env.REACT_APP_BASE_FOLDER}/BasicInformation`)
                    notify(response?.data?.result?.statusMessage, 'success')
                    setLoader(false)
                } else {
                    notify(response?.data?.exceptionMessage, 'danger')
                    props.onSubmit()
                    setLoader(false)
                }

            })

        }
    }

    const handleFileRead = async (event) => {
        const file = event.target.files[0]
        const filesize = file.size / 1000000;
        const fileName = file.name;
        const base64 = await convertBase64(file)
        return { base64, filesize, fileName };
    }

    const handleDocumentUpload = async (e) => {
        const result = await handleFileRead(e);
        const data = result.base64;
        const name = result.fileName;
        let currentFileSize = fileSize + result.filesize;
        setFileSize(currentFileSize);
        if (currentFileSize > 10) {
            notify(
                "Please Upload File Up To 4MB of JPEG, JPG, PNG, & Up To 2 MB Of PDF format",
                "danger"
            );
            setFileSize(currentFileSize - result.filesize);
            Input1.current.value = "";
            return;
        }
        console.log(data.split(',')[1])
        setDocument64(data.split(',')[1])
        setDocumentName(name)
        // setShowPanImage(data)
    }

    return (
        <>
            <Modal
                {...props}
                centered
                className='address-modal'
            >
                <Modal.Body>
                    <div className="address-container">
                        <div className='address_modal_heading_box'>
                            <h2>{props.title}</h2>
                            <div className="cross_icon_container">
                                <img
                                    className="crossimg-img"
                                    src={crossimg}
                                    onClick={() => {
                                        props.onSubmit()
                                    }}
                                />
                            </div>
                        </div>
                        <div className="address-view">

                            <div>
                                <form>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className="address-form-group">
                                                <label>Address</label><br />
                                                <input type='text' name='address' id='address' placeholder='Enter Your Address' value={address} onChange={(e) => { setAddress(e.target.value) }} />
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <div className="address-form-group">
                                                <label>Pincode</label><br />
                                                <input type='text' name='pincode' id='pincode' placeholder='Enter Your Pincode' maxLength={6} value={pincode} onChange={(e) => { setPincode(e.target.value.replace(/\s/g, '')) }} />
                                            </div>
                                        </div>

                                        <div className='col-md-6'>
                                            <div className="address-form-group">
                                                <label>State</label><br />
                                                <input disabled={disable} type='text' name='state' id='state' placeholder='Enter Your State' value={state} onChange={(e) => { setState(e.target.value) }} />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="address-form-group">
                                                <label>City</label><br />
                                                <input disabled={disable} type='text' name='city' id='city' placeholder='Enter Your city' value={city} onChange={(e) => { setCity(e.target.value) }} />
                                            </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <div className="address-form-group">
                                                <label>Address Proof</label><br />
                                                <select value={addressProof} onChange={(e) => { setAddressProof(e.target.value) }}>
                                                    <option value={''}>Select address proof</option>
                                                    {props.title == 'Current Business Address' ?
                                                        <>
                                                            <option value={'Shop Establishment Certificate'}>Shop Establishment Certificate</option>
                                                            <option value={'Establishment Certificate'}>Establishment Certificate</option>
                                                        </>
                                                        :
                                                        <>
                                                            <option value={'VoterID'}>VoterID</option>
                                                            <option value={'PassPort'}>PassPort</option>
                                                        </>
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <div className="address-form-group form-input">
                                                <label>Please Upload Image <span className="required_field">*</span></label>
                                                <div className='file file--upload'>
                                                    <label for='input-file'>
                                                        {documentName == "" ?
                                                            <>
                                                                <img
                                                                    className="Clip-img"
                                                                    src={Clip}
                                                                /> Select Your File <span>+</span>
                                                            </>
                                                            :
                                                            <p>{documentName}</p>}
                                                    </label>
                                                    <input
                                                        ref={Input1}
                                                        id='input-file'
                                                        type='file'
                                                        // ref={drugInput}
                                                        accept="image/*,.pdf"
                                                        onChange={(e) => { handleDocumentUpload(e) }}
                                                    />
                                                    {/* {drugLicenceFileName != "" ? (
                                                        <div className="filebox">
                                                            <p className="filename">{drugLicenceFileName.length < 20 ? drugLicenceFileName : `${drugLicenceFileName.slice(0, 20)}...`}</p>
                                                            
                                                        </div>
                                                    ) : null} */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 text-center'>
                                            <Button
                                                type="button"
                                                buttonStyle="btn--secondary--solid"
                                                buttonSize="btn-medium"
                                                onClick={(e) => { SubmitBusinessAddress(e) }}
                                            >
                                                Submit
                                            </Button>
                                        </div>

                                    </div>


                                </form>
                            </div>

                        </div>
                        <SpokesLoader
                            show={loader}
                            message={"Loading ..."}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddressModel;