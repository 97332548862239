import React, { useEffect, useState, useRef } from "react";
import '../../index.css';
import './style.scss';
import rejected from '../../assets/images/reject1.png';
import Navbar from "../../components/Navbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { propTypes } from "react-bootstrap/esm/Image";
import crossimg from '../../assets/images/cancel.png';

function SessionExpired() {

    useEffect(() => {
        window.history.pushState(null, '', window.location.href);
        window.onpopstate = function (event) {
          window.history.pushState(null, '', window.location.href);
        };
    }, [])
    

    const removeSeesionStorage = () =>{
        sessionStorage.removeItem("login");
        sessionStorage.removeItem("loginData");
        sessionStorage.removeItem("retailerName");
        sessionStorage.removeItem("nextActivityId");
        sessionStorage.removeItem("embeddedPartnerId");
        sessionStorage.removeItem("retailerId");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("loanRequestId");
        sessionStorage.removeItem("UrmnNumber");
      }
    

    const logout = () => {
        let programId = sessionStorage.getItem("programName");
        removeSeesionStorage();
        localStorage.clear();
        window.location.href = `/`;
      };
      
  return (
    <>
            <Navbar />
            <div className="CibilFetchReject-container">
                <div className="content-container">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <div className="close-icon">
                                    {/* <img
                                        className="crossimg-img"
                                        src={crossimg}
                                        onClick={() => { navigate(-1) }}
                                    // onClick={() => {
                                    //     // if (back) {
                                    //     //     navigate(`/${process.env.REACT_APP_BASE_FOLDER}/OKYC`)
                                    //     // } else {
                                    //     //     navigate(`/${process.env.REACT_APP_BASE_FOLDER}/${id}`)
                                    //     // }
                                    // }}
                                    /> */}
                                </div>
                                <img
                                    className="reject-img"
                                    src={rejected}
                                />
                                <h1 className="cfr-heading">Session Expired, Kindly Login Again!</h1>
                                <button className="re_initiate_btn" onClick={()=>{logout(); }}>Go Back To Login</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default SessionExpired